import React, { useEffect, useState } from 'react';
import styles from './InfoTooltip.module.scss';
import { useTranslation } from 'react-i18next';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { useSelector } from 'react-redux';
import { ReactComponent as IconInfo } from '../../../assets/icons/u_info-circle.svg';
import { ReactComponent as IconClose } from '../../../assets/icons/u_multiply.svg';

const displayUrl = (indicatorKey: IndicatorKeyEnum) => {
  const url = 'https://www.iadb.org/es/mici/registro-publico-bid-cronologico?year=&phase=&country=&status=open';

  return indicatorKey === IndicatorKeyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES ? (
    <a href={url} className={styles.link} target="_blank" rel="noopener noreferrer">
      {url}
    </a>
  ) : null;
};

type Props = {
  indicatorKey: IndicatorKeyEnum;
};
const getIndicatorTooltip = (allIndicators, indicatorKey) => {
  const filterTabIndicators = allIndicators.find(i => i.indicators.some(indicator => indicator.code === indicatorKey));
  return filterTabIndicators?.indicators.find(indicator => indicator.code === indicatorKey);
};

const InfoTooltip = ({ indicatorKey }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [indicatorInfo, setIndicatorInfo] = useState<any>();
  const allIndicatorsFromState = useSelector<any, any[]>(state => state.allIndicators);
  const WHATFIX_URL = 'https://whatfix.com/contenthosting/download/pdf/eab06f67-b3ad-471a-bf44-b2c3e64263f2/61da22a1-a088-4520-b9e5-3f184e4adabc.pdf';

  const handleClickOutside = event => {
    if (event.target.className !== styles.modalInfo) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIndicatorInfo(getIndicatorTooltip(allIndicatorsFromState, indicatorKey));

    if (isOpen) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const { t } = useTranslation();

  const splitTooltipText = indicatorInfo?.tooltip?.split('|');

  return (
    <div className={styles.container}>
      <div
        className={styles.clickable}
        onClick={event => {
          event.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <IconInfo />
      </div>
      {isOpen ? (
        <div className={styles.modalInfo} onClick={event => event.stopPropagation()}>
          <div className={styles.modalHeader}>
            <h4>{t('about_this_indicator')}</h4>
            <div className={styles.iconClose} onClick={() => setIsOpen(false)}>
              <IconClose />
            </div>
          </div>
          {(indicatorKey === IndicatorKeyEnum.CLASSIFICATION_OF_ACQUISITION_PLANS) ? (
            <>
              <p>{splitTooltipText[0]}</p>
              <p>{splitTooltipText[1]}</p>
              <a href={WHATFIX_URL} className={styles.link} target="_blank" rel="noopener noreferrer">
                {t('link_for_more_information')}
              </a>
            </>
          ) : (
            <>
              <p>{indicatorInfo && indicatorInfo?.tooltip ? indicatorInfo.tooltip : ''}</p>
              {displayUrl(indicatorKey)}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InfoTooltip;
