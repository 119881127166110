import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import './Portfolio.scss';
import CardIndicator from './CardIndicator/CardIndicator';
import AddIndicator from './AddIndicator/AddIndicator';
import { TabPanel } from '../Projects/ProjectsAll/ProjectAllUtil';
import { getRegionsAndCountries, getSectors } from '../../api/filterApi';
import { processIndicators } from './indicatorHelper';
import User from '../../models/User';
import FilterComponent from '../Filter/FilterComponent';
import { addRegions } from '../../redux/actions/regions';
import { CardPortfolioSkeletonList } from '../LoadersSkeletons/CardPortfolioSkeletonList/CardPortfolioSkeletonList';
import { translateMultipleText } from '../../services/stringUtil';
import { isIE11 } from '../../services/booleanUtil';
import { resetCurrentIndicator } from '../../redux/actions/currentIndicator';
import { resetIndicatorProjects } from '../../redux/actions/indicatorProjects';
import { sortIndicatorsList, addIndicators, getIndicatorsList } from '../../redux/actions/indicators';
import { resetIndicatorAccordances } from '../../redux/actions/indicatorAccordances';
import { setMarginRight, emptyProjects, resetLocalStorageOnPortfolioMount } from './PortfolioUtil';
import ReportButton from '../../components/Util/Buttons/ReportButton/ReportButton';
import { ReportType } from '../../models/ReportType';
import { addSectors } from '../../redux/actions/sectors';
import { getCurrentDivisions } from '../../redux/actions/divisions';
import { CalledFrom } from '../../models/CalledFromEnum';
import { ProjectProductTypes } from '../../models/ProjectProductTypes';
import FilterIndicators from '../../models/FilterIndicators';
import Indicator from '../../models/Indicator';
import { resetSessionFilter } from '../../services/sessionFiltersIndicatorDetailHelper';
import { MenuTabsComponent } from '../MenuTabs/MenuTabsComponent';
import { getCurrentInterestIssues } from '../../redux/actions/interestIssues';
import { AppMenuTabs, MenuTabSectionEnum } from '../MenuTabs/userTabsInterface';
import { PortfolioTitle } from './PortfolioTitle/PortfolioTitle';
import CustomTabPanel from './CustomTabPanel/CustomTabPanel';
import EditCustomTabButton from '../../components/Util/Buttons/EditCustomTabButton/EditCustomTabButton';
import { getNewFilteredIndicators } from '../../api/indicatorApi';
import { updateCreatingOrEditing, updateMenuTabsFirstLoad } from '../../redux/actions/menuTabs';
import {
  resetIndicatorDetailFilter,
  resetIndicatorDetailFilterChart,
} from '../../redux/actions/indicatorDetailFilters';
import { trackActionClicked } from '../../api/trackActionClickedApi';
import { TrackedActions } from '../../models/TrackedActionsEnum';
import ModalProjectIntegration from '../PortfolioDetail/ModalProjectIntegration/ModalProjectIntegration';
import Filter from './Filter/Filter';
import PortfolioProductTable from './PortfolioProductTable/PortfolioProductTable';
import { useCards } from '../../context/CardContext';
import { setIndicatorResponse, setLoading } from '../../redux/actions/dataTable';

const SortableItem = SortableElement(({ value }) => <CardIndicator card={value}></CardIndicator>);
const SortableList = SortableContainer(({ items }) => {
  const arrItems = items.filter(elemente => elemente.id !== 15);

  return (
    <div className="sortable-container" style={isIE11() ? { width: '86vw' } : {}}>
      {arrItems.map((value, index) => (
        <SortableItem key={value.id} index={index} value={value} />
      ))}
      <AddIndicator></AddIndicator>
    </div>
  );
});

export const getTabLabelText = (label, t) => {
  return label === 'global_bid' || label === 'my_portfolio' ? t(label) : label;
};

const emptySectors = sectors => sectors && !sectors.length;

const tabId0OrCreatingEditing = (menuTabs, creatingOrEditing) =>
  menuTabs.portfolioSelectedTab.tabId === 0 || creatingOrEditing;

const obtainDataIfEmpty = (regions, sectors, divisions, interestIssues, dispatch) => {
  if (!regions.length) {
    getRegionsAndCountries().then(regionsData => {
      dispatch(addRegions(regionsData));
    });
  }

  if (emptySectors(sectors)) {
    getSectors().then(sectorsData => {
      dispatch(addSectors(sectorsData));
    });
  }

  if (!divisions.length) {
    dispatch(getCurrentDivisions());
  }

  if (!interestIssues.length) {
    dispatch(getCurrentInterestIssues());
  }
};
const addTrackTypeProjectPortfolio = (projectTypeFilter: ProjectProductTypes[]) => {
  const isTypeProjectPortfolioHomeTracked = localStorage.getItem('hasTrackProjectProductPortfolioHome');
  if (!isTypeProjectPortfolioHomeTracked) {
    const stringFiltersPortfolio = [...projectTypeFilter].sort((a: number, b: number) => a - b).join('|');
    trackActionClicked(TrackedActions.portfolioTypeFilterProjectChanged, stringFiltersPortfolio);
    localStorage.setItem('hasTrackProjectProductPortfolioHome', JSON.stringify(true));
  }
};
const Portfolio = () => {
  const indicators = useSelector<any, Indicator[]>(state => state.indicators);
  const { setCards } = useCards();
  const dispatch = useDispatch();
  const summaryIndicator = indicators.filter(elemente => elemente.id === 15);
  setCards(summaryIndicator[0]);

  const user = useSelector<any, User>(state => state.user);
  const filterIndicators = useSelector<any, FilterIndicators>(state => state.filterIndicators);

  //HOOKS
  const { t } = useTranslation();

  const theme = useTheme();

  //STATE
  const [totalProjects, setTotalProjects] = React.useState(false);
  const [filterIsOpen, setFilterIsOpen] = React.useState(false);
  const [customTabPanelIsOpen, setCustomTabPanelIsOpen] = React.useState(false);
  const [isCustomTabEdit, setIsCustomTabEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { divisions, sectors, regions, interestIssues } = useSelector<any, any>(state => state);
  const menuTabs = useSelector<any, AppMenuTabs>(state => state.menuTabs);
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const creatingOrEditing = menuTabs.creatingOrEditing;

  const isReduxLoading = useSelector<any>(state => state.dataTableInfo.indicatorLoading);

  React.useEffect(() => {
    dispatch(resetCurrentIndicator());
    dispatch(getIndicatorsList());
    resetSessionFilter();
    resetLocalStorageOnPortfolioMount();
    dispatch(resetIndicatorDetailFilterChart());
    obtainDataIfEmpty(regions, sectors, divisions, interestIssues, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(resetCurrentIndicator());
    dispatch(resetIndicatorProjects());
    dispatch(resetIndicatorAccordances());
    addTrackTypeProjectPortfolio(filterIndicators.common.projectTypeFilters);
    if (!menuTabs.firstLoad) {
      handleCommonFilterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIndicators.common]);

  React.useEffect(() => {
    dispatch(resetIndicatorDetailFilter());

    if (!menuTabs.firstLoad && tabId0OrCreatingEditing(menuTabs, creatingOrEditing)) {
      handleCommonFilterChange();
      if (creatingOrEditing) {
        dispatch(updateCreatingOrEditing(false));
      }
    }
    if (menuTabs.firstLoad) {
      dispatch(updateMenuTabsFirstLoad(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTabs]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(sortIndicatorsList(oldIndex, newIndex, t));
  };

  const closeModal = () => {
    setFilterIsOpen(false);
  };

  const handleCommonFilterChange = async () => {
    dispatch(setLoading(true));
    setIsLoading(true);
    try {
      const indicatorsToProcess = await getNewFilteredIndicators();
      dispatch(setIndicatorResponse(indicatorsToProcess));
      startProcessIndicators(
        indicatorsToProcess,
        translateMultipleText(` ${' '} ${portfolioSelectedTab.title}`, t),
        filterIndicators.common.projectTypeFilters,
      );
      setTotalProjects(emptyProjects(indicatorsToProcess.totalProjects));
    } catch (err) {
      console.error(err, 'Error fetching data from indicators');
    } finally {
      dispatch(setLoading(false));
      setIsLoading(false);
    }
  };

  const startProcessIndicators = (inds, titleFromTab, projectTypeFilter = [ProjectProductTypes.Loan]) => {
    const result = processIndicators(inds, {
      titleFromTab,
      projectTypeFilter,
    });
    const elementSummary: any = result.find(elem => elem.id === 15);
    const indexSummary: number = result.indexOf(elementSummary);
    const sendEndSummary = arrayMove(result, indexSummary, result.length);

    dispatch(addIndicators(sendEndSummary));
    setIsLoading(false);
  };

  const handleCustomTabButtons = (fromAddButton: boolean) => {
    setIsCustomTabEdit(!fromAddButton);
    setCustomTabPanelIsOpen(true);
    trackActionClicked(TrackedActions.addCustomTabClicked);
  };

  return (
    <div className="indicators-container">
      <div className="top-header">
        <MenuTabsComponent
          selectedTab={portfolioSelectedTab}
          menuTabSection={MenuTabSectionEnum.PORTFOLIO_MENU_TAB}
          handleAddTabButton={() => handleCustomTabButtons(true)}
        />
      </div>
      <div className="header-title">
        <h1>
          <PortfolioTitle userLanguage={user.language.encodedName} selectedMenuTab={portfolioSelectedTab} />
        </h1>

        <div className={'filter-button'} id="filterComponent">
          {portfolioSelectedTab.tabId === 0 && (
            <ReportButton
              customStyle={{ marginRight: setMarginRight() }}
              reportType={ReportType.Customizable}
              disabled={totalProjects}
            />
          )}
          <EditCustomTabButton
            handleButtonClick={() => handleCustomTabButtons(false)}
            selectedTab={portfolioSelectedTab}
          />
          <FilterComponent
            closeModal={closeModal}
            isOpen={filterIsOpen}
            indicatorCall={true}
            calledFrom={CalledFrom.PORTFOLIO}
          />
        </div>
      </div>
      {/* Se descomenta e inicia el uso del componente Filter. */}
      <Filter />
      {/*<FilterBar /> */}
      <CustomTabPanel
        isOpen={customTabPanelIsOpen}
        closePanel={() => setCustomTabPanelIsOpen(false)}
        isEdit={isCustomTabEdit}
        calledFrom={CalledFrom.CUSTOM_TABS_PORTFOLIO}
        selectedTab={portfolioSelectedTab}
      />
      <div className="portfolio-product-table">
        <PortfolioProductTable />
      </div>
      {isLoading || isReduxLoading ? (
        <CardPortfolioSkeletonList />
      ) : (
        <TabPanel
          key={portfolioSelectedTab.internalId}
          value={portfolioSelectedTab.internalId}
          index={portfolioSelectedTab.internalId}
          dir={theme.direction}
        >
          <SortableList items={indicators} onSortEnd={onSortEnd} axis="xy" pressDelay={200} />
        </TabPanel>
      )}
      <ModalProjectIntegration />
    </div>
  );
};

export default Portfolio;

// const openModalFilter = () => {
//   setFilterIsOpen(true);
//   trackActionClicked(TrackedActions.filterClickedButton);
// };
