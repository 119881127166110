import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import './Table.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { PENDING_SIGNATURE_OR_LEGIS_APPROVAL } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { TabsFiltersEnum } from '../TabsFilter/TabsFilter';
import { EarlyWarningsTimeLapse } from '../../EarlyWarningsTimeLapse/EarlyWarningsTimeLapse';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import {
  addFilterConditionOnIgSection,
  dateIsAfterToday,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByDays,
  getUniqueCodeProjectsList,
} from '../../EarlyWarningsFilters/Util';
import { useSelector } from 'react-redux';
import { handleClassName, isCtOrPendingRatificationTabSelected } from './Util';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { handleValueOrSlash } from '../../PortfolioDetailTablesUtil';
import { isDiferentToIGLower } from '../../EarlyWarningsTimeLapse/Util';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { Tooltip } from '@material-ui/core';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { Pagination } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headersPending = ['last_project', 'last_operation', 'name', 'project_manager', 'approval_date_long', 'days_since_approval', "status",];
const selectedKeysPending = ['code', 'loanNumber', 'title', 'field4', 'field1', 'field2', 'field8'];
const headersRatification = ['last_project', 'last_operation', 'name', 'approval_date_long', 'days_since_approval', "status"];
const selectedKeysRatification = ['code', 'loanNumber', 'title', 'field1', 'field2', 'field8'];

const {
  container,
  tableHeaderSignaturePending,
  tableHeaderPendingRatification,
  tableRowSignaturePending,
  tableRowPendingRatification,
  rowValue,
  rowList,
  rowValueName,
  rowValueDate,
  headerTitleClickable,
  rowValueCenterd,
  rowValueCapitalized,
  headerCenteredTitleClickable,
  headerTitleApplyPaddingLeft,
  tableHeaderCTPendingSignature,
  tableRowCTSignaturePending,
  borderTable,
} = styles;

enum SIGNATURE_PENDING_FLAG {
  'SIGNATURE_PENDING',
  'PENDING_RATIFICATION',
}

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const handleDaysDiff = (filterColor, projectD) => {
  if (filterColor === -1) {
    return true;
  }
  const projectDate = new Date(projectD);
  if (filterColor === FilterColorSelected.GREEN) {
    return dateIsAfterToday(projectDate);
  } else if (filterColor === FilterColorSelected.YELLOW) {
    return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByDays(364), getSubstractedDateByDays(0));
  }
  return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByDays(365));
};

const getPendingSignatureProjectsQtyByEearlyWarning = (projects, projectTypeFilterOnDetail) => {
  const filterCondition = isDiferentToIGLower(projectTypeFilterOnDetail)
    ? p => p.field8 === SIGNATURE_PENDING_FLAG[0]
    : p => p.field8 === SIGNATURE_PENDING_FLAG[0] && isDiferentToIGLower(p.field9);
  const projectsExpired = getUniqueCodeProjectsList(projects).filter(filterCondition);
  let green = 0;
  let yellow = 0;
  let red = 0;

  for (const p of projectsExpired) {
    if (dateIsAfterToday(new Date(p.field6))) {
      green++;
    } else if (
      filterProjectsBetweenLimitsByDays(new Date(p.field6), getSubstractedDateByDays(364), getSubstractedDateByDays(0))
    ) {
      yellow++;
    } else if (filterProjectsBetweenLimitsByDays(new Date(p.field6), getSubstractedDateByDays(365))) {
      red++;
    }
  }
  return [green, yellow, red];
};

type Props = {
  selectedTab: TabsFiltersEnum;
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading?: boolean;
};
export const PendingSignatureTable = ({ selectedTab, earlyWarningsTab, projectsQuantities, loading }: Props) => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [filterdProjects, setFilteredProjects] = useState<any>(projects);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: filterdProjects,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    const filterNewProjects = [...projects];
    projectsQuantities(getPendingSignatureProjectsQtyByEearlyWarning(projects, projectTypeFilterOnDetail));
    setFilteredProjects(filterNewProjects.filter(p => p.field8 === SIGNATURE_PENDING_FLAG[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setFilteredProjects(
      projects.filter(p => {
        return (
          p.field8 === SIGNATURE_PENDING_FLAG[selectedTab] &&
          handleDaysDiff(earlyWarningsTab.indexOf(true), p.field6) &&
          addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field9)
        );
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(PENDING_SIGNATURE_OR_LEGIS_APPROVAL);
    storage &&
      sessionStorage.setItem(
        PENDING_SIGNATURE_OR_LEGIS_APPROVAL,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  const headers = selectedTab === 0 ? headersPending : headersRatification;
  const selectedKeys = selectedTab === 0 ? selectedKeysPending : selectedKeysRatification;
  const pendingSignatureHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickable,
      criteria: 'field4',
      criteriaType: CriteriaType.string,
      label: 'project_manager',
      hide: selectedTab !== TabsFiltersEnum.SIGNATURE_PENDING,
    },
    {
      className: headerCenteredTitleClickable,
      criteria: 'field1',
      criteriaType: CriteriaType.date,
      label: 'approval_date_long',
    },
    {
      className: headerCenteredTitleClickable,
      criteria: 'field2',
      criteriaType: CriteriaType.number,
      label: 'days_since_approval',
    },
    {
      className: headerTitleApplyPaddingLeft,
      criteria: 'field3',
      criteriaType: CriteriaType.string,
      label: 'status',
    },
    {
      className: headerTitleClickable,
      criteria: 'field6',
      criteriaType: CriteriaType.date,
      label: 'early_warnings',
      hide: isCtOrPendingRatificationTabSelected(selectedTab, projectTypeFilterOnDetail),
    },
  ];

  const dataExcel = useDataNormalize(headers, selectedKeys, filterdProjects);
  return loading ? (
    <div className="pending-signature-or-legis-approval__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : filterdProjects.length !== 0 ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
      <div className={borderTable}>
        <div
          className={handleClassName(
            selectedTab,
            projectTypeFilterOnDetail,
            tableHeaderPendingRatification,
            tableHeaderSignaturePending,
            tableHeaderCTPendingSignature,
          )}
        >
          {pendingSignatureHeaderTableSetup.map(ps => {
            const { className, criteria, criteriaType, label, hide } = ps;
            return (
              !hide && (
                <h4
                  className={className}
                  onClick={() => handleSort(criteria, criteriaType)}
                  key={`${className}-${criteria}`}
                >
                  {t(label)}
                </h4>
              )
            );
          })}
        </div>
        <ul className={rowList}>
          {currentList &&
            currentList.map(project => {
              const {
                loanNumber,
                code,
                title,
                field4: projectManager,
                field1: approvalDate,
                field2: daysSinceApproval,
                field3: status,
                field6: calculatedDateByCountry,
                field9: projectProductType,
                frontendId,
              } = project;
              return (
                <li
                  className={handleClassName(
                    selectedTab,
                    projectTypeFilterOnDetail,
                    tableRowPendingRatification,
                    tableRowSignaturePending,
                    tableRowCTSignaturePending,
                  )}
                  key={`${loanNumber}${code}${frontendId}`}
                >
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                      {code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>
                  {selectedTab === TabsFiltersEnum.SIGNATURE_PENDING && (
                    <p className={rowValueCapitalized}>{projectManager && projectManager.toLowerCase()}</p>
                  )}

                  <p className={rowValueDate}>{renderDateOrSlash(approvalDate)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(daysSinceApproval)}</p>
                  <p className={rowValue}>{handleValueOrSlash(t(status))}</p>

                  {!isCtOrPendingRatificationTabSelected(selectedTab, projectTypeFilterOnDetail) && (
                    <p className={rowValue}>
                      <EarlyWarningsTimeLapse
                        filterToApply={{
                          green: dateIsAfterToday(new Date(calculatedDateByCountry)),
                          yellow: filterProjectsBetweenLimitsByDays(
                            new Date(calculatedDateByCountry),
                            getSubstractedDateByDays(364),
                            getSubstractedDateByDays(0),
                          ),
                          red: filterProjectsBetweenLimitsByDays(
                            new Date(calculatedDateByCountry),
                            getSubstractedDateByDays(365),
                          ),
                        }}
                        projectStringDate={handleValueOrSlash(calculatedDateByCountry)}
                        projectProductType={projectProductType}
                        showTimeLapsText={dateIsAfterToday(new Date(calculatedDateByCountry)) ? false : true}
                        indicatorKey={IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL}
                      />
                    </p>
                  )}
                </li>
              );
            })}
        </ul>
      </div>


      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={filterdProjects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
      <ProjectCounter
        arrayToCount={filterdProjects}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED}
      />
    </div>
  ) : (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
      <div className={borderTable}>
        <div
          className={handleClassName(
            selectedTab,
            projectTypeFilterOnDetail,
            tableHeaderPendingRatification,
            tableHeaderSignaturePending,
            tableHeaderCTPendingSignature,
          )}
        >
          {pendingSignatureHeaderTableSetup.map(ps => {
            const { className, criteria, criteriaType, label, hide } = ps;
            return (
              !hide && (
                <h4
                  className={className}
                  onClick={() => handleSort(criteria, criteriaType)}
                  key={`${className}-${criteria}`}
                >
                  {t(label)}
                </h4>
              )
            );
          })}
        </div>
        {/* <ul className={rowList}>
          {currentList &&
            currentList.map(project => {
              const {
                loanNumber,
                code,
                title,
                field4: projectManager,
                field1: approvalDate,
                field2: daysSinceApproval,
                field3: status,
                field6: calculatedDateByCountry,
                field9: projectProductType,
                frontendId,
              } = project;
              return (
                <li
                  className={handleClassName(
                    selectedTab,
                    projectTypeFilterOnDetail,
                    tableRowPendingRatification,
                    tableRowSignaturePending,
                    tableRowCTSignaturePending,
                  )}
                  key={`${loanNumber}${code}${frontendId}`}
                >
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                      {code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>
                  {selectedTab === TabsFiltersEnum.SIGNATURE_PENDING && (
                    <p className={rowValueCapitalized}>{projectManager && projectManager.toLowerCase()}</p>
                  )}

                  <p className={rowValueDate}>{renderDateOrSlash(approvalDate)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(daysSinceApproval)}</p>
                  <p className={rowValue}>{handleValueOrSlash(t(status))}</p>

                  {!isCtOrPendingRatificationTabSelected(selectedTab, projectTypeFilterOnDetail) && (
                    <p className={rowValue}>
                      <EarlyWarningsTimeLapse
                        filterToApply={{
                          green: dateIsAfterToday(new Date(calculatedDateByCountry)),
                          yellow: filterProjectsBetweenLimitsByDays(
                            new Date(calculatedDateByCountry),
                            getSubstractedDateByDays(364),
                            getSubstractedDateByDays(0),
                          ),
                          red: filterProjectsBetweenLimitsByDays(
                            new Date(calculatedDateByCountry),
                            getSubstractedDateByDays(365),
                          ),
                        }}
                        projectStringDate={handleValueOrSlash(calculatedDateByCountry)}
                        projectProductType={projectProductType}
                        showTimeLapsText={dateIsAfterToday(new Date(calculatedDateByCountry)) ? false : true}
                        indicatorKey={IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL}
                      />
                    </p>
                  )}
                </li>
              );
            })}
        </ul> */}
      </div>

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={filterdProjects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
      <ProjectCounter
        arrayToCount={filterdProjects}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED}
      />
    </div>
  );
};
