import React, { useState } from 'react';
import styles from './ReviewAccordance.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewAccordance from '../../Util/Modals/NewAccordance/NewAccordance';
import { setCurrentProject, resetCurrentProject } from '../../../redux/actions/currentProject';
import { SuccessModal } from '../../Util/Modals/SuccessModal/SuccessModal';
import bgImg from '../../../assets/icons/u_comment.svg';
import { setCurrentReview } from '../../../redux/actions/currentReview';

type Props = {
  project: any;
  revision?: any;
};

const ReviewAccordance = ({ project, revision }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<any>(false);
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const [showSuccessModal, setShowSuccessModal] = useState<any>(false);

  const toggleModal = (value, submitSuccess = false) => {
    if (value) {
      dispatch(setCurrentProject(project));
    } else {
      dispatch(resetCurrentProject());
    }
    setShowModal(value);
    if (submitSuccess === true) {
      setShowSuccessModal(true);
      if (!revision) {
        dispatch(setCurrentReview({ ...currentReview }));
      }
    }
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      className={styles.container}
    >
      <span className={styles.createAccordance} onClick={e => toggleModal(true)}>
        {t('create_accordance')}
      </span>
      <NewAccordance
        closeModal={submitSuccess => toggleModal(false, submitSuccess)}
        isOpen={showModal}
        currentReview={revision || currentReview}
      />
      <SuccessModal
        isOpen={showSuccessModal}
        closeModal={() => setShowSuccessModal(false)}
        title={t('accordance_create_success_title')}
        subTitle={t('accordance_create_success_subtitle')}
        buttonText={t('close')}
        backgroundImg={bgImg}
      />
    </div>
  );
};

export default ReviewAccordance;
