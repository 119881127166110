import React from 'react';
import Advance from '../Advancev2/Advance';
import FinancialInformation from '../../../models/FinancialInformation';
import TemporaryAdvance from '../../../models/TemporaryAdvance';
import './AdvanceData.scss';
import { useTranslation } from 'react-i18next';
import { useMapToExecutionPeriodAdvance } from '../../../mappers/useMapToExecutionPeriodAdvance';
import { useMapToFinancialAdvance } from '../../../mappers/useMapToFinancialAdvance';

type Props = {
  financialInformation?: FinancialInformation;
  temporaryAdvance: TemporaryAdvance;
  titleOnTop?: boolean;
  isWarranty?: any;
  dataBar?: any;
};
export const AdvanceData = (props: Props) => {
  const { temporaryAdvance, financialInformation, dataBar } = props;
  const { t } = useTranslation();
  const excecutionPeriodAdvance = useMapToExecutionPeriodAdvance(temporaryAdvance);
  const financialAdvanceTitle = useMapToFinancialAdvance(financialInformation);

  return (
    <>
      {temporaryAdvance ? (
        <Advance {...excecutionPeriodAdvance} />
      ) : (
        <div className="empty-object-container linebox">
          <div className="dash">-</div>
          <div className="title"> {t('temporary_advance')}</div>
        </div>
      )}
      <div className="linebox">
        {financialInformation ? <Advance {...financialAdvanceTitle} dataBar={dataBar} /> : null}
      </div>
    </>
  );
};

export default AdvanceData;
