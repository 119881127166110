import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getProjectBaseInformation } from '../../../api/projectApi';
import useGcmAlerts from '../../../hooks/useGcmAlerts';
import { ProjectExecutionTypes } from '../../../models/ProjectExecutionTypes';
import { getProject } from '../../../redux/actions/currentProject';
import { getIdFromUrl } from '../../../services/numberUtil';
import AdvanceData from '../GeneralInformation/AdvanceData';
import { initialDisbursement, initialDivision, initialTemporaryAdvance } from '../GeneralInformation/util';
import { RelatedProjectLinks } from '../RelatedProjectLinks/RelatedProjectLinks';
// import { DisbursementChart } from './DisbursementChart/DisbursementChart';
import styles from './GeneralInformationTC.module.scss';
import { SimpleValue } from './SimpleValue/SimpleValue';
import DateText from '../DateText/DateText';
import { DateTextTypes } from '../../../models/DateTextTypes';
// import { ListEsg } from '../ListEsg/ListEsg';
// import { PmrClassification } from '../../../models/PmrClassification';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';

type Props = {
  executedBy?: ProjectExecutionTypes;
};

export const checkHundreadPercent = (percentRealizedDisbursement, percentCommitedAmount, percentAvailableAmount) => {
  const totalPercent =
    Math.round(percentRealizedDisbursement) + Math.round(percentCommitedAmount) + Math.round(percentAvailableAmount);

  let difference = 0;
  if (totalPercent > 100) {
    difference = totalPercent - 100;
    return Math.round(percentCommitedAmount) - difference;
  } else if (totalPercent < 100) {
    difference = 100 - totalPercent;
    return Math.round(percentCommitedAmount) + difference;
  }
  return Math.round(percentCommitedAmount);
};

export const createDisbursedChartObject = financialInformation => {
  return {
    total: financialInformation.totalDisbursementAmount,
    disbursed: {
      title: 'disbursed',
      percentage: Math.round(financialInformation.percentRealizedDisbursement),
      total: financialInformation.realizedDisbursementAmount,
    },
    commited: {
      title: 'committed_supervision',
      percentage: checkHundreadPercent(
        financialInformation.percentRealizedDisbursement,
        financialInformation.percentCommitedAmount,
        financialInformation.percentAvailableAmount,
      ),
      total: financialInformation.commitedAmount,
    },
    available: {
      title: 'available',
      percentage: Math.round(financialInformation.percentAvailableAmount),
      total: financialInformation.availableAmount,
    },
  };
};

export const GeneralInformationTC = ({ executedBy }: Props) => {
  const { currentProject } = useSelector<any, any>(state => state);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { totalActiveAlerts } = useGcmAlerts({});
  const [extensionsMade, setExtensionsMade] = useState<number | null>(0);
  const {
    name = '',
    division = initialDivision,
    code = '',
    temporaryAdvance = initialTemporaryAdvance,
    financialInformation = initialDisbursement,
    loanNumber,
    loans,
    // pmrClassification = PmrClassification.Green,
    // pmrValue = 0,
    // esgClasification = '',
    // esgRisk = 0,
    // esgSafeguard = 0,
    projectProductType,
  } = currentProject;

  // const disbursedChartObject = createDisbursedChartObject(financialInformation);
  const getBaseInfo = async () => {
    const response = await getProjectBaseInformation(currentProject.id);
    setExtensionsMade(response.numberOfExtensionsMade);
  };

  useEffect(() => {
    dispatch(getProject(getIdFromUrl(history.location.search)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (currentProject.id) {
      getBaseInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const {
    generalInfoContainer,
    top,
    left,
    right,
    code: codeStyle,
    division: divisionStyle,
    bottom,
    barposition,
    linksContainer,
  } = styles;

  const { t } = useTranslation();
  return (
    <div className={generalInfoContainer}>
      <div className={top}>
        <div className={left}>
          <h1>{name}</h1>
          {/* <h3 className="loan">
            {`${t(projectProductType === ProjectProductTypes.GUARANTEE ? 'warranty' : 'loan')}: ${loanNumber}`}
            {loans?.length ? <RelatedProjectLinks loans={loans} mainLoanNumber={loanNumber} /> : null}
            <Pmr classification={pmrClassification} value={pmrValue} projectCode={code} />
          </h3> */}
          <div className={linksContainer}>
            <h3 className="loan">
              {`${t('operation')}: ${loanNumber}`}
              {loans?.length ? <RelatedProjectLinks loans={loans} mainLoanNumber={loanNumber} /> : null}
            </h3>
          </div>
        </div>
        <div className={right}>
          <span className={codeStyle}>{code}</span>
          <div className={divisionStyle}>{division.code}</div>
        </div>
      </div>
      <div className={bottom}>
        {executedBy === ProjectExecutionTypes.BID ? (
          <>
            <AdvanceData
              dataBar={executedBy}
              temporaryAdvance={temporaryAdvance}
              financialInformation={financialInformation}
              titleOnTop={true}
            />
          </>
        ) : (
          <AdvanceData
            financialInformation={financialInformation}
            temporaryAdvance={temporaryAdvance}
            titleOnTop={true}
          />
        )}

        <div className="advance-container">
          <h3>{`${t('disbursement_title')}`}</h3>
          {projectProductType === ProjectProductTypes.GUARANTEE ? null : (
            <>
              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.lastDisbursementDate}
                text={'last_accomplished_disbursement_complete'}
              />

              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.nextDisbursementDate}
                text={'next_projected_disbursement'}
              />
              <div className="container-project-detail-date">
                <div className="project-detail-date-text-container">
                  <span className="text">{`${t('disbursement_minimum')}`}</span>
                  <span className="date">
                    {`${financialInformation.minimumAmountToJustify.toLocaleString()} ${t('dolar_sign')}`}{' '}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="botton-container-button">
            {/* <button className="btn-primary-text" onClick={handleSeeMoreClick}>
              {t('see_more')}
            </button> */}
          </div>
        </div>
        <div className='advance-container'>
          <SimpleValue
            firstLineTitle={t('number_of_extensions_ct')}
            value={extensionsMade !== null ? extensionsMade.toString() : '-'}
          />
          {/* <div className="bottom-container">
            <ListEsg values={{ esgClasification, esgRisk, esgSafeguard }} />
          </div> */}

          {/* <SimpleValue
            firstLineTitle={t('days_from_last_disbursement_done')}
            value={
              financialInformation.lastDisbursementInDays
                ? `${financialInformation.lastDisbursementInDays} ${t('days')}`
                : '-'
            }
            maxWidth={170}
          /> */}

          <SimpleValue
            firstLineTitle={t('detail_of')}
            secondLineTitle={t('active_alerts')}
            value={`${totalActiveAlerts} ${t('active_alerts')}`}
            alerts={totalActiveAlerts > 0}
            hide={totalActiveAlerts === 0}
          />
        </div>
        </div>
    </div>
  );
};
