import React from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import './ReportProjectConfig.scss';
import { styles } from '../Styles';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import { disableEscKey } from '../Util';
import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import CustomCheckbox from '../../CustomCheckbox/CustomCheckbox';
import { setProjectReportSections } from '../../../../redux/actions/projectReportSections';
import { createProjectReport } from '../../../../api/reportApi';
import { ReportSectionsEnum, ReportSectionTabs } from '../../../../models/ReportEnums';
import { setProjectTabValue } from '../../../../redux/actions/projectTabValue';
import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import { ShowComponentEnum, showCorrespondingComponent } from '../../../../services/util';
import BaseModal from '../BaseModal';
import { ProjectProductTypeClassification } from '../../../../models/ProjectProductTypeClassification';
import { generateBlob } from '../../../../services/imageService';
import ExecutionByComponent from '../../../ProjectDetail/ProjectTabs/Financial/ExecutionByComponent/ExecutionByComponent';
import ComponentBalances from '../../../../models/ComponentBalances';
import { PerformancePMRCollapsed } from '../../../ProjectDetail/PerformancePMRCollapsed/PerformancePMRCollapsed';
import DisbursementProjectionEvolution from '../../../ProjectDetail/DisbursementProjectionEvolution/DisbursementProjectionEvolution';
import { getProjectFinancial } from '../../../../api/projectApi';
import { createDisbursedChartObject } from '../../../ProjectDetail/GeneralInformationTC/GeneralInformationTC';
import SpinningLoader from '../../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';

const COMPONENT_BALANCES_REPORT_IMAGE = 'COMPONENT_BALANCES_REPORT_IMAGE';
const PMR_EVOLUTION_DISBURSEMENTS_REPORT_DETAIL = 'PMR_EVOLUTION_DISBURSEMENTS_REPORT_DETAIL';

type Props = {
  isOpen: boolean;
  closeModal: any;
  submit: any;
};

const getInitialState = (projectType, executedBy, igGreater3) => {
  const tcpLowerIgr = [
    { key: ReportSectionsEnum.baseInfo, value: true, resolved: false },
    { key: ReportSectionsEnum.products, value: true, resolved: false },
    { key: ReportSectionsEnum.accordances, value: true, resolved: false },
    { key: ReportSectionsEnum.financial, value: true, resolved: false },
  ];

  if (showCorrespondingComponent(projectType, executedBy, igGreater3) === ShowComponentEnum.LOAN_IGR_GREATER) {
    return [
      { key: ReportSectionsEnum.baseInfo, value: true, resolved: false },
      { key: ReportSectionsEnum.planning, value: true, resolved: false },
      { key: ReportSectionsEnum.accordances, value: true, resolved: false },
      { key: ReportSectionsEnum.financial, value: true, resolved: false },
      { key: ReportSectionsEnum.risk, value: true, resolved: false },
    ];
  } else if (
    showCorrespondingComponent(projectType, executedBy, igGreater3) === ShowComponentEnum.TCP_BID_IGR_LOWER ||
    showCorrespondingComponent(projectType, executedBy, igGreater3) === ShowComponentEnum.TCP_AGENCY
  ) {
    return tcpLowerIgr;
  } else if (showCorrespondingComponent(projectType, executedBy, igGreater3) === ShowComponentEnum.GUARANTEE) {
    return [
      { key: ReportSectionsEnum.baseInfo, value: true, resolved: false },
      { key: ReportSectionsEnum.accordances, value: true, resolved: false },
      { key: ReportSectionsEnum.risk, value: true, resolved: false },
    ];
  } else {
    return tcpLowerIgr;
  }
};

const hasPmrEvolutionData = (pmrEvolution: any) => pmrEvolution && pmrEvolution.length;

const updateOptions = (dispatch, projectReportSections, isChecked, key) => {
  const newOptions = projectReportSections.map(item => {
    if (item.key === key) {
      item.value = isChecked;
    }
    return item;
  });
  dispatch(setProjectReportSections(cloneDeep(newOptions)));
  trackActionClicked(TrackedActions.reportOptionClicked);
};

const hasComponentBalances = componentBalances => componentBalances && componentBalances.length;

const hasSelectedFinancial = projectReportSections =>
  projectReportSections.find(i => i.key === ReportSectionsEnum.financial && i.value);

const closeIfNotSubmitting = (e: any, closeModal: Function, submitting: boolean) => {
  if (!submitting) {
    disableEscKey(e, closeModal);
  }
};

const processAndSubmit = (componentBalances, createImageAndSubmit, createReportAndfinishModal) => {
  if (hasComponentBalances(componentBalances)) {
    createImageAndSubmit();
  } else if (componentBalances) {
    createReportAndfinishModal();
  }
};

const ReportProjectConfig = (props: Props) => {
  const { isOpen, closeModal, submit } = props;
  const { t } = useTranslation();
  const currentProject = useSelector<any, any>(state => state.currentProject);
  const dispatch = useDispatch();
  const projectReportSections = useSelector<any, any>(state => state.projectReportSections);
  const [disabledButtonWhenClick, setDisabledButtonWhenClick] = React.useState(false);
  const {
    execution,
    projectProductType,
    projectProductTypeClassification,
    pmrEvolution,
    disbursementsProjectionsEvolution,
    financialInformation,
  } = currentProject;

  const [submitting, setSubmitting] = React.useState(false);
  const [componentBalances, setComponentBalances] = React.useState<ComponentBalances[] | null>(null);

  const disbursedChartObject = createDisbursedChartObject(financialInformation);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        setProjectReportSections(
          cloneDeep(
            getInitialState(
              projectProductType,
              execution,
              projectProductTypeClassification &&
                projectProductTypeClassification['code'] === ProjectProductTypeClassification.IGR_GREATER_THAN_3MM,
            ),
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const createImageAndSubmit = async () => {
    setTimeout(async () => {
      const images: any = [];
      const COMPONENT_BALANCES_IMG = await generateBlob(`${COMPONENT_BALANCES_REPORT_IMAGE}`, {
        width: 700,
        height: 350,
      });
      images.push(COMPONENT_BALANCES_IMG);
      if (hasPmrEvolutionData(pmrEvolution)) {
        const PMR_EVOLUTION_DISBURSEMENTS_IMG = await generateBlob(`${PMR_EVOLUTION_DISBURSEMENTS_REPORT_DETAIL}`, {
          width: 700,
          height: 450,
        });
        images.push(PMR_EVOLUTION_DISBURSEMENTS_IMG);
      }
      createReportAndfinishModal(images);
    }, 500);
  };

  React.useEffect(() => {
    processAndSubmit(componentBalances, createImageAndSubmit, createReportAndfinishModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentBalances]);

  const getFinancialInfo = async currentProjectId => {
    const response = await getProjectFinancial(currentProjectId);
    setComponentBalances(response.componentBalances);
  };

  const createReportAndfinishModal = async (images?) => {
    await createProjectReport(currentProject.id, projectReportSections, images);
    submit();
    setSubmitting(false);
    closeModal();
    setDisabledButtonWhenClick(false);
    trackActionClicked(TrackedActions.generateReportClicked);
  };

  const openReportModal = async () => {
    setDisabledButtonWhenClick(true);
    setSubmitting(true);
    dispatch(setProjectTabValue(ReportSectionTabs.PROJECTS));

    if (hasSelectedFinancial(projectReportSections)) {
      await getFinancialInfo(currentProject.id);
    } else if (hasPmrEvolutionData(pmrEvolution)) {
      const PMR_EVOLUTION_DISBURSEMENT_IMG = await generateBlob(`${PMR_EVOLUTION_DISBURSEMENTS_REPORT_DETAIL}`, {
        width: 850,
        height: 600,
      });
      createReportAndfinishModal([PMR_EVOLUTION_DISBURSEMENT_IMG]);
    } else {
      createReportAndfinishModal();
    }
  };

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onRequestClose={e => {
          closeIfNotSubmitting(e, closeModal, submitting);
        }}
        style={styles}
        className="modal-report-project-config-content"
      >
        <div className="modal-report-project-config-container">
          {submitting ? (
            <SpinningLoader customText={'generating_report'} customStyle={{ margin: 'auto' }} />
          ) : (
            <>
              <div className="close-cross-container">
                <CloseCrossButton handleClick={closeModal} />
              </div>
              <h3>{`${t('generate_report_of_project')} ${currentProject.code}`}</h3>
              <div className="options-list">
                <span className="subtitle">{t('sections_to_include')}</span>
                {projectReportSections
                  .filter(a => a.key !== ReportSectionsEnum.baseInfo)
                  .map(item => {
                    return (
                      <CustomCheckbox
                        key={item.key}
                        isChecked={item.value}
                        handleOnClick={isChecked => {
                          updateOptions(dispatch, projectReportSections, isChecked, item.key);
                        }}
                        labelText={t(ReportSectionsEnum[item.key])}
                      />
                    );
                  })}
              </div>
              <div className="horizontal">
                <SaveButton
                  handleClick={openReportModal}
                  disabled={disabledButtonWhenClick}
                  customText={t('generate')}
                />
              </div>
            </>
          )}
        </div>
      </BaseModal>
      {isOpen ? (
        <ImagesToSubmit
          currentProject={currentProject}
          componentBalances={componentBalances}
          disbursedChartObject={disbursedChartObject}
          financialInformation={financialInformation}
        />
      ) : null}
      {isOpen && pmrEvolution && pmrEvolution.length ? (
        <div style={{ top: '20px', position: 'absolute', backgroundColor: '#ffffff', zIndex: -9999 }}>
          <div
            id={PMR_EVOLUTION_DISBURSEMENTS_REPORT_DETAIL}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <PerformancePMRCollapsed data={pmrEvolution} t={t} forReport={true} />
            <DisbursementProjectionEvolution
              data={disbursementsProjectionsEvolution}
              typeHeader={false}
              forReport={true}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

type ImgsToSubmitProps = {
  currentProject: any;
  componentBalances: ComponentBalances[] | null;
  disbursedChartObject: any;
  financialInformation: any;
};

const ImagesToSubmit = ({
  currentProject,
  componentBalances,
  disbursedChartObject,
  financialInformation,
}: ImgsToSubmitProps) => {
  return (
    <>
      <div style={{ top: '20px', right: 100, position: 'absolute', backgroundColor: '#ffffff', zIndex: -9999 }}>
        {componentBalances ? (
          <ExecutionByComponent data={componentBalances} idForReport={COMPONENT_BALANCES_REPORT_IMAGE} />
        ) : null}
      </div>
      <div
        style={{
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: '#ffffff',
          zIndex: -9998,
        }}
      ></div>
    </>
  );
};
export default ReportProjectConfig;
