import React, { useState } from 'react';
import styles from './ReviewWithProjects.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { replaceMomentFormat } from '../../../services/util';
import { isMobile } from '../../../services/booleanUtil';
import { setListReviewSelectedProject } from '../../../redux/actions/reviewProjectsSelectedRows';
import { setShowSelectProjects } from '../../../redux/actions/reviewShowSelectProjects';
import ProjectsMobile from './ProjectsMobile';
import AgendaListButton from '../AgendaListButton/AgendaListButton';
import Agenda from '../Agenda/Agenda';
import Projects from './Projects';
import CreateAgenda from '../../Util/Modals/CreateAgenda/CreateAgenda';
import SaveButton from '../../Util/Buttons/SaveButton/SaveButton';
import FilterMyPortfolio from './FilterMyPortfolio';
import ReviewStatus from './ReviewStatus';
import { modifyReview } from '../../../api/reviewsApi';
import { setCurrentReview } from '../../../redux/actions/currentReview';
import { SeeAccordances } from '../ReviewsActionLinks/SeeAccordances';
import ReportEditListButtons from './ReportEditListButtons';
import { resetReviewSelectedFilter } from '../../../redux/actions/reviewProjectSelectedFilters';
import { resetTextSearch } from '../../../redux/actions/textSearch';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import EditReviewModal from '../ReviewsDate/EditReviewModal/EditReviewModal';
import MemoryHelpButtons from './MemoryHelpButtons/MemoryHelpButtons';

type Props = {
  editable?: boolean;
  countryName: any;
  localRouter: any;
  visibleComponent?: any
};

const desktopNotAgenda = isAgenda => {
  return !isMobile() && !isAgenda;
};

const hasAgenda = agendaExist => {
  return agendaExist;
};

const ReviewWithProjects = ({ editable, countryName, localRouter, visibleComponent }: Props) => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MMM/YYYY';
  const dispatch = useDispatch();
  const selectAgendaByDefault = useSelector<any, any>(state => state.agendaIsSelected);
  const [isAgenda, setIsAgenda] = useState<boolean>(selectAgendaByDefault);
  const [filterByMyPortfolio, setFilterByMyPortfolio] = useState<boolean>(false);
  const currentReview = useSelector<any, any>(state => state.currentReview);
  const agendaExist = currentReview.projects.find(project => project.projectRevisionStartDateTime !== null)
    ? true
    : false;
  const user = useSelector<any, any>(state => state.user);
  const [edit, setEdit] = useState<boolean>(false);

  const handleEditList = () => {
    dispatch(resetReviewSelectedFilter());
    dispatch(resetTextSearch());
    dispatch(setListReviewSelectedProject(currentReview.projects.map(p => p.id)));
    dispatch(setShowSelectProjects(true));
  };

  const updateReview = async () => {
    const review = { ...currentReview, published: true };
    await modifyReview(review);
    dispatch(setCurrentReview(review));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.titles}>
            <h1 className={styles.title}>{currentReview.title}</h1>
            <div className={styles.datesContainer}>
              {replaceMomentFormat(moment(currentReview.startDate).format(dateFormat))}
              <span> {` ${t('to_the')} `}</span>
              {replaceMomentFormat(moment(currentReview.endDate).format(dateFormat))}
              <span className={styles.upercase}>{` | ${countryName}`}</span>
              {currentReview.status === ReviewsStatusEnum.Attempt ? (
                <>
                  <span className={styles.attemptabel}>{`- ${t('attempt')} - `}</span>
                  <span className={styles.edit} onClick={() => setEdit(true)}>{`${t('edit')} `}</span>
                </>
              ) : null}
              <div className={styles.agendaContainer}>
                {hasAgenda(agendaExist) ? (
                  <AgendaListButton isAgenda={isAgenda} swapView={() => setIsAgenda(!isAgenda)} />
                ) : !agendaExist && editable ? (
                  <CreateAgenda revisionTitle={currentReview.title} />
                ) : null}
                {/* <div>
              
                  <button className='btn btn-primary-text'> Ver Acuerdos </button>
                </div> */}
              </div>

            </div>
          </div>



          <div className={styles.buttonsContainer}>
            <ReportEditListButtons
              isAgenda={isAgenda}
              editable={editable}
              handleOnclck={handleEditList}
              revision={currentReview}
            />
            <div className={styles.memoryHelpButton}>
              <MemoryHelpButtons localRouter={localRouter} visibleComponent={visibleComponent} />
            </div>
          </div>

        </div>
        {isAgenda && <ReviewStatus status={currentReview.status} />}
        <div className={styles.publishFilterContainer}>
          {!isMobile() && editable && isAgenda && currentReview.status === 0 && !currentReview.published && (
            <SaveButton
              handleClick={updateReview}
              customText={t('publish_attempt_agenda')}
              customStyle={{ marginRight: 35 }}
            />
          )}
          {!isMobile() && user.displayMyPortfolio && isAgenda && (
            <FilterMyPortfolio
              myPortfolio={filterByMyPortfolio}
              handleClick={() => setFilterByMyPortfolio(!filterByMyPortfolio)}
            />
          )}
          {desktopNotAgenda(isAgenda) && <SeeAccordances />}
        </div>







        {isAgenda ? (
          <Agenda
            editable={editable}
            filterByMyPortfolio={filterByMyPortfolio}
            closeAgenda={() => setIsAgenda(false)}
          />
        ) : isMobile() ? (
          <ProjectsMobile displayMyPortfolio={user.displayMyPortfolio} review={currentReview} />
        ) : (
          <Projects review={currentReview} displayMyPortfolio={user.displayMyPortfolio} />
        )}
      </div>
      <EditReviewModal review={currentReview} isOpen={edit} closeModal={() => setEdit(false)} />
    </>
  );
};

export default ReviewWithProjects;
