import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import './Table.scss';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import useTable from '../../../../hooks/useTable';
import { PMR_ALERT_PROBLEM_LAST_3_YEARS } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { PmrPrintValue } from '../../../Util/PmrPrintValue/PmrPrintValue';
import { EarlyWarningsTrafficLight, TrafficLightEnum } from '../../EarlyWarningsTrafficLight/EarlyWarningsTrafficLight';
import { PmrClassification } from '../../../../models/PmrClassification';
import { getUniqueCodeProjectsList } from '../../EarlyWarningsFilters/Util';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px'
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = ["id", "last_project", "last_operation", "name", "Pmr 2021", "Pmr 2022", "Pmr 2023", "early_warnings"];

const selectedKeys = ["id", "code", "loanNumber", "title", "field1", "field3", "field5", "field7"];

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const handleFilterDiff = (filterColor: FilterColorSelected, pmrClassification: string) => {
  if (filterColor === FilterColorSelected.NONE_SELECTED) {
    return true;
  }
  if (filterColor === FilterColorSelected.GREEN) {
    return parseInt(pmrClassification) === PmrClassification.Green;
  } else if (filterColor === FilterColorSelected.YELLOW) {
    return parseInt(pmrClassification) === PmrClassification.Yellow;
  } else if (filterColor === FilterColorSelected.RED) {
    return parseInt(pmrClassification) === PmrClassification.Red;
  }
  return false;
};

const getProjectsQuantityByEarlyWarningType = projects => {
  const uniqueProjects = getUniqueCodeProjectsList(projects);
  let green = 0;
  let yellow = 0;
  let red = 0;

  for (const p of uniqueProjects) {
    if (parseInt(p.field7) === PmrClassification.Green) {
      green++;
    } else if (parseInt(p.field7) === PmrClassification.Yellow) {
      yellow++;
    } else if (parseInt(p.field7) === PmrClassification.Red) {
      red++;
    }
  }
  return [green, yellow, red];
};

const {
  container,
  tableHeaderExpired,
  headerTitleClickableCentered,
  tableRow,
  headerTitle,
  rowValue,
  rowList,
  rowValueName,
  fw,
  lastColumn,
  headerTitleClickable,
  rowValueSpan,
  pmrColumnAdjustSign,
  pmrColumnAdjustValue,
  greenPmr,
  yellowPmr,
  redPmr,
  notAvailablePmr,
  borderTable,
} = styles;

type Props = {
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading?: boolean;
};
export const Table = ({ projectsQuantities, earlyWarningsTab, loading }: Props) => {
  // manage years


  const lastYear = new Date().getFullYear() - 1;
  const oneYearAgo = lastYear - 1;
  const twoYearsAgo = lastYear - 2;
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterdProjects, setFilteredProjects] = useState<any>(projects);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: filterdProjects,
    defaultSortCriteria: 'field7',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    const filterNewProjects = [...projects];
    projectsQuantities(getProjectsQuantityByEarlyWarningType(projects));
    setFilteredProjects(filterNewProjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setFilteredProjects(projects.filter(p => handleFilterDiff(earlyWarningsTab.indexOf(true), p.field7)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(PMR_ALERT_PROBLEM_LAST_3_YEARS);
    storage &&
      sessionStorage.setItem(
        PMR_ALERT_PROBLEM_LAST_3_YEARS,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  const handleEarlyWarningColor = (pmrClassification: string) => {
    const pmrClassificationNumber = parseInt(pmrClassification);

    if (pmrClassificationNumber === PmrClassification.Green) {
      return TrafficLightEnum.GREEN;
    } else if (pmrClassificationNumber === PmrClassification.Yellow) {
      return TrafficLightEnum.YELLOW;
    } else if (pmrClassificationNumber === PmrClassification.Red) {
      return TrafficLightEnum.RED;
    }
    return null;
  };


  const normalizeEarlyWarnings = (projects: any[]) => {
    return projects.map(project => {
      let warningLevel;

      switch (project.early_warnings) {

        case 0:
          warningLevel = 'SATISFACTORY';
          break;
        case 1:
          warningLevel = 'ALERT';
          break;
        case 2:
          warningLevel = 'PROBLEM';
          break;
        default:
          warningLevel = 'UNKNOWN';
      }

      return {
        ...project,
        early_warnings: warningLevel
      };
    });
  };


  const data = normalizeEarlyWarnings(useDataNormalize(headers, selectedKeys, projects));



  return loading ? (
    <div className="pmr-alert-problem-last-three-years__loading_container">
      <ProjectsListSkeleton />
    </div>
  ) : filterdProjects.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
      <div className={borderTable}>
        <div className={tableHeaderExpired}>
          <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
            {t('project')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('operation')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('title', CriteriaType.string)}>
            {t('name')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('field1', CriteriaType.number)}>
            {`${t('pmr_initials')} ${twoYearsAgo}`}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('field3', CriteriaType.number)}>
            {`${t('pmr_initials')} ${oneYearAgo}`}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('field5', CriteriaType.number)}>
            {`${t('pmr_initials')} ${lastYear}`}
          </h4>

          <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field7', CriteriaType.number)}>
            {t('early_warnings')}
          </h4>


        </div>

        <ul className={rowList}>
          {currentList &&
            currentList.map(project => {
              const {
                loanNumber,
                code,
                frontendId,
                title,
                field1: pmrValueTwoYearsAgo,
                field2: pmrClassificationTwoYearsAgo,
                field3: pmrValueOneYearAgo,
                field4: pmrClassificationOneYearAgo,
                field5: pmrValueLastYear,
                field6: pmrClassificationLastYear,
                field7: earlyWarningColor,
              } = project;

              return (
                <li className={tableRow} key={`${loanNumber}${code}${frontendId}`}>
                  <BlackTooltip arrow title={t('go_to_project_info')}>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>{code}</p>
                  </BlackTooltip>
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip arrow title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100}>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>
                  <p className={rowValue}>
                    <PmrClassificationColumn
                      pmrValue={pmrValueTwoYearsAgo}
                      pmrClassification={pmrClassificationTwoYearsAgo}
                    />
                  </p>
                  <p className={rowValue}>
                    <PmrClassificationColumn
                      pmrValue={pmrValueOneYearAgo}
                      pmrClassification={pmrClassificationOneYearAgo}
                    />
                  </p>
                  <p className={rowValue}>
                    <PmrClassificationColumn pmrValue={pmrValueLastYear} pmrClassification={pmrClassificationLastYear} />
                  </p>
                  <span className={rowValueSpan}>
                    <EarlyWarningsTrafficLight earlyWarningColor={handleEarlyWarningColor(earlyWarningColor)} />
                  </span>


                </li>
              );
            })}
        </ul>
      </div>

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={filterdProjects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
      <ProjectCounter
        arrayToCount={filterdProjects}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.PROJECTS_EXTENDED_NOT_FULLY_DISBURSED}
      />
    </div>
  ) : null;
};

const PmrClassificationColumn = props => {
  const { pmrValue, pmrClassification } = props;
  const classificationColorCode = [greenPmr, yellowPmr, redPmr, notAvailablePmr];
  return (
    <>
      <span className={pmrColumnAdjustSign}>
        <span className={classificationColorCode[parseInt(pmrClassification)]} />
      </span>
      <span className={pmrColumnAdjustValue}>
        <PmrPrintValue pmrClassi={parseInt(pmrClassification)} pmrValue={parseFloat(pmrValue)} />
      </span>
    </>
  );
};
