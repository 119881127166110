import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Region from '../../../models/Region';
import { multiSelectStyle, DropdownIndicator } from '../FilterComponent.Styles';
import { CalledFrom } from '../../../models/CalledFromEnum';
import useFilters from '../../../hooks/useFilters';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { AppMenuTabs } from '../../MenuTabs/userTabsInterface';
import { TagFilterContext } from '../../../context/TagFilterContext';

const selectedTabEqualZero = selectedTab => {
  return selectedTab.tabId === 0;
};

const handleSelectedRegionsOptions = (selectedRegions, allOptions) => {
  return selectedRegions.length ? allOptions.filter(item => selectedRegions.includes(item.id)) : allOptions;
};

const getRegionOptions = (allOptions: any[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  const portfolioSelectedTab = menuTabs.portfolioSelectedTab;
  const projectSelectedTab = menuTabs.projectSelectedTab;
  const earlyWarningsSelectedTab = menuTabs.earlyWarningsSelectedTab;

  if (calledFrom === CalledFrom.PORTFOLIO) {
    if (selectedTabEqualZero(portfolioSelectedTab)) {
      return allOptions;
    } else {
      const selectedRegions = portfolioSelectedTab.regions;
      return handleSelectedRegionsOptions(selectedRegions, allOptions);
    }
  } else if (calledFrom === CalledFrom.PROJECT) {
    if (selectedTabEqualZero(projectSelectedTab)) {
      return allOptions;
    } else {
      const selectedRegions = projectSelectedTab.regions;
      return handleSelectedRegionsOptions(selectedRegions, allOptions);
    }
  } else if (calledFrom === CalledFrom.EARLY_WARNINGS) {
    if (selectedTabEqualZero(earlyWarningsSelectedTab)) {
      return allOptions;
    } else {
      const selectedRegions = earlyWarningsSelectedTab.regions;
      return handleSelectedRegionsOptions(selectedRegions, allOptions);
    }
  } else {
    return allOptions;
  }
};
interface IProps {
  regions: Region[];
  filterCountriesOnSelect: any;
  calledFrom: CalledFrom;
  setOptionSelected?: Function;
  isEdit?: boolean;
}

type Props = IProps;

const getSelectOptions = (regions: any[], menuTabs: AppMenuTabs, calledFrom: CalledFrom) => {
  return regions && getRegionOptions(regions, menuTabs, calledFrom);
};

const getTabSelected = (calledFrom: CalledFrom, menuTabs: AppMenuTabs) => {
  return calledFrom === CalledFrom.CUSTOM_TABS_PORTFOLIO ? menuTabs.portfolioSelectedTab : menuTabs.projectSelectedTab;
};

const getRegionValue = (regionFilterValue: any, regionsFromRedux: any[]) => {
  return regionFilterValue && regionsFromRedux.filter(r => regionFilterValue.includes(r.id));
};

const RegionFilter = (props: Props) => {
  const { filterCountriesOnSelect, regions, calledFrom, isEdit, setOptionSelected } = props;
  const {
    filter,
    filterIndicators,
    filterAnalytics,
    dispatch,
    setFilter,
    setFilterIndicators,
    setFilterAnalytics,
    filterEarlyWarnings,
    setFilterEarlyWarnings,
  } = useFilters();

  const { t } = useTranslation();

  const regionsFromRedux = useSelector<any, any>(state => state.regions);
  const menuTabs = useSelector<any, any>(state => state.menuTabs);
  const regionOptions = getSelectOptions(regions, menuTabs, calledFrom);

  const handleChange = value => {
    if (value === null) {
      value = [];
    }

    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        dispatch(
          setFilterIndicators({
            ...filterIndicators,
            common: {
              ...filterIndicators.common,
              regionId: value,
            },
          }),
        );
        trackActionClicked(TrackedActions.regionFilterChangedFromPortfolio);
        break;
      case CalledFrom.PROJECT:
        dispatch(
          setFilter({
            ...filter,
            common: {
              ...filter.common,
              regionId: value,
              itSearch: true,
            },
          }),
        );
        trackActionClicked(TrackedActions.regionFilterChangedFromProject);
        break;
      case CalledFrom.ANALYTICS:
        dispatch(
          setFilterAnalytics({
            ...filterAnalytics,
            regionId: value,
          }),
        );
        break;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        setOptionSelected && setOptionSelected(value);
        break;
      case CalledFrom.EARLY_WARNINGS:
        dispatch(
          setFilterEarlyWarnings({
            ...filterEarlyWarnings,
            common: {
              ...filterEarlyWarnings.common,
              regionId: value,
            },
          }),
        );
        trackActionClicked(TrackedActions.regionFilterChangedFromEarlyWarnings);
        break;
      default:
        break;
    }

    filterCountriesOnSelect(value);
  };

  const getCustomTabFilterValue = () => {
    if (isEdit) {
      const tabSelected = getTabSelected(calledFrom, menuTabs);
      const regionFilterValue = tabSelected.regions;
      const regionValue = getRegionValue(regionFilterValue, regionsFromRedux);
      return regionValue.map(r => ({ id: r.id, value: r.name, label: r.name }));
    }
    return null;
  };

  const getDefaultValue = () => {
    switch (calledFrom) {
      case CalledFrom.PORTFOLIO:
        return filterIndicators.common.regionId;
      case CalledFrom.PROJECT:
        return filter.common.regionId;
      case CalledFrom.ANALYTICS:
        return filterAnalytics.regionId;
      case CalledFrom.CUSTOM_TABS_PORTFOLIO:
      case CalledFrom.CUSTOM_TABS_PROJECT:
        return getCustomTabFilterValue();
      case CalledFrom.EARLY_WARNINGS:
        return filterEarlyWarnings.common.regionId;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedOption = getDefaultValue();
      selectedOption && setOptionSelected && setOptionSelected(selectedOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterCountriesOnSelect(getDefaultValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions]);

  /* tag logic */
  const { setTagOptions } = useContext(TagFilterContext);
  const getValuesSelected = getDefaultValue();
  useEffect(() => {
    setTagOptions("region", getValuesSelected)
  }, [getValuesSelected, setTagOptions])


  return (
    <Select
      id="regionsSelect"
      defaultValue={getDefaultValue()}
      isMulti
      components={{ DropdownIndicator }}
      name="regions"
      options={regionOptions}
      className="basic-multi-select"
      classNamePrefix="All"
      placeholder={t('all')}
      onChange={value => handleChange(value)}
      styles={multiSelectStyle}
    />
  );
};

export default RegionFilter;
