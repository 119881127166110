import React from 'react';
import { useSelector } from 'react-redux';
import Project from '../../../../../models/Project';
import { SummaryIndicatorTable } from '../../SummaryIndicatorTable/SummaryIndicatorTable';
import styles from './TableData.module.scss';
import { tableInfo } from './Util';
import { getBaseInformationList, getProjectInformationList } from '../../../../../api/projectApi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


type Props = {
  tableHeaderLabelToChange?: string;
  newTableHeaderLabel?: string;
};

export const TableData = ({ tableHeaderLabelToChange = undefined, newTableHeaderLabel = undefined }: Props) => {
  const { t } = useTranslation();
  const tableInfoUpdated =
    tableHeaderLabelToChange && newTableHeaderLabel
      ? alterTableHeader(tableInfo, tableHeaderLabelToChange, newTableHeaderLabel)
      : tableInfo;
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const projectList = indicatorProjects.map((val) => { return val.id });
  const [list, setList] = React.useState<any>([]);


  // useEffect(() => {
  //   if (indicatorProjects.length !== 0) {
  //     getSupervisionIndicatorData(projectList);
  //   }
  // }, [indicatorProjects]);
  useEffect(() => {

    getSupervisionIndicatorData(projectList);
  }
    ,);

  const getSupervisionIndicatorData = async (data) => {
    const projectBaseInformationList = await getBaseInformationList(data);
    // const projectInformationList = await getProjectInformationList(data);


    if (projectBaseInformationList !== undefined && projectBaseInformationList !== null && projectBaseInformationList.length > 0) {
      // setList(projectInformationList);

      indicatorProjects.forEach(val => {
        var pj = projectBaseInformationList.find(p => p.id === val.id);
        if (pj !== undefined && pj.baseInformation) {
          val['approvalDate'] = new Date(pj.baseInformation.approvalDate);
          val['currentDisbursementExpirationDate'] = pj.financialInformation.lastDisbursementDate === null ? pj.baseInformation.lastExtensionApprobedDate : new Date(pj.baseInformation.lastExtensionApprobedDate);
          val['overallStage'] = t(`${pj.overallStage}`)
          val['instrument'] = pj.instrument;
        }
      });
      setList(indicatorProjects);
    }
  }


  const { portfolioProjectsDetailContentContainer, wrapper, adjustHeight } = styles;
  return (
    <div className={portfolioProjectsDetailContentContainer}>
      <div className={list.length === 0 ? wrapper : adjustHeight}>
        <SummaryIndicatorTable projects={list} tableInfo={tableInfoUpdated} />
      </div>
    </div>
  );
};

const alterTableHeader = (tableInfo, tableHeaderLabelToChange, newTableHeaderLabel) => {
  return tableInfo.map(ti => {
    if (ti.tableHeader === tableHeaderLabelToChange) {
      return { ...ti, tableHeader: newTableHeaderLabel };
    }

    return ti;
  });
};


