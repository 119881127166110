import React, { useEffect, useState } from 'react';
import './PortfolioSimpleIndicatorTable.scss';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import { StyledTableRow } from './PortfolioSimpleIndicatorTable.Styles';
import { Table, TableBody, TableContainer, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { formatedValueProperty } from '../../../services/numberUtil';
import {
  translateMultipleText,
  tooltipTitle,
  isPercentageFieldType,
  renderDateOrSlash,
} from '../../../services/stringUtil';
import { isTinyTable } from '../../../services/booleanUtil';
import { sortTablesByType } from '../../../services/sortsUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { DynamicTrend } from '../DynamicTrend/DynamicTrend';
import { ExcecutorLink } from '../ExcecutorLink/ExcecutorLink';
import { PmrClassificationComponent } from '../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../Util/PmrPrintValue/PmrPrintValue';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';
import PaginationTable from '../../PortfolioDetail/PaginationTable/PaginationTable';
import { useSelector } from 'react-redux';
import useDataNormalize from '../../../hooks/useDataNormalize';
import Project from '../../../models/Project';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { ReactComponent as ArrowUpRight } from '../../../assets/icons/u_arrow-up-right.svg';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../hooks/usePaginationSort';

import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    // fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headersRegionaProjects = ['id', 'last_project', 'last_operation', 'name', 'disbursed_to_date_01'];
const selectedKeyRegionaProjects = ['id', 'code', 'loanNumber', 'title', 'field2'];

const headersEfas = ['id', 'last_project', 'last_operation', 'name', 'efa_audit_company', 'last_date_recepcion'];
const selectedKeyEfas = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2'];

const headersOver180 = ['id', 'last_project', 'last_operation', 'name', 'number_of_days_months_without_paying_disbursements', 'filter_last_disbursement_date', 'FINANCIAL_PLAN_OF_THE_LAST_ADVANCE', 'amount_pending_surrender'];

const selectedKeysOver180 = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2', 'field4', 'field3'];

const headersIncludingRegionals = ['id', 'last_project', 'last_operation', 'name', 'original_disbursements_annual_projection', 'current_projection', 'disbursed', 'pending'];
const headersProjectsMICI = ['id', 'last_project', 'last_operation', 'name', ' '];

const selectedKeysProjectsMICI = ['id', 'code', 'loanNumber', 'title', 'field1'];

const selectedKeysIncludingRegionals = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2', 'field3', 'field4'];

const headersProjectsPending = ['id', 'last_project', 'last_operation', 'name', 'number_of_days_elapsed_from_the_effective_date'];

const selectedKeysProjectsPending = ['id', 'code', 'loanNumber', 'title', 'field1'];

const headerSafeguard = ['id', 'last_project', 'last_operation', 'name', 'executor', 'division', 'risk', 'performance_dynamics',];

const selectedKeysSafeguard = ['id', 'code', 'loanNumber', 'title', 'field1', 'field3', 'field4', 'field5'];

const headersAccordingSocialCategory = ['last_project', 'last_operation', 'name', 'category', 'risk', 'performance'];

const selectedKeysAccordingSocialCategory = ['code', 'loanNumber', 'title', 'field1', 'field2', 'field3'];
const headersSupervisionPlanBudgetStatus = ['id', 'Project', 'Operation', 'Name', 'Supervision Plan', 'Spent', 'Committed', 'Balance'];
const selectedKeysSupervisionPlanBudgetStatus = ['id', 'code', 'loanNumber', 'title', 'field5', 'field1', 'field2', 'field4'];

interface ReceivedProps {
  projects: any[];
  tableInfo: any;
  indicatorKey?: any;
  footerColumnsInformation?: any;
  label?: any;
  havePmr?: boolean;
  loading?: boolean;
}

type Props = ReceivedProps;
export const PortfolioSimpleIndicatorTable = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projects, tableInfo, indicatorKey, footerColumnsInformation, label, havePmr, loading } = props;
  const [sortAsc, setSortAsc] = React.useState(true);
  const [tinyTable] = React.useState(isTinyTable(tableInfo.length) ? true : false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { handlePaginationChange, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const getHeaders = indicator => {
    switch (indicator) {
      case 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS':
        return headersRegionaProjects;
      case 'DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS':
        return headersIncludingRegionals;
      case 'PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY':
        return headersProjectsPending;
      case 'PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES':
        return headersProjectsMICI;
      case 'DISTRIBUTION_ACCORDING_TO_LATEST_EFAS':
        return headersEfas;
      case 'PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS':
        return headersOver180;
      case 'ESG_SAFEGUARD_PERFORMANCE_RATING':
        return headerSafeguard;
      case 'ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY':
        return headersAccordingSocialCategory;
      case 'SUPERVISION_PLAN_BUDGET_STATUS':
        return headersSupervisionPlanBudgetStatus;
      default:
        return headersIncludingRegionals;
    }
  };

  const getSelectedKeys = indicator => {
    switch (indicator) {
      case 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS':
        return selectedKeyRegionaProjects;
      case 'DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS':
        return selectedKeysIncludingRegionals;
      case 'PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY':
        return selectedKeysProjectsPending;
      case 'DISTRIBUTION_ACCORDING_TO_LATEST_EFAS':
        return selectedKeyEfas;
      case 'PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES':
        return selectedKeysProjectsMICI;
      case 'ESG_SAFEGUARD_PERFORMANCE_RATING':
        return selectedKeysSafeguard;
      case 'PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS':
        return selectedKeysOver180;
      case 'ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY':
        return selectedKeysAccordingSocialCategory;
      case 'SUPERVISION_PLAN_BUDGET_STATUS':
        return selectedKeysSupervisionPlanBudgetStatus;
      default:
        return selectedKeysIncludingRegionals;
    }
  };

  const selectedKeys = getSelectedKeys(props.indicatorKey);
  const headers = getHeaders(props.indicatorKey);
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  let data = useDataNormalize(headers, selectedKeys, indicatorProjects);

  const [isLoading, setisLoading] = React.useState(true);

  useEffect(() => {
    data = [];
  }, []);

  useEffect(() => {
    setisLoading(true);
    if (data.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  const handleClick = projectdIdSelected => {
    if (indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING || !projectdIdSelected) {
      return;
    }
    history.push(`/projectdetail?id=${projectdIdSelected}`);
  };

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(currentList, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const handleUrlClick = (e, url) => {
    window.open(url, '_blank');
    e.stopPropagation();
  };

  return (
    <div
      className={`portfolio-simple-indicator-table-container ${tinyTable ? 'tiny-table' : 'large-table'}`}
      style={
        indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
          ? { maxWidth: 1300 }
          : indicatorKey === IndicatorKeyEnum.PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS
            ? { maxWidth: '100%' }
            : {}
      }
    >
      <div className="">
        <TableContainer>
          {isLoading ? (
            <ProjectsListSkeleton />
          ) : (
            <>
              <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />

              <div className="borderTable">
                <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
                  <StyledTableHeader className="tableHeader">
                    <StyledTableRowHeader>
                      <StyledTableCellHeader className="code" align="left" onClick={() => sortTable('code', 'string')}>
                        <div className="text-head">
                          <div>{codeCustomText(t, indicatorKey)}</div>
                          <div>
                            <IconSort className="sortIcon" />
                          </div>
                        </div>
                      </StyledTableCellHeader>

                      <StyledTableCellHeader
                        className="loan"
                        align="left"
                        onClick={() => sortTable('loanNumber', 'string')}
                      >
                        <div className="text-head">
                          <div>{t('operation')}</div>
                          <div>
                            <IconSort className="sortIcon" />
                          </div>
                        </div>
                      </StyledTableCellHeader>

                      <StyledTableCellHeader
                        className="project-name"
                        align="left"
                        onClick={() => sortTable('title', 'string')}
                      >
                        <div className="text-head">
                          <div>{nameCustomText(t, indicatorKey)}</div>
                          <div>
                            <IconSort className="sortIcon" />
                          </div>
                        </div>
                      </StyledTableCellHeader>

                      {indicatorKey === IndicatorKeyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES && (
                        <StyledTableCellHeader
                          className="project-name"
                          align="left"
                          onClick={() => sortTable('title', 'string')}
                        >
                          <div className="text-head">
                            <div>{t('open_MICI_site')}</div>
                            <div>
                              <IconSort className="sortIcon" />
                            </div>
                          </div>
                        </StyledTableCellHeader>
                      )}

                      {indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS && (
                        <StyledTableCellHeader
                          className="supervision-plan"
                          align="center"
                          onClick={() => sortTable('field5', '')}
                        >
                          <div className="text-head">
                            <div>{translateMultipleText('supervision_plan', t)}</div>
                            <div>
                              <IconSort className="sortIcon" />
                            </div>
                          </div>
                        </StyledTableCellHeader>
                      )}
                      {tableInfo.map(th => (
                        <StyledTableCellHeader
                          key={th.tableHeader}
                          align="center"
                          onClick={() => sortTable(th.rootProperty, th.fieldType)}
                          style={headerCustomStyle(th)}
                        >
                          <div className="text-head">
                            <div style={{ minWidth: '110px' }}>{translateMultipleText(th.tableHeader, t)}</div>
                            <div>
                              <IconSort className="sortIcon" />
                            </div>
                          </div>
                        </StyledTableCellHeader>
                      ))}

                      {/* <StyledTableCellHeader align="right" /> */}
                    </StyledTableRowHeader>
                  </StyledTableHeader>
                  <TableBody>
                    {currentList.map((project, index) => (
                      <StyledTableRow
                        key={project.frontendId}
                        // style={customRowStyle(index, project.id)}
                        onClick={() => handleClick(project.id)}
                      >
                        <StyledTableCell
                          style={customRowStyleByIndex(index)}
                          className={`${project.id ? 'link-first' : ''}`}
                        >
                          {project.id ? (
                            <BlackTooltip title={t('go_to_project_info')} arrow>
                              <Link className="link" to={`/projectdetail?id=${project.id}`}>
                                {project.code}
                              </Link>
                            </BlackTooltip>
                          ) : (
                            <p>{project.code}</p>
                          )}
                        </StyledTableCell>

                        <StyledTableCell style={{ minWidth: '80px' }}>{project.loanNumber}</StyledTableCell>
                        <StyledTableCell>
                          <BlackTooltip
                            title={project.title}
                            placement="bottom"
                            arrow
                            enterDelay={500}
                            leaveDelay={100}
                          >
                            <p className="name-project-nowrap"> {project.title}</p>
                          </BlackTooltip>
                        </StyledTableCell>

                        {indicatorKey === IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS && (
                          <StyledTableCell style={{ textAlign: 'center' }}>
                            {setNumberFormat(project.field5)}
                          </StyledTableCell>
                        )}
                        {tableInfo.map((val, index, arr) => styledRows(val, arr, project, indicatorKey, t))}

                        {havePmr && (
                          <StyledTableCellHeader
                            align="left"
                            onClick={() => sortTable('field5', '')}
                            className="space-table-tiny"
                          >
                            <div className="text-head">
                              {/* <div>{t('pmr_initials')}</div>
                            <div>
                              <IconSort className="sortIcon" />
                            </div> */}
                            </div>
                          </StyledTableCellHeader>
                        )}
                        {/* {indicatorKey === IndicatorKeyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES && (
                        <StyledTableCellHeader align="right" />
                      )} */}

                        {havePmr && (
                          <StyledTableCell style={{ width: '15%' }}>
                            <PmrClassificationComponent classificationColor={parseInt(project.field6)} />
                            <span className="pmr-value">
                              <PmrPrintValue
                                pmrClassi={parseInt(project.field6)}
                                pmrValue={parseFloat(project.field5)}
                              />
                            </span>
                          </StyledTableCell>
                        )}
                        {indicatorKey === IndicatorKeyEnum.PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES && (
                          <StyledTableCell
                            style={{ width: '5%', borderRadius: '6px' }}
                            onClick={e => handleUrlClick(e, project.field1)}
                          >
                            <BlackTooltip
                              title={`${tooltipTitle(t, 'detail')} ${tooltipTitle(t, 'mici')}`}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={200}
                              arrow
                            >
                              <ArrowUpRight className="link" />
                            </BlackTooltip>
                          </StyledTableCell>
                        )}
                        {/* <LinkToProjectDetalilTableCell projectId={project.id} /> */}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </TableContainer>
        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        </div>
      </div>
      {counterProjectFooter(indicatorKey, footerColumnsInformation)}
    </div>
  );
};

const counterProjectFooter = (
  indicatorKey = undefined,
  footerColumnsInformation = {
    approved: 0,
    disbursed: 0,
    disbursedPercentage: 0,
    undisbursedAmount: 0,
    arrayToCount: [],
    countProjects: true,
    projectsAmount: 0,
  },
) => {
  if (indicatorKey === IndicatorKeyEnum.PROJECTS_SUMMARY_PORTFOLIO) {
    return (
      <ProjectCounter
        arrayToCount={footerColumnsInformation.arrayToCount}
        countProjects={footerColumnsInformation.countProjects}
        indicatorKey={indicatorKey}
        footerColumnsInformation={footerColumnsInformation}
      />
    );
  } else {
    return null;
  }
};

const dataByFieldType = (val, project) => {
  if (val.fieldType === 'date') {
    return isDate(project[val.rootProperty]);
    // return renderDateOrSlash(project[val.rootProperty], 'DD/MM/YY');
  } else {
    return (
      <NumberFormat
        value={formatedValueProperty(val.fieldType, project[val.rootProperty])}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        suffix={isPercentageFieldType(val.fieldType)}
      />
    );
  }
};

const setNumberFormat = value => (
  <NumberFormat value={value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} />
);

const widthByLength = arr => (arr.length === 1 ? '28%' : '14%');

const isDate = data => {
  if (typeof data === 'string') {
    return renderDateOrSlash(data);
  }
};

const styledRows = (val, arr, project, indicatorKey = '', t) => {
  const additionalStyle: {} = getAdditionalStyle(indicatorKey, project);
  if (
    (indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ||
      indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING) &&
    val.rootProperty === 'field5'
  ) {
    return (
      <StyledTableCell key={val.tableHeader} style={{ width: widthByLength(arr), ...additionalStyle }} align="center">
        <DynamicTrend
          trend={project.field5}
          prevTrend={
            indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ? project.field7 : project.field6
          }
        />
      </StyledTableCell>
    );
  } else if (indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING && val.rootProperty === 'field1') {
    return (
      <StyledTableCell key={val.tableHeader} style={{ width: widthByLength(arr), ...additionalStyle }} align="center">
        <ExcecutorLink text={project.field1} excecutorId={project.field2} />
      </StyledTableCell>
    );
  } else if (
    indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL &&
    val.rootProperty === 'field5'
  ) {
    return (
      <StyledTableCell key={val.tableHeader} style={{ width: '16%', padding: 10, ...additionalStyle }} align="center">
        <PmrClassificationComponent classificationColor={parseInt(project.field5)} />
        <span className="pmr-value" style={{ marginLeft: 6 }}>
          {t(classificationList[parseInt(project[val.rootProperty])])}
        </span>
      </StyledTableCell>
    );
  } else if (
    indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL &&
    val.rootProperty === 'field3'
  ) {
    return (
      <StyledTableCell
        key={val.tableHeader}
        style={{ width: '16%', ...additionalStyle, textTransform: 'capitalize' }}
        align="center"
      >
        {t(project[val.rootProperty])}
      </StyledTableCell>
    );
  } else {
    return (
      <StyledTableCell key={val.tableHeader} style={{ width: widthByLength(arr), ...additionalStyle }} align="center">
        {val.fieldType ? dataByFieldType(val, project) : stringValueAccordingIndicator(indicatorKey, project, val, t)}
      </StyledTableCell>
    );
  }
};

const stringValueAccordingIndicator = (indicatorKey, project, val, t) => {
  if (
    indicatorKey === IndicatorKeyEnum.ESG_SAFEGUARD_PERFORMANCE_RATING ||
    indicatorKey === IndicatorKeyEnum.ESG_ENVIRONMENTAL_AND_SOCIAL_RISK_RATING
  ) {
    return (
      <span>
        {project[val.rootProperty] === null
          ? t('not_assigned_initials')
          : t(
            val.rootProperty === 'field1' || val.rootProperty === 'field3'
              ? project[val.rootProperty]
              : project[val.rootProperty].toLowerCase(),
          )}
      </span>
    );
  } else if (indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY) {
    return (
      <span>
        {project[val.rootProperty] === null
          ? t('not_assigned_initials')
          : t(val.rootProperty === 'field1' ? project[val.rootProperty] : project[val.rootProperty].toLowerCase())}
      </span>
    );
  } else {
    return project[val.rootProperty];
  }
};

const getAdditionalStyle = (indicatorKey, project) => {
  if (indicatorKey === IndicatorKeyEnum.ESG_DISTRIBUTION_ACCORDING_TO_ENVIRONMENTAL_AND_SOCIAL_CATEGORY) {
    return { textTransform: 'capitalize' };
  } else {
    return {};
  }
};

export const customRowStyle = (index: number, projectId?: number | null) => {

  if (index % 2) {
    return { cursor: projectId ? 'pointer' : 'default', background: 'white' };
  }
  else {
    return {};
  }


};

const customRowStyleByIndex = index =>
  index % 2
    ? { background: 'white', minWidth: '80px', borderRadius: '6px' }
    : { background: 'white', minWidth: '80px' };

const classificationList = ['satisfactory', 'alert', 'problem', 'not_assigned_initials'];

const headerCustomStyle = th => (th.customWidth ? th.customWidth : {});

const codeCustomText = (t: Function, indicatorKey: IndicatorKeyEnum) =>
  t(indicatorKey === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL ? 'code_word' : 'code');


// * Se cambia la logica por temas de sonar.
const nameCustomText = (t: Function) => t('name');
