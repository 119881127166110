import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewFilteredIndicators } from '../../../../api/indicatorApi';
import { ProjectCountryCount } from '../../../../models/ProjectCountryCount';
import { ProjectExecutionTypes } from '../../../../models/ProjectExecutionTypes';
import { ProjectProductTypes } from '../../../../models/ProjectProductTypes';
import { TaxonomiesTypes } from '../../../../models/TaxonomyOptions';
import { setCurrentIndicator } from '../../../../redux/actions/currentIndicator';
import { getProjectsSimpleTableIndicatorForSummaryIndicator } from '../../../../redux/actions/indicatorProjects';
import {
  addSessionFilter,
  checkIfSessionFilterExist,
  getFilterSessionValue,
  resetTCPModuleFilters,
} from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { parseFilterToApiCall } from '../../../../services/util';
import { processIndicators } from '../../../Portfolio/indicatorHelper';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { HeaderDrops } from './HeaderDrops/HeaderDrops';
import { TableData } from './TableData/TableData';
import { TcpFilters } from './TcpFilters/TcpFilters';


export enum SummaryTcpInternalFilters {
  NATIONAL,
  REGIONALS,
}

type Props = {
  currentIndicator: any;
  headerTitle: any;
};
export const SummaryTcp = ({ currentIndicator, headerTitle }: Props) => {
  const { indicatorProjects, filterIndicators } = useSelector<any, any>(state => state);
  const dispatch = useDispatch();
  const { indicatorKey, id } = currentIndicator;
  const [filterApplied, setFilterApplied] = useState(
    checkIfSessionFilterExist('beneficiaryTypes')
      ? getFilterSessionValue('beneficiaryTypes')
      : SummaryTcpInternalFilters.NATIONAL,
  );
  const [resetFilters, setResetFilters] = useState(false);

  useEffect(() => {
    headerTitle(true, 'technical_cooperation');
    const haveFilterObject = sessionStorage.getItem('filterObject');
    const moduleFilter = haveFilterObject ? JSON.parse(haveFilterObject) : {};
    getFilteredSimpleIndicators(dispatch, id, filterApplied, moduleFilter, filterIndicators);
    addSessionFilter({ beneficiaryTypes: filterApplied });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApplied]);

  const handleFilterApplied = value => {
    setFilterApplied(value);
    resetTCPModuleFilters();
    setResetFilters(true);
  };

  const handleModuleFilters = moduleFilter => {
    getFilteredSimpleIndicators(dispatch, id, filterApplied, moduleFilter, filterIndicators);
    setResetFilters(false);
  };
  return (
    <div>
      <TcpFilters filterBy={filterApplied} handleFilterApplied={handleFilterApplied} />
      <HeaderDrops
        filterBy={filterApplied}
        ctMetrics={currentIndicator.data.ct}
        handleModuleFilters={handleModuleFilters}
        resetFilters={resetFilters}
      />
      <TableData filterBy={filterApplied} />
      <ProjectCounter
        arrayToCount={indicatorProjects}
        countProjects={true}
        indicatorKey={indicatorKey}
        showOnlyProjects={true}
        widthStyle={'1420px'}
      />
    </div>
  );
};

const getFilteredSimpleIndicators = (
  d,
  currentIndicatorId,
  filterApplied,
  moduleFilterState: any = {},
  filterIndicators,
) => {
  const {
    executedByBankSelected,
    executionByAgencyExecutor,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    taxonomyResearch,
    udrSelected,
    upTo8Selected,
    moreThan8Selected,
  } = moduleFilterState;

  const tcpTaxonomyId = taxonomyCustomerSupport
    ? TaxonomiesTypes.CUSTOMER_SUPPORT
    : taxonomyOperationalSupport
      ? TaxonomiesTypes.OPERATIONAL_SUPPORT
      : taxonomyResearch
        ? TaxonomiesTypes.RESEARCH
        : null;

  const projectExecutorEntity = executedByBankSelected
    ? ProjectExecutionTypes.BID
    : executionByAgencyExecutor
      ? ProjectExecutionTypes.EXECUTING_AGENCY
      : null;

  const projectCountriesCount = upTo8Selected
    ? ProjectCountryCount.UP_TO_8
    : moreThan8Selected
      ? ProjectCountryCount.MORE_THAN_8
      : null;

  const parameter = udrSelected ? 'udr' : null;

  getIndicatorsData(
    currentIndicatorId,
    {
      projectExecutorEntity,
      tcpTaxonomyId,
      projectCountriesCount,
      parameter,
    },
    d,
    filterIndicators,
  );

  return d(
    getProjectsSimpleTableIndicatorForSummaryIndicator(currentIndicatorId, {
      projectProductTypes: [ProjectProductTypes.CT],
      beneficiaryTypes: [filterApplied],
      igLowerGreater: [],
      moduleFilterState,
    }),
  );
};

const getIndicatorsData = async (indicatorId, moduleFilterOptions, d, filterIndicators) => {
  const { projectExecutorEntity, tcpTaxonomyId, projectCountriesCount, parameter } = moduleFilterOptions;

  const options = {
    indicatorId,
    projectExecutorEntity,
    tcpTaxonomyId,
    projectCountriesCount,
    parameter,
  };

  const currentIndicatorOnDetail = await getNewFilteredIndicators(
    options,
    parseFilterToApiCall(filterIndicators.common.projectTypeFilters),
  );
  const justCurrentIndicatorOnDetail = {
    ...currentIndicatorOnDetail,
    indicatorsData: currentIndicatorOnDetail.indicatorsData.filter(i => i.id === indicatorId),
  };
  if (justCurrentIndicatorOnDetail.indicatorsData[0].metrics.length) {
    const indicatorsProcessed = processIndicators(justCurrentIndicatorOnDetail);

    d(setCurrentIndicator(indicatorsProcessed[0]));
  }
};
