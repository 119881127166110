import React, { useState, useContext } from 'react';
import './PortfolioAccordanceDetailContent.scss';
import PortfolioAccordanceTable from '../PortfolioAccordanceTable/PortfolioAccordanceTable';
import { ProjectCounter } from '../ProjectCounter/ProjectCounter';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingApprovalAccordances } from '../../../redux/actions/indicatorAccordances';
import Accordance from '../../../models/Accordance';
import Indicator from '../../../models/Indicator';
import { IndicatorKeyEnum as key } from '../../../models/IndicatorKeyEnum';
import { isMobile } from '../../../services/booleanUtil';
import { capitalizeFirstLetter } from '../../../services/stringUtil';
import { MobileAccordanceTable } from '../MobileAccordanceTable/MobileAccordanceTable';
import { addSortValues } from '../PendingTasksDetail/PendingTasksDetail';

//Added
import AccordanceDetail from '../../Util/Modals/AccordanceDetail/AccordanceDetail';
import useAccordanceDetailModalsState from '../../../hooks/useAccordanceDetailModalsState';
import AccordanceFulfilled from '../../Util/Modals/AccordanceFulfilled/AccordanceFulfilled';
import AccordanceReject from '../../Util/Modals/AccordanceReject/AccordanceReject';
import { NewAccordanceContext } from '../../Util/Modals/NewAccordance/context/NewAccordanceContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { store } from '../../../redux/store';
import { handleLoading } from '../../utils';
import { ReactComponent as IconFilter } from '../../../assets/icons/u_filters-v.svg';
export const getAccordancesFromApi = (d, indicatorKey, setLoading) => {
  return handleLoading(
    () => d(getPendingApprovalAccordances(indicatorKey === key.ACCORDANCES_WAITING_APPROVAL ? true : false)),
    setLoading,
  );
};

interface ReceivedProps {
  currentIndicator: Indicator;
  waitingApproval?: boolean;
}

type Props = ReceivedProps;
export const PortfolioAccordanceDetailContent = (props: Props) => {
  const { t } = useTranslation();
  const { currentIndicator, waitingApproval } = props;
  const dispatch = useDispatch();
  const indicatorAccordances = useSelector<any, Accordance[]>(state => state.indicatorAccordances);
  const [nextToExpired, setNextToExpired] = useState(false);
  const [allIndicators, setAllIndicators] = useState(true);
  const [labelStyledNexToExpire, setLabelSelectedNexToExpire] = useState(false);
  const [labelStyleExpire, setLabelStyleExpire] = useState(false);
  const [buttonStyledNexToExpire, setButtonStyledNexToExpire] = useState(false);
  const [buttonStyleExpire, setButtontStyleExpire] = useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [refresAcordacnceInfo, setRefresAcordacnceInfo] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const projectTypeFilterOnDetail = useSelector<any, any>(
    state => state.indicatorDetailFilter.projectTypeFilterOnDetail,
  );

  const [accordance, setAccordance] = React.useState<Accordance>();

  //added
  const [accordanceSelected, setAccordanceSelected] = React.useState<Accordance>();
  const currentDate = new Date();
  const stateProps = useAccordanceDetailModalsState();
  const { state, setState } = useContext(NewAccordanceContext);
  const { changeStatus, showFulfilledModal, setShowFulfilledModal, showReject, setShowReject, showJustificationModal } = stateProps;

  const [mobileSelectedTab, setMobileSelectedTab] = React.useState(0);

  React.useEffect(() => {
    getAccordancesFromApi(dispatch, currentIndicator.indicatorKey, setLoading);
  }, [currentIndicator, dispatch, projectTypeFilterOnDetail]);

  React.useEffect(() => {
    if (refresAcordacnceInfo) {
      getAccordancesFromApi(dispatch, currentIndicator.indicatorKey, setLoading);
      setRefresAcordacnceInfo(false);
    }
  }, [refresAcordacnceInfo, dispatch, currentIndicator]);

  const showModal = () => {
    setShowDetail(true);
  };

  const hideModal = (data?: any) => {
    setShowDetail(false);
  };

  const handleProject = React.useCallback(AccordanceObject => {
    setMobileSelectedTab(1);
    setAccordance(AccordanceObject);
  }, []);

  const handleAccordance = (acc: Accordance) => {
    setAccordanceSelected(acc);
    showModal();
  };


  const changeAccordanceStatus = (state: number) => {
    changeStatus(state);
    hideModal();
  };

  const handleChangeState = (state: boolean) => {
    var previusState = store.getState().indicatorAccordances;
    getAccordancesFromApi(dispatch, currentIndicator.indicatorKey, setLoading);

    store.subscribe(() => {
      const currentState = store.getState().indicatorAccordances;

      if (previusState !== currentState) {
        if (previusState.length > 0) {
          if (!allIndicators) {
            handleAllIndicators();
          } else {
            handleNextToExpire();
            handleAllIndicators();
          }
        }
      }
      previusState = [];
    });
  };

  const handleChangeAccordanceInfo = (state: boolean) => {
    setRefresAcordacnceInfo(true);
  }

  const handleNextToExpire = () => {
    if (nextToExpired && !allIndicators) {
      setAllIndicators(true);
    } else {
      setNextToExpired(true);
      setAllIndicators(false);
    }

    setLabelSelectedNexToExpire(true);
    setLabelStyleExpire(false);

    setButtonStyledNexToExpire(true);
    setButtontStyleExpire(false);
  };

  const handleExpired = () => {
    if (!nextToExpired && !allIndicators) {
      setAllIndicators(true);
    } else {
      setNextToExpired(false);
      setAllIndicators(false);
    }

    setLabelStyleExpire(true);
    setLabelSelectedNexToExpire(false);

    setButtontStyleExpire(true);
    setButtonStyledNexToExpire(false);
  };

  const handleAllIndicators = () => {
    setAllIndicators(true);
    setLabelSelectedNexToExpire(false);
    setLabelStyleExpire(false);

    setButtonStyledNexToExpire(false);
    setButtontStyleExpire(false);
  };

  const handleSelectLabelNexToExpire = () => {
    return labelStyledNexToExpire ? 'labelSelected' : 'labelUnSelected';
  };
  const handleSelectLabelExpire = () => {
    return labelStyleExpire ? 'labelSelected' : 'labelUnSelected';
  };
  const handleButtonlNexToExpire = () => {
    return buttonStyledNexToExpire ? 'buttonContainerSelected' : 'buttonContainerUnSelected';
  };
  const handleButtonlExpire = () => {
    return buttonStyleExpire ? 'buttonContainerSelected' : 'buttonContainerUnSelected';
  };

  const rejectedHandler = () => {
    handleChangeState(true);
  };

  const quantityExpired = Expire(indicatorAccordances, currentDate);
  const quantityNeXToExpired = NextToExpire(indicatorAccordances, currentDate);
  const textquantityExpired = textQuantity(quantityExpired, t);
  const textquantityNeXToExpired = textQuantity(quantityNeXToExpired, t);

  const filterData = () => {
    let data = allIndicators
      ? indicatorAccordances
      : nextToExpired
        ? indicatorAccordances.filter(
          x => new Date(x.newExpirationDate !== null ? x.newExpirationDate : x.expirationDate) >= currentDate,
        )
        : indicatorAccordances.filter(
          x => new Date(x.newExpirationDate !== null ? x.newExpirationDate : x.expirationDate) < currentDate,
        );

    return addSortValues(data);
  };


  return !isMobile() ? (
    <div className="portfolio-accordance-detail-content-container">
      <div className="content-container">
        <div className="buttonsContainer">
          <div className={handleButtonlNexToExpire()} onClick={() => handleNextToExpire()}>
            <div className='filter-cards'>
              <div className='title-head'>
                <label className={`${handleSelectLabelNexToExpire()} ci-type-heading-4`} >
                  {' '}
                  {quantityNeXToExpired}{' '}
                </label>
                <label className={`${handleSelectLabelNexToExpire()} ci-type-text-3`}>
                  {' '}
                  {textquantityNeXToExpired}{' '}
                </label>
              </div>
              <label className={`${handleSelectLabelNexToExpire()} ci-type-text-9`}>{`${capitalizeFirstLetter(
                t('acc_next_expire'),
              )}`}</label>
            </div>
            <div className='icon-filter'>
              <IconFilter />
            </div>

          </div>
          <div className={handleButtonlExpire()} onClick={() => handleExpired()}>
            <div className='filter-cards'>
              <div className='title-head'>
                <label className={`${handleSelectLabelExpire()} ci-type-heading-4`}>
                  {' '}
                  {quantityExpired}{' '}
                </label>
                <label className={`${handleSelectLabelExpire()} ci-type-text-3`}>
                  {' '}
                  {textquantityExpired}{' '}
                </label>

              </div>
              <label className={`${handleSelectLabelExpire()} ci-type-text-9`}>{`${capitalizeFirstLetter(
                t('expired'),
              )}`}</label>
            </div>
            <div className='icon-filter'>
              <IconFilter />
            </div>
          </div>
        </div>
      </div>

      <PortfolioAccordanceTable
        accordances={filterData()}
        handleProject={handleProject}
        waitingApproval={waitingApproval}
        indicatorKey={key.ACCORDANCES_EXPIRING}
        handleAccordance={event => handleAccordance(event)}
        loading={loading}
      />
      <ProjectCounter arrayToCount={filterData()} countProjects={false} indicatorKey={currentIndicator.indicatorKey} />

      <>
        {showDetail && <AccordanceDetail
          isOpen={showDetail}
          closeModal={hideModal}
          accordance={accordanceSelected}
          showJustificationModal={showJustificationModal}
          onApproveHandler={value => { handleChangeState(value); }}
          changeAccordanceStatus={value => {
            changeAccordanceStatus(value);
          }}
          daysToExpire={daysToExpire(accordanceSelected)}
          hideEdit={true}
          changeState={value => { handleChangeState(value); }}
          updateAccordanceInfo={value => { handleChangeAccordanceInfo(value); }}
        />
        }
        <AccordanceReject
          isOpen={showReject}
          closeModal={() => {
            setShowReject(false);
            setState({ ...state, relatedAccordance: null });
          }}
          accordanceId={accordanceSelected?.id !== undefined ? accordanceSelected.id : 0}
          onRejectHandler={rejectedHandler}
        />
        <AccordanceFulfilled
          isOpen={showFulfilledModal}
          closeModal={() => {
            setShowFulfilledModal(false);
            setState({ ...state, relatedAccordance: null });
          }}
          accordanceId={accordanceSelected?.id !== undefined ? accordanceSelected.id : 0}
          changeState={value => {
            handleChangeState(value);
          }}
        />
      </>
    </div>
  ) : (
    <MobileAccordanceTable
      indicatorAccordances={indicatorAccordances}
      handleProject={handleProject}
      rowSelected={accordance}
      selectedTab={mobileSelectedTab}
    />
  );
};

export const NextToExpire = (accordances, currentDate) => {
  return accordances.filter(
    x => new Date(x.newExpirationDate !== null ? x.newExpirationDate : x.expirationDate) >= currentDate,
  ).length;
};
export const Expire = (accordances, currentDate) => {
  return accordances.filter(
    x => new Date(x.newExpirationDate !== null ? x.newExpirationDate : x.expirationDate) < currentDate,
  ).length;
};
export const daysToExpire = accordance => {
  let currentDate = new Date();
  let dateToExpire = accordance.newExpirationDate !== null ? accordance.newExpirationDate : accordance.expirationDate;
  return moment(new Date(dateToExpire)).diff(currentDate, 'days') + 1;
};
export const textQuantity = (days: number, t) => {
  return days === 1 ? `${capitalizeFirstLetter(t('accordance'))}` : `${capitalizeFirstLetter(t('accordances'))}`;
};
