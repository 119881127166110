import { axiosDefaultConfig } from './axiosConfig';
import * as config from './config.json';
import Project from '../../src/models/Project';
import { FilterTypesEnum } from '../../src/models/FitlerTypesEnum';
import { store } from '../../src/redux/store';
import { SET_FILTER, SET_ALL_PROJECTS } from '../redux/actions/types';
import { setHaveFiltersInputsAndSelects } from './filterSharedUtil';
import {
  setFilterParams,
  setItsSearchCall,
  getProductTypesFilterAndIgConfigFromStore,
  createNewUniqueValueArray,
  setValueFilter,
} from './filterPortfolioAndProjectUtil';
import { buildGet, buildPut } from './genericMethods';
import { MOCK_PROJECT_CONSULTANCIES_DATA } from '../mockData/mockProjectConsultanciesData';
import { duplicateToUniqueKey, getMergedFilterArray } from '../services/util';
import { AppMenuTabs, Filter } from '../components/MenuTabs/userTabsInterface';

/**
 * Return the params needed to get from Backend a list of Followed Projects or All Projects
 * @param {boolean} selectState - If True = FOLLOWED If False = ALL
 * @returns {}
 */
function createFollowedParams(selectState: boolean) {
  let returnedParams;
  const filter = store.getState().filter;

  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', false);
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', false);
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', false);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', false);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects('disbursementProjection', false);
  const havePmrClassification = filter.common.pmrId.filter(pmr => pmr.selected === true);
  const haveExcludeRegionales = filter.common.excludeRegionals;
  const haveTaxonomy = filter.common.taxonomyFilter && filter.common.taxonomyFilter.id;
  const haveExecution = filter.common.executionFilter && filter.common.executionFilter.id !== undefined;

  const stateFilterValues = {
    haveDivisionFilter,
    haveCountriesFilter,
    haveLastDisbursement,
    haveAgeOfProject,
    havePendingDisbursement,
    havePmrClassification,
    haveExcludeRegionales,
    haveTaxonomy,
    haveExecution,
  };

  returnedParams = createCommonParams(selectState);

  returnedParams = setFilterParams(returnedParams, stateFilterValues, false);

  return returnedParams;
}

function createCommonParams(selectState) {
  let returnedParams = {};
  const filter = store.getState().filter;
  const regionsFilter = setValueFilter(false, 'regionId');
  const itsSearchCall = setItsSearchCall(selectState);
  const { projectProductAmounts, projectProductTypes } = getProductTypesFilterAndIgConfigFromStore(true);

  returnedParams = {
    pageNumber: selectState
      ? filter.followedProjectsPagination?.currentPage
      : filter.allProjectsPagination?.currentPage,
    itemsPerPage: selectState ? filter.followedProjectsPagination?.pageSize : filter.allProjectsPagination?.pageSize,
    sortParameter: selectState
      ? filter.followedProjectsFilter?.sortParamenter
      : filter.allProjectsFilter?.sortParamenter,
    orderDesc: selectState ? filter.followedProjectsFilter?.orderDesc : filter.allProjectsFilter?.orderDesc,
    inFollow: selectState ? filter.followedProjectsFilter?.inFollow : filter.allProjectsFilter?.inFollow,
    isAnnual: filter.common.isAnnual === 'annual' ? true : false, //selectState ? filter.followedProjectsFilter?.inFollow : filter.allProjectsFilter?.inFollow,
    regions: regionsFilter,
    Filters: [],
    projectProductTypes,
    projectProductAmounts,
    projectSelectedTab: [],
    coReponsibleDivisions: [] as any,
    participantDivisions: [] as any,
    interestIssues: [] as any,
    teamLeaders: [] as any,
  };

  if (itsSearchCall > 2) {
    const searchQuery = {
      filterType: FilterTypesEnum.Search,
      field: 'Name,Code',
      Value: selectState ? filter.followedProjectsFilter.searchValue : filter.allProjectsFilter.searchValue,
    };
    const returnedFilters = { Filters: searchQuery };
    returnedParams = { ...returnedParams, Filters: [returnedFilters.Filters] };
  }

  return returnedParams;
}

/**
 * Set Pagination response
 *  @param {boolean} selectState - If True = FOLLOWED If False = ALL
 * @returns {}
 */
function setPaginationResponseFollowedProjects(selectState: boolean, responseData: any) {
  const paginationProperties = {
    currentPage: responseData.currentPage,
    pageSize: responseData.pageSize,
    pageCount: responseData.pageCount,
    rowCount: responseData.rowCount,
    firstRowOnPage: responseData.firstRowOnPage,
    lastRowOnPage: responseData.lastRowOnPage,
  };

  if (selectState) {
    store.dispatch({
      type: SET_FILTER,
      payload: {
        ...store.getState().filter,
        followedProjectsPagination: paginationProperties,
      },
    });
  } else {
    store.dispatch({
      type: SET_FILTER,
      payload: {
        ...store.getState().filter,
        allProjectsPagination: paginationProperties,
      },
    });
  }
}

/**
 * API CALLS
 * @summary Functions to call the API to get and set projects.
 */
export const getFollowedProjects = () => {
  return new Promise<Project[]>(async (resolve, reject) => {
    const url = config.PROJECTS;
    try {
      const filterParams = createFollowedParams(true);
      //get every project product type
      filterParams.projectProductTypes = [0, 2, 3];
      filterParams.projectProductAmounts = [
        {
          amount: 3000000,
          filterType: 1,
          projectProductType: 3,
        },
        {
          amount: 3000000,
          filterType: 0,
          projectProductType: 3,
        },
      ];
      const response = await axiosDefaultConfig.post(url, filterParams);
      setPaginationResponseFollowedProjects(true, response.data);
      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};

export const getGeneralInformation = list => {

  return new Promise<any[]>(async (resolve, reject) => {
    const url = config.PROJECT_GENERAL_INFORMATION;
    try {
      const payLoad = [...list];
      const response = await axiosDefaultConfig.post(url, payLoad);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProjectInformationList = list => {

  return new Promise<any[]>(async (resolve, reject) => {
    const url = config.GET_PROJECT_INFORMATION_LIST;
    try {
      const payLoad = [...list];
      const response = await axiosDefaultConfig.post(url, payLoad);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/*--- memory help ---*/
export const getMemoryHelpAgreements = (agreementId: number) => {

  return new Promise<any[]>(async (resolve, reject) => {
    let url = config.GET_MEMORY_HELP_AGREEMENTS;
    url = url.replace('{id}', agreementId.toString());
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMemoryHelpFileRevision = (agreementId: number) => {

  return new Promise<any[]>(async (resolve, reject) => {
    let url = config.GET_MEMORY_HELP_FILE_REVISION;
    url = url.replace('{id}', agreementId.toString());
    try {
      const response = await axiosDefaultConfig.get(url);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setMemoryHelpSaveRevision = (agreementId: number, infoFile: any) => {

  return new Promise<any[]>(async (resolve, reject) => {
    let url = config.SET_MEMORY_HELP_SAVE_REVISION;
    url = url.replace('{id}', agreementId.toString());
    const payLoad = [...infoFile];
    try {
      const response = await axiosDefaultConfig.post(url, payLoad);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setMemoryHelpFileAttachment = (agreementId: number, file: any) => {

  return new Promise<any[]>(async (resolve, reject) => {
    let url = config.SET_MEMORY_HELP_FILE_ATTACHMENT;
    url = url.replace('{id}', agreementId.toString());
    const payLoad = file;
    try {
      const response = await axiosDefaultConfig.post(url, payLoad);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

/*-----*/

export const getBaseInformationList = list => {
  return new Promise<any[]>(async (resolve, reject) => {
    const url = config.PROJECT_BASE_INFORMATION_LIST;

    try {
      const payLoad = [...list];
      const response = await axiosDefaultConfig.post(url, payLoad);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const setFollowUnfollowProject = (projectId: number) => {
  return new Promise<any>(async (resolve, reject) => {
    let url = config.SET_FOLLOW;
    url = url.replace('{id}', projectId.toString());
    try {
      const response = await axiosDefaultConfig.post(url, {});
      resolve(response.status);
    } catch (error: any) {
      reject(error.response);
    }
  });
};

export const getNewFilteredProjects = () => {
  const { projectSelectedTab } = store.getState().menuTabs as AppMenuTabs;
  const filter = store.getState().filter.common;
  let returnedParams = createFollowedParams(false);

  const regionsFromFilter = filter.regionId.map(item => item.id);
  const regionsFromTab = createNewUniqueValueArray(returnedParams, projectSelectedTab, 'regions');
  const regions = regionsFromFilter.length ? regionsFromFilter : regionsFromTab;

  const coReponsibleDivisionsFromFilter = filter.coReponsibleDivisions.map(item => item.id);
  const coReponsibleDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    projectSelectedTab,
    'coReponsibleDivisions',
  );
  const coReponsibleDivisions = coReponsibleDivisionsFromFilter.length
    ? coReponsibleDivisionsFromFilter
    : coReponsibleDivisionsFromTab;

  const participantDivisionsFromFilter = filter.participantDivisions.map(item => item.id);
  const participantDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    projectSelectedTab,
    'participantDivisions',
  );
  const participantDivisions = participantDivisionsFromFilter.length
    ? participantDivisionsFromFilter
    : participantDivisionsFromTab;

  const interestIssuesFromFilter = filter.interestIssues.map(item => item.id);
  const interestIssuesFromTab = createNewUniqueValueArray(returnedParams, projectSelectedTab, 'interestIssues');
  const interestIssues = interestIssuesFromFilter.length ? interestIssuesFromFilter : interestIssuesFromTab;
  const teamLeadersFromFilter = filter.teamLeaders.map(item => item.id);
  const teamLeadersFromTab = projectSelectedTab.teamLeaders.map(tl => tl.id);
  const teamLeaders = teamLeadersFromFilter.length ? teamLeadersFromFilter : teamLeadersFromTab;

  returnedParams = {
    ...returnedParams,
    inFollow: projectSelectedTab.inFollow,
    assignedToMe: projectSelectedTab.assignedToMe,
    regions,
    coReponsibleDivisions,
    participantDivisions,
    interestIssues,
    teamLeaders,
  };

  if (projectSelectedTab.filters) {
    const mergeFilterArray = getMergedFilterArray(returnedParams.Filters, projectSelectedTab.filters);
    const returnedParamsFilterArray: Filter[] = duplicateToUniqueKey(mergeFilterArray);
    returnedParams = {
      ...returnedParams,
      Filters: returnedParamsFilterArray,
    };
  }

  return new Promise<Project[]>(async (resolve, reject) => {
    const url = config.PROJECTS;
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);
      store.dispatch({ type: SET_ALL_PROJECTS, payload: response.data.collection });
      setPaginationResponseFollowedProjects(false, response.data);
      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProjectById = (id: number) => {
  const url = `${config.PROJECT}/${id}`;
  return buildGet(url);
};

export const getProjectLinks = (id: number) => {
  const url = `${config.PROJECT}/${id}/links`;
  return buildGet(url);
};

export const getRisks = (id: number) => {
  const url = `${config.PROJECT}/${id}/riskPrograms`;
  return buildGet(url);
};

export const getProjectBaseInformation = (id: number) => {
  const url = `${config.PROJECT}/${id}/getBaseInformation`;
  return buildGet(url);
};

export const getProjectFinancial = (id: number) => {
  const url = `${config.PROJECT}/${id}/financial`;
  return buildGet(url);
};

export const editFinancialBudgetAndPoaAmount = (projectId, data) => {
  const url = config.PROJECT_FINANCIAL_UPDATE.replace('{projectId}', projectId.toString());

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.put(url, data);

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const basicPost = returnedParams => {
  return new Promise<Project[]>(async (resolve, reject) => {
    const url = config.PROJECTS;
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);
      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};

export const addRemoveProjectMyPortfolio = (projectId: number) => {
  return new Promise<any>(async (resolve, reject) => {
    let url = config.PROJECT_MY_PORTFOLIO;
    url = url.replace('{id}', projectId.toString());
    try {
      const response = await axiosDefaultConfig.post(url, {});
      resolve(response.status);
    } catch (error: any) {
      reject(error.response);
    }
  });
};

export const getProjectHistoric = (id: number, pageNumber: number) => {
  return new Promise<any>(async (resolve, reject) => {
    const url = config.PROJECT_CHANGELOG.replace('{projectId}', id.toString());
    try {
      const notificationParam = {
        pageNumber,
        itemsPerPage: 5,
      };
      const response = await axiosDefaultConfig.post(url, notificationParam);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProjectConsultancies = () => {
  //TODO CT-ETAPA-2 Falta integrar
  return Promise.resolve(MOCK_PROJECT_CONSULTANCIES_DATA);
};

export const getProjectPmrvalidationcheck = (id: number) => {
  const url = `${config.PROJECT}/${id}/pmrvalidationcheck`;
  return buildGet(url);
};

export const getProjectIntegrationMyPortfolio = () => {
  const url = config.GET_PROJECT_INTEGRATION_MY_PORTFOLIO;
  return buildGet(url);
};

export const confirmProjectIntegrationMyPortfolio = data => {
  return new Promise<Project[]>(async (resolve, reject) => {
    const url = config.CONFIRM_PROJECT_INTEGRATION_MY_PORTFOLIO;
    try {
      const response = await axiosDefaultConfig.post(url, data);
      resolve(response.data.collection);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPreferenceTab = () => {
  const url = config.GET_PREFERENCE_TABS;
  return buildGet(url);
};

export const getRecommendedList = () => {
  const url = config.GET_RECOMMENDED_LIST;;
  return buildGet(url);
};

export const getAdquisitionsData = id => {
  const projectIdVariable = '{id}';
  const url = config.GET_ADQUISITION_DATA.replace(projectIdVariable, id.toString());
  return buildGet(url);
};

export const updatePreferenceTab = tab => {
  const url = `${config.GET_PREFERENCE_TABS}/${tab}?typeId=1`;
  return buildPut(url);
};

