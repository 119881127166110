import React from 'react';
import './NewAccordance.scss';
import { useTranslation } from 'react-i18next';
import ProjectTabs from '../../../ProjectDetail/ProjectTabs/ProjectTabs';
import NewAccordanceForm from '../../../ProjectDetail/ProjectTabs/Accordances/NewAccordanceForm/NewAccordanceForm';
import { AccordanceType } from '../../../../models/AccordanceType';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import Project from '../../../../models/Project';

type Props = {
  close: any;
  accordanceToEdit?: any;
  canCreateInterGroupAccordances?: boolean;
  canCreateMonitoringAccordances?: boolean;
  canCreatePortfolioReviewAccordances?: boolean;
};

const setItemsListIds = (itemsList: any[]): any => {
  itemsList.forEach((item, ind) => {
    item.id = ind;
  });
};

const NewAccordanceWithTabs = (props: Props) => {
  const { close, accordanceToEdit } = props;
  const accordaceData = useSelector<any, Project>(state => state.currentProject);

  const { t } = useTranslation();
  const [errorsMessage, setErrorsMesssge] = React.useState(false);
  const [defaultTab, setDefaultTab] = React.useState(0);

  React.useEffect(() => {
    if (accordanceToEdit) {
      const tab = accordanceToEdit.accordanceType === AccordanceType.Revision ? 0 : 1;
      setDefaultTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemsList: any[] = [
    {
      id: 2,
      label: '',
      panel: (
        <NewAccordanceForm
          close={close}
          accordanceFormType={AccordanceType.Revision}
          displayGenericErrorsMessage={value => setErrorsMesssge(value)}
          editAccordanceData={accordanceToEdit}
        />
      ),
      hidden: true,
    },
  ];

  const portfolioReviewTab = {
    id: 0,
    label: t('portfolio_review'),
    panel: (
      <NewAccordanceForm
        close={close}
        accordanceFormType={AccordanceType.Revision}
        displayGenericErrorsMessage={value => setErrorsMesssge(value)}
        editAccordanceData={accordanceToEdit}
      />
    ),
  };

  const monitoringTab = {
    id: 1,
    label: t('motitoring'),
    panel: (
      <NewAccordanceForm
        close={close}
        accordanceFormType={AccordanceType.Monitoring}
        displayGenericErrorsMessage={value => setErrorsMesssge(value)}
        editAccordanceData={accordanceToEdit}
      />
    ),
  };

  const interGroupTab = {
    id: 3,
    label: t('inter_group'),
    panel: (
      <NewAccordanceForm
        close={close}
        accordanceFormType={AccordanceType.InterGroup}
        displayGenericErrorsMessage={value => setErrorsMesssge(value)}
        editAccordanceData={accordanceToEdit}
      />
    ),
  };

  props.canCreateInterGroupAccordances && itemsList.unshift(interGroupTab);
  props.canCreateMonitoringAccordances && itemsList.unshift(monitoringTab);
  props.canCreatePortfolioReviewAccordances && itemsList.unshift(portfolioReviewTab);

  setItemsListIds(itemsList);

  return (
    <div className="modal-new-accordance-container with-tabs">
      {errorsMessage && (
        <ErrorMessage
          customStyle={{ position: 'absolute', left: accordanceToEdit ? -61 : 1, width: '100%' }}
          alertMessage={accordanceToEdit ? t('could_not_edit_accordance') : t('could_not_create_accordance')}
          descriptionMessage={t('please_check_required_fields')}
        />
      )}
      {errorsMessage && accordanceToEdit && <div style={{ width: '100%', height: 60 }} />}
      {!accordanceToEdit && <h3 style={errorsMessage ? { marginTop: 70 } : {}}>{t('new_create_accordance')}</h3>}
      <p>
        <b>{accordaceData.code}</b> {t('LON_table')}: {accordaceData.loanNumber}
      </p>
      {/* <ProjectTabs itemsList={itemsList} customStyle={customStyle} defaultTab={defaultTab} /> */}
      <NewAccordanceForm
        close={close}
        accordanceFormType={AccordanceType.Revision}
        displayGenericErrorsMessage={value => setErrorsMesssge(value)}
        editAccordanceData={accordanceToEdit}
      />
    </div>
  );
};

export default NewAccordanceWithTabs;
