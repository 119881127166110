import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer, Tooltip } from '@material-ui/core';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import { StyledTableRow } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.Styles';
import { renderDateOrSlash } from '../../../services/stringUtil';
import { sortTablesByType } from '../../../services/sortsUtil';
import { customRowStyle } from '../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import LinkToExternalProjectDetailTableCell from '../../Util/LinkToExternalProjectDetailTableCell/LinkToExternalProjectDetailTableCell';
import * as config from '../../../api/config.json';
import IndicatorDetailFilter from '../../../models/IndicatorDetailFilter';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import DataTable from '../../DetailDataTable/DataTable';
import useDataNormalize from '../../../hooks/useDataNormalize';
import usePaginationSort from '../../../hooks/usePaginationSort';
import PaginationTable from '../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const renderExecutedByField = value => {
  return value === 'AGENCY' ? 'executing_agency' : 'BID';
};

type Props = {
  projects: any[];
  tableInfo: any[];
  loading?: boolean;
};

const headers = ['id', 'last_project', 'name', 'division', 'amount_bid_usd', 'gcm_team_leader', 'expected_approval_date', 'status', 'Pipeline', ' ',];

const selectedKeys = ['id', 'code', 'title', 'field1', 'field2', 'field3', 'field5', 'field5', 'field6', 'code'];
const ProjectsInPreparationTable = ({ projects, tableInfo, loading }: Props) => {
  const [sortAsc, setSortAsc] = useState(true);
  const { t } = useTranslation();
  const [isLoading, setisLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { projectTypeFilterOnDetail } = useSelector<any, IndicatorDetailFilter>(state => state.indicatorDetailFilter);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const sortTable = (keyToSort, fieldType) => {
    sortTablesByType(currentList, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const handleClick = projectCode => {
    const url = `${config.CONVERGENCIA_URL.replace('{project_number}', projectCode.trim())}`;
    window.open(url, '_blank');
  };
  const data = useDataNormalize(headers, selectedKeys, projects);

  const isDate = data => {
    if (typeof data === 'string') {
      return renderDateOrSlash(data);
    }
  };

  useEffect(() => {
    setisLoading(true);
    if (data.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="portfolio-simple-indicator-table-container">
      <div className="">
        <TableContainer>
          {isLoading ? (
            <ProjectsListSkeleton />
          ) : (
            <>
              <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
              {/* <DataTable headers={headers} selectedKeys={selectedKeys} hasFirstColLink data={data} hasRowDetail key={data} /> */}
              <div className=" border-table">
                <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
                  <StyledTableHeader>
                    <StyledTableRowHeader className="tableHeader">
                      {tableInfo.map(th => (
                        <StyledTableCellHeader
                          key={th.tableHeader}
                          align={th.align ? th.align : 'left'}
                          onClick={() =>
                            sortTable(
                              th.tableHeader === 'executed_by_or_expected_approval_date' &&
                                projectTypeFilterOnDetail === ProjectProductTypes.Loan
                                ? 'field7'
                                : th.rootProperty,
                              th.tableHader === 'executed_by_or_expected_approval_date'
                                ? projectTypeFilterOnDetail === ProjectProductTypes.Loan
                                  ? 'date'
                                  : 'string'
                                : th.fieldType,
                            )
                          }
                          style={th.customWidth}
                          className="code"
                        >
                          <div className="text-head">
                            {th.tableHeader === 'executed_by_or_expected_approval_date'
                              ? projectTypeFilterOnDetail === ProjectProductTypes.Loan
                                ? t('expected_approval_date')
                                : t('executed_by')
                              : t(th.tableHeader)}
                            {/* <i className="sort-icon"></i> */}
                            <IconSort className="sortIcon" />
                          </div>
                        </StyledTableCellHeader>
                      ))}
                      {projectTypeFilterOnDetail === ProjectProductTypes.Loan && (
                        <StyledTableCellHeader
                          key="pipeline"
                          align="center"
                          onClick={() => sortTable('field6', 'string')}
                          style={{ width: '5%' }}
                          className="code"
                        >
                          <div className="text-head">
                            {t('pipeline')}
                            <IconSort className="sortIcon" />
                          </div>
                        </StyledTableCellHeader>
                      )}
                      <StyledTableCellHeader style={{ width: '0%' }}></StyledTableCellHeader>
                    </StyledTableRowHeader>
                  </StyledTableHeader>
                  <TableBody>
                    {currentList &&
                      currentList.map((project, index) => (
                        <StyledTableRow
                          key={project.id}
                        // style={customRowStyle(index, project.id)}
                        >
                          <StyledTableCell align="left" className="link-project">
                            <BlackTooltip
                              title={t('go_to_project_info')}
                              onClick={() => handleClick(project.code)}
                              arrow
                            >
                              <p className={` link-project`}>{project.code}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <BlackTooltip
                              className="name-project-nowrap-tiny"
                              title={project.title}
                              placement="bottom"
                              enterDelay={500}
                              leaveDelay={100}
                              arrow
                            >
                              <p>{project.title}</p>
                            </BlackTooltip>
                          </StyledTableCell>
                          <StyledTableCell align="center">{project.field1}</StyledTableCell>
                          <StyledTableCell align="center">
                            <NumberFormat
                              value={parseInt(project.field2)}
                              displayType={'text'}
                              className={'value'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">{project.field3 || '-'}</StyledTableCell>
                          <StyledTableCell align="left">
                            {projectTypeFilterOnDetail === ProjectProductTypes.Loan
                              ? isDate(project.field7)
                              : t(renderExecutedByField(project.field4))}
                            {/* {projectTypeFilterOnDetail === ProjectProductTypes.Loan
                              ? renderDateOrSlash(project.field7, 'DD/MM/YYYY')
                              : t(renderExecutedByField(project.field4))} */}
                          </StyledTableCell>
                          <StyledTableCell align="left">{project.field5}</StyledTableCell>
                          {projectTypeFilterOnDetail === ProjectProductTypes.Loan && (
                            <StyledTableCell align="center">{project.field6}</StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <div className="pagination-style">
                <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
                <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
              </div>
            </>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default ProjectsInPreparationTable;
