import { Accordion, AccordionDetails, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TranslationsIndicatorAccordionSummary } from '../ContentManagerAccordion/ContentManagerAccordion';
import { updateRecommendedIndicators } from '../../../api/contentManager';
import IndicatorsRecommended from './IndicatorsRecommended/IndicatorsRecommended';
import { SearchRender } from '../../Util/CustomInput/SearchRender/SearchRender';
import './Recommended.scss';
import { getRecommendedList } from '../../../api/projectApi';

const indicatorOptions = [
  { id: 1, value: 1, label: 'Indicator 1', sortOrder: 1 },
  { id: 2, value: 2, label: 'Indicator 2', sortOrder: 2 },
  { id: 3, value: 3, label: 'Indicator 3', sortOrder: 3 },
  { id: 4, value: 4, label: 'Indicator 4', sortOrder: 4 },
];
export const Recommended = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [transformedIndicators, setTransformedIndicators] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredIndicators, setFilteredIndicators] = useState<any[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const listRecommended = await getRecommendedList();

    const indicators = listRecommended.map(item => ({
      id: item.mainIndicatorId,
      value: item.mainIndicatorId,
      code: item.code,
      label: item.title,
      listRecommendedIndicators: item.listRecommendedIndicators,
    }))
      .sort((a, b) => a.label.localeCompare(b.label));
      
    setTransformedIndicators(indicators);
    setFilteredIndicators(indicators); // Set filteredIndicators initially
  };

  const handleSelectChange = useCallback((indicatorId: number, selectIndex: number, selectedOption: any) => {
    setTransformedIndicators(prevIndicators => {
      const newIndicators = [...prevIndicators];
      const indicator = newIndicators.find(indicator => indicator.id === indicatorId);

      if (!indicator.listRecommendedIndicators) {
        indicator.listRecommendedIndicators = [];
      }

      if (selectedOption.value === null) {
        indicator.listRecommendedIndicators = indicator.listRecommendedIndicators.filter(
          item => item.sortOrder !== indicatorOptions[selectIndex].sortOrder,
        );
      } else {
        const existingIndicator = indicator.listRecommendedIndicators.find(
          item => item.sortOrder === indicatorOptions[selectIndex].sortOrder,
        );

        if (existingIndicator) {
          existingIndicator.indicatorId = selectedOption.id;
        } else {
          indicator.listRecommendedIndicators.push({
            indicatorId: selectedOption.id,
            sortOrder: indicatorOptions[selectIndex].sortOrder,
          });
        }
      }

      return newIndicators;
    });
  }, []);

  const handleChange = useCallback(
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const submit = async (indicatorId: number) => {
    try {
      const indicator = transformedIndicators.find(indicator => indicator.id === indicatorId);
      const payload = {
        mainIndicatorId: indicator.id,
        listRecommendedIndicators: indicator.listRecommendedIndicators.map(indicator => ({
          IndicatorId: indicator.indicatorId,
          SortOrder: indicator.sortOrder,
        })),
      };
      await updateRecommendedIndicators(payload);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term.length >= 3) {
      const filtered = transformedIndicators.filter(indicator =>
        indicator.label.toLowerCase().includes(term.toLowerCase()),
      );
      setFilteredIndicators(filtered);
    } else {
      setFilteredIndicators(transformedIndicators);
    }
  };

  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: '#00BAC7' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  };

  return (
    <>
      <section className="recommended-admin">
        <div className="search-input">
          <SearchRender
            placeholder="last_search_by_indicator_name"
            valueSearched={searchTerm}
            onChangeHandler={handleSearchChange}
          />
          {/* 
                    <TextField
                        label={t('last_search_by_indicator_name')}
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                    /> */}
        </div>

        <div className="container">
          {filteredIndicators.length > 0 ? (
            <>
              {filteredIndicators.map((indicator, index) => (
                <Accordion
                  expanded={expanded === indicator.code}
                  onChange={handleChange(indicator.code)}
                  key={indicator.id}
                  style={{ boxShadow: 'none' }}
                  className="accordion"
                >
                  <TranslationsIndicatorAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="title-indiicator">
                      {searchTerm.length >= 3 ? highlightText(indicator.label, searchTerm) : indicator.label}
                    </div>
                  </TranslationsIndicatorAccordionSummary>

                  <AccordionDetails className="accordion-detail">
                    <IndicatorsRecommended
                      indicator={indicator}
                      handleSelectChange={(selectIndex, selectedOption) =>
                        handleSelectChange(indicator.id, selectIndex, selectedOption)
                      }
                      onSave={() => submit(indicator.id)}
                      indicatorOptions={indicatorOptions}
                      transformedIndicators={transformedIndicators}
                      index={index}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            <span>{t('last_not_find_search')}</span>
          )}
        </div>
      </section>
    </>
  );
};

export default Recommended;
