import React, { useEffect, useState } from 'react';
import './PortfolioProjectsTable.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import {
  StyledTableHeader,
  StyledTableCellHeader,
  StyledTableCell,
  StyledTableRowHeader,
} from '../PorfolioTableShared.Styles';

import { BidTooltip } from '../../Util/Tooltip/Tooltip';
import { dispatchFilterIndicatorState } from '../../../redux/actions/filterIndicators';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconSort } from '../../../assets/icons/u_sort.svg';

import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import DataTable from '../../DetailDataTable/DataTable';
import useDataNormalize from '../../../hooks/useDataNormalize';
import Project from '../../../models/Project';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { Table, TableBody, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { useHistory } from 'react-router';
import { useCards } from '../../../context/CardContext';
import { sortTablesByType } from '../../../services/sortsUtil';
import { LastColumnHeader } from './LastColumnHeader';
import { conditionalDisplay } from './Util';
import { LastColumnValues } from './LastColumnValues';
import { ProjectProductTypeFilters } from '../../../models/ProjectProductTypeFilters';
import { isMobile } from '../../../services/booleanUtil';
import PaginationTable from '../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../hooks/usePaginationSort';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headersTcPerformance = ['id', 'last_project', 'last_operation', 'name', 'tcdi_initials'];
const selectedKeysTcPerformance = ['id', 'code', 'loanNumber', 'title', 'field1'];

const headersPmr = ['id', 'last_project', 'last_operation', 'name', 'PMR'];
const selectedKeysPmr = ['id', 'code', 'loanNumber', 'title', 'field1'];

const headersDivsion = ['id', 'last_project', 'last_operation', 'name', 'total_approved_amount', 'PMR/Tcdi'];

const selectedKeysDivision = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2'];

type Props = {
  projects: any;
  havePmr?: boolean;
  indicatorKey?: any;
  haveTcdi?: boolean;
  loading?: boolean;
};

export const PortfolioProjectsTable = (props: Props) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = React.useState(true);
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getHeaders = indicator => {
    switch (indicator) {
      case 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION':
        return headersDivsion;
      case 'TCP_PERFORMANCE':
        return headersTcPerformance;
      default:
        return headersPmr;
    }
  };

  const getSelectedKeys = indicator => {
    switch (indicator) {
      case 'PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION':
        return selectedKeysDivision;
      case 'TCP_PERFORMANCE':
        return selectedKeysTcPerformance;
      default:
        return selectedKeysPmr;
    }
  };
  const headers = getHeaders(props.indicatorKey);
  const selectedKeys = getSelectedKeys(props.indicatorKey);

  let data = useDataNormalize(headers, selectedKeys, indicatorProjects);

  useEffect(() => {
    setisLoading(true);
    if (data.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [data]);

  const [sortAsc, setSortAsc] = React.useState(true);
  const history = useHistory();
  const { havePmr, projects, indicatorKey, haveTcdi, loading } = props;
  const filterIndicators = useSelector<any, any>(state => state.indicatorDetailFilter);
  const { currentRowClicked } = useCards();

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });
  const dispatch = useDispatch();
  const handleClick = projectId => {
    dispatchFilterIndicatorState(dispatch, filterIndicators, false);
    history.push(`/projectdetail?id=${projectId}`);
  };

  const sortTable = (data, keyToSort, fieldType) => {
    sortTablesByType(currentList, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  return isLoading ? (
    <div className="portfolio-projects-table__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : (
    <div className="">
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />

      <TableContainer>
        <div className="border-table">
          <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
            <StyledTableHeader>
              <StyledTableRowHeader className="tableHeader">
                <StyledTableCellHeader
                  className="code"
                  align="left"
                  onClick={() => sortTable(projects, 'code', 'string')}
                >
                  <div className="text-head">
                    <div> {t('code')}</div>
                    <div>
                      <IconSort className="sortIcon" />
                    </div>
                  </div>
                </StyledTableCellHeader>
                <StyledTableCellHeader align="left" onClick={() => sortTable(projects, 'loanNumber', 'string')}>
                  <div className="text-head">
                    <div>{t('operation')}</div>
                    <div>
                      <IconSort className="sortIcon" />
                    </div>
                  </div>
                </StyledTableCellHeader>
                <StyledTableCellHeader align="left" onClick={() => sortTable(projects, 'title', 'string')}>
                  <div className="text-head">
                    <div>{t('project_name')}</div>
                    <div>
                      <IconSort className="sortIcon" />
                    </div>
                  </div>
                </StyledTableCellHeader>

                {indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION && (
                  <StyledTableCellHeader align="left" onClick={() => sortTable(projects, 'field1', 'numeric')}>
                    <div className="text-head">
                      <div>{t('total_approved_amount')}</div>
                      <div>
                        <IconSort className="sortIcon" />
                      </div>
                    </div>
                  </StyledTableCellHeader>
                )}

                <LastColumnHeader havePmr={havePmr} haveTcdi={haveTcdi} projects={projects} sortTable={sortTable} />
              </StyledTableRowHeader>
            </StyledTableHeader>
            <TableBody>
              {currentList &&
                currentList.map((project, index) => (
                  <TableRow key={project.frontendId}>
                    <StyledTableCell style={{ width: '10%', minWidth: '80px', borderRadius: '6px', border: 'hidden' }}>
                      <BlackTooltip arrow title={t('go_to_project_info')} onClick={() => handleClick(project.id)}>
                        <p className={` link-project`}> {project.code}</p>
                      </BlackTooltip>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '10%', minWidth: '80px', border: 'hidden' }}>
                      {project.loanNumber}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '40%', border: 'hidden' }}>
                      <BlackTooltip
                        className="name-project-nowrap-tiny"
                        arrow
                        title={conditionalDisplay(
                          indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
                          indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION ||
                          indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE ||
                          indicatorKey ===
                          IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION,
                          project.title,
                          project.name,
                        )}
                        placement="bottom"
                        enterDelay={500}
                        leaveDelay={100}
                      >
                        <p>
                          {' '}
                          {conditionalDisplay(
                            indicatorKey === IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR ||
                            indicatorKey === IndicatorKeyEnum.PMR_STATE_DISTRIBUTION ||
                            indicatorKey === IndicatorKeyEnum.TCP_PERFORMANCE ||
                            indicatorKey ===
                            IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION,
                            project.title,
                            project.name,
                          )}
                        </p>
                      </BlackTooltip>
                    </StyledTableCell>
                    {indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION && (
                      <StyledTableCell style={{ width: '30%', minWidth: '70px', border: 'hidden' }}>
                        {project.field1 ? (
                          <NumberFormat
                            value={parseFloat(project.field1)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                          />
                        ) : (
                          '-'
                        )}
                      </StyledTableCell>
                    )}

                    <LastColumnValues
                      havePmr={handleHavePmr(project, indicatorKey, havePmr)}
                      haveTcdi={handleHaveTcdi(project, indicatorKey, haveTcdi)}
                      project={project}
                      indicatorKey={indicatorKey}
                    />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  );
};

const handleHavePmr = (project, indicatorKey, paramValue) => {
  if (indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) {
    return (
      parseInt(project.field4) === ProjectProductTypeFilters.Loan ||
      parseInt(project.field4) === ProjectProductTypeFilters.IG_greater_3
    );
  }
  return paramValue;
};

const handleHaveTcdi = (project, indicatorKey, paramValue) => {
  if (indicatorKey === IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) {
    return (
      parseInt(project.field4) === ProjectProductTypeFilters.CT ||
      parseInt(project.field4) === ProjectProductTypeFilters.IG_lower_3
    );
  }

  return paramValue;
};
