import { ProjectCountryCount } from './../models/ProjectCountryCount';
import { ProjectExecutionTypes } from './../models/ProjectExecutionTypes';
import { ProjectProductTypes } from './../models/ProjectProductTypes';
import { FilterTypesEnum } from './../models/FitlerTypesEnum';
import * as config from './config.json';
import { axiosDefaultConfig } from './axiosConfig';
import { store } from '../../src/redux/store';
import {
  setFilterParams,
  getReturnedParmasIndicator,
  getProductTypesFilterAndIgConfigFromStore,
  createNewUniqueValueArray,
  setValueFilter,
  addMenuTabFilter,
  handleActualProjectedDifferenceFilters,
} from './filterPortfolioAndProjectUtil';
import { setHaveFiltersInputsAndSelects } from './filterSharedUtil';
import { IncludeRegionalProjectsEnum } from '../models/IncludeRegionalProjectsEnum';
import { buildGet } from './genericMethods';
import { duplicateToUniqueKey, getMergedFilterArray } from '../services/util';
import { SummaryTcpInternalFilters } from '../components/PortfolioDetail/PortfolioSummaryIndicator/SummaryTcp/SummaryTcp';
import { SummaryIGInternalFilters } from '../components/PortfolioDetail/PortfolioSummaryIndicator/SummaryIg/SummaryIg';
import { getFilterSessionValue } from '../services/sessionFiltersIndicatorDetailHelper';
import { AppMenuTabs, Filter, MenuTabSectionEnum } from '../components/MenuTabs/userTabsInterface';
import { TaxonomiesTypes } from '../models/TaxonomyOptions';
import { setPayload } from '../redux/actions/indicatorPayload';

const handleResponse = (resolve, reject, response) => {
  if (response && response.data) {
    resolve(response.data);
  } else {
    reject('error');
  }
};

export const getNewFilteredIndicators = (options: any = {}, customTypeFilters?, customFilterIndicators?) => {
  const { portfolioSelectedTab } = store.getState().menuTabs as AppMenuTabs;
  const filterIndicators = customFilterIndicators || store.getState().filterIndicators.common;
  let returnedParams = createCommonParams(customTypeFilters, filterIndicators);

  const regionsFromFilter = filterIndicators.regionId.map(item => item.id);
  const regionsFromTab = createNewUniqueValueArray(returnedParams, portfolioSelectedTab, 'regions');

  const regions = regionsFromFilter.length ? regionsFromFilter : regionsFromTab;

  const coReponsibleDivisionsFromFilter = filterIndicators.coReponsibleDivisions.map(item => item.id);
  const coReponsibleDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    portfolioSelectedTab,
    'coReponsibleDivisions',
  );
  const coReponsibleDivisions = coReponsibleDivisionsFromFilter.length
    ? coReponsibleDivisionsFromFilter
    : coReponsibleDivisionsFromTab;

  const participantDivisionsFromFilter = filterIndicators.participantDivisions.map(item => item.id);
  const participantDivisionsFromTab = createNewUniqueValueArray(
    returnedParams,
    portfolioSelectedTab,
    'participantDivisions',
  );
  const participantDivisions = participantDivisionsFromFilter.length
    ? participantDivisionsFromFilter
    : participantDivisionsFromTab;

  const interestIssuesFromFilter = filterIndicators.interestIssues.map(item => item.id);
  const interestIssuesFromTab = createNewUniqueValueArray(returnedParams, portfolioSelectedTab, 'interestIssues');
  const interestIssues = interestIssuesFromFilter.length ? interestIssuesFromFilter : interestIssuesFromTab;

  const teamLeadersFromFilter = filterIndicators.teamLeaders ? filterIndicators.teamLeaders.map(item => item.id) : [];
  const teamLeadersFromTab = portfolioSelectedTab.teamLeaders ? portfolioSelectedTab.teamLeaders.map(tl => tl.id) : [];
  const teamLeaders = teamLeadersFromFilter.length ? teamLeadersFromFilter : teamLeadersFromTab;

  returnedParams = {
    ...returnedParams,
    inFollow: portfolioSelectedTab.inFollow,
    assignedToMe: portfolioSelectedTab.assignedToMe,
    includeRegionalProjects: getLocalStorageOptionIncludeRegionalProjects(),
    regions,
    coReponsibleDivisions,
    participantDivisions,
    interestIssues,
    teamLeaders,
  };

  if (portfolioSelectedTab.filters) {
    const mergeFilterArray = getMergedFilterArray(returnedParams.Filters, portfolioSelectedTab.filters);
    const returnedParamsFilterArray: Filter[] = duplicateToUniqueKey(mergeFilterArray);
    returnedParams = {
      ...returnedParams,
      Filters: returnedParamsFilterArray,
    };
  }

  returnedParams = handleActualProjectedDifferenceFilters(returnedParams, options);
  return postIndicators(returnedParams, options);
};

export const getLocalStorageOptionIncludeRegionalProjects = () => {
  const localStorageValue = localStorage.getItem('includeRegionalProjects');
  if (localStorageValue) {
    return parseInt(localStorageValue);
  }
  return IncludeRegionalProjectsEnum.INCLUDE_UDR;
};

export const getLocalStorageOptionIncludeRegionalProjectsFromSummaryIndicator = () => {
  const localStorageValue = localStorage.getItem('summaryIndicatorIncludeRegionalSetup');
  if (localStorageValue) {
    const objectParsed = JSON.parse(localStorageValue);
    return parseInt(objectParsed.optionSelectedApplied);
  }
  return IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS;
};

function createCommonParams(customTypeFilters?, customFilterIndicators?) {
  const filterIndicators = customFilterIndicators || store.getState().filterIndicators.common;
  const { portfolioSelectedTab } = store.getState().menuTabs;
  const regionsFilter = setValueFilter(true, 'regionId');

  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', true, filterIndicators);
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', true, filterIndicators);
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', true, filterIndicators);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', true, filterIndicators);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects('disbursementProjection', true, filterIndicators);
  const havePmrClassification = filterIndicators.pmrId.filter(pmr => pmr.selected === true);
  const haveTaxonomy = filterIndicators.taxonomyFilter && filterIndicators.taxonomyFilter.id;
  const haveExecution = filterIndicators.executionFilter && filterIndicators.executionFilter.id;
  const haveExcludeRegionales = filterIndicators.excludeRegionals || portfolioSelectedTab.excludeRegionals;
  const { projectProductTypes, projectProductAmounts }: any = customTypeFilters
    ? customTypeFilters
    : getProductTypesFilterAndIgConfigFromStore();
  const stateFilterValues = {
    haveDivisionFilter,
    haveCountriesFilter,
    haveLastDisbursement,
    haveAgeOfProject,
    havePendingDisbursement,
    havePmrClassification,
    haveTaxonomy,
    haveExecution,
    haveExcludeRegionales,
  };

  let returnedParams = {
    sortParameter: 0,
    orderDesc: true,
    isAnnual: false,
    regions: regionsFilter,
    assignedToMe: false,
    Filters: [] as any,
    includeAll: false,
    includeRegionalProjects: getLocalStorageOptionIncludeRegionalProjectsFromSummaryIndicator(),
    projectProductTypes,
    projectProductAmounts,
    coReponsibleDivisions: [] as any,
    participantDivisions: [] as any,
    interestIssues: [] as any,
    teamLeaders: [] as any,
    inFollow: false,
  };

  returnedParams = setFilterParams(returnedParams, stateFilterValues, true, { common: filterIndicators });

  return returnedParams;
}

export const getAllIndicators = () => {
  const url = config.INDICATOR;
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      const response = await axiosDefaultConfig.get(url);
      handleResponse(resolve, reject, response);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateIndicators = data => {
  const url = config.INDICATOR;
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.put(url, data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const removeIndicator = id => {
  const url = `${config.INDICATOR}/${id.toString()}`;
  return new Promise<any[]>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.delete(url);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const sortIndicators = (oldIndex, nexIndex) => {
  const url = config.INDICATOR_SORT;
  const data = { oldIndex, nexIndex };

  return new Promise<any[]>(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.post(url, data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

interface ModuleFiltersState {
  executedByBankSelected?: boolean;
  executionByAgencyExecutor?: boolean;
  taxonomyCustomerSupport?: boolean;
  taxonomyOperationalSupport?: boolean;
  taxonomyResearch?: boolean;
  udrSelected?: boolean;
  upTo8Selected?: boolean;
  moreThan8Selected?: boolean;
}

interface IFilterSummaryDetailParams {
  beneficiaryTypes: SummaryTcpInternalFilters[];
  projectProductAmounts: SummaryIGInternalFilters[];
  includeRegionalProjects: IncludeRegionalProjectsEnum;
  moduleFilterState: ModuleFiltersState;
}

export const getSimpleIndicatorProjectsSummaryIndicator = (
  indicatorId,
  {
    beneficiaryTypes = [],
    projectProductAmounts = [],
    includeRegionalProjects = IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS,
    moduleFilterState = {},
  }: IFilterSummaryDetailParams,
) => {
  let returnedParams = indicatorProjectsCreateCommonsParams(indicatorId);
  returnedParams = { ...returnedParams, includeRegionalProjects };

  if (projectProductAmounts.length) {
    returnedParams = {
      ...returnedParams,
      projectProductAmounts,
    };
  }

  if (beneficiaryTypes.length) {
    const beneficiaryFilterExist = returnedParams.Filters.find(f => f.field === 'BeneficiaryType');
    if (beneficiaryFilterExist) {
      returnedParams = {
        ...returnedParams,
        Filters: returnedParams.Filters.map(obj => {
          if (obj.field === 'BeneficiaryType') {
            return { ...obj, value: beneficiaryTypes };
          }
          return obj;
        }),
      };
    } else {
      returnedParams = {
        ...returnedParams,
        Filters: [
          ...returnedParams.Filters,
          { field: 'BeneficiaryType', filterType: FilterTypesEnum.NumberIn, value: beneficiaryTypes },
        ],
      };
    }
  }

  const {
    executedByBankSelected,
    executionByAgencyExecutor,
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    taxonomyResearch,
    udrSelected,
    upTo8Selected,
    moreThan8Selected,
  } = moduleFilterState;
  returnedParams = handleExecutionFilter(executedByBankSelected, executionByAgencyExecutor, returnedParams);
  returnedParams = handleHaveTaxonomy(
    taxonomyCustomerSupport,
    taxonomyOperationalSupport,
    taxonomyResearch,
    returnedParams,
  );
  returnedParams = handleHaveParticipation(udrSelected, upTo8Selected, moreThan8Selected, returnedParams);
  return postIndicatorsProjects({ ...returnedParams });
};

const handleExecutionFilter = (executedByBankSelected, executionByAgencyExecutor, params) => {
  let returnedParams = { ...params };
  if (haveExecution(executedByBankSelected, executionByAgencyExecutor)) {
    const projectExecutorType = executedByBankSelected
      ? [ProjectExecutionTypes.BID]
      : [ProjectExecutionTypes.EXECUTING_AGENCY];
    const projectExecutorEntityFilterExist = returnedParams.Filters.find(f => f.field === 'ProjectExecutorEntity');
    if (projectExecutorEntityFilterExist) {
      returnedParams = {
        ...returnedParams,
        Filters: returnedParams.Filters.map(obj => {
          if (obj.field === 'ProjectExecutorEntity') {
            return { ...obj, value: projectExecutorType };
          }
          return obj;
        }),
      };
    } else {
      returnedParams = {
        ...returnedParams,
        Filters: [
          ...returnedParams.Filters,
          { field: 'ProjectExecutorEntity', filterType: FilterTypesEnum.NumberIn, value: projectExecutorType },
        ],
      };
    }
  }
  return returnedParams;
};

const handleHaveTaxonomy = (taxonomyCustomerSupport, taxonomyOperationalSupport, taxonomyResearch, params) => {
  let returnedParams = { ...params };
  if (haveTaxonomy(taxonomyCustomerSupport, taxonomyOperationalSupport, taxonomyResearch)) {
    const tcpTaxonomyId = taxonomyCustomerSupport
      ? [TaxonomiesTypes.CUSTOMER_SUPPORT]
      : taxonomyOperationalSupport
        ? [TaxonomiesTypes.OPERATIONAL_SUPPORT]
        : [TaxonomiesTypes.RESEARCH];
    const taxonomyFilterExist = returnedParams.Filters.find(f => f.field === 'TCPTaxonomyId');
    if (taxonomyFilterExist) {
      returnedParams = {
        ...returnedParams,
        Filters: returnedParams.Filters.map(obj => {
          if (obj.field === 'TCPTaxonomyId') {
            return { ...obj, value: tcpTaxonomyId };
          }
          return obj;
        }),
      };
    } else {
      returnedParams = {
        ...returnedParams,
        Filters: [
          ...returnedParams.Filters,
          { field: 'TCPTaxonomyId', filterType: FilterTypesEnum.NumberIn, value: tcpTaxonomyId },
        ],
      };
    }
  }
  return returnedParams;
};

const handleHaveParticipation = (udrSelected, upTo8Selected, moreThan8Selected, params) => {
  let returnedParams = { ...params };
  if (udrSelected) {
    returnedParams = {
      ...returnedParams,
      categorization: 'udr',
    };
  }

  if (haveCountriesCount(upTo8Selected, moreThan8Selected)) {
    const projectCountriesCount = upTo8Selected ? ProjectCountryCount.UP_TO_8 : ProjectCountryCount.MORE_THAN_8;
    const projectCountriesFilterExist = returnedParams.Filters.find(f => f.field === 'ProjectCountriesCount');
    if (projectCountriesFilterExist) {
      returnedParams = {
        ...returnedParams,
        Filters: returnedParams.Filters.map(obj => {
          if (obj.field === 'ProjectCountriesCount') {
            return { ...obj, value: 8 };
          }
          return obj;
        }),
      };
    } else {
      returnedParams = {
        ...returnedParams,
        Filters: [
          ...returnedParams.Filters,
          {
            field: 'ProjectCountriesCount',
            filterType: projectCountriesCount,
            value: 8,
          },
        ],
      };
    }
  }
  return returnedParams;
};

const haveExecution = (executedByBankSelected, executionByAgencyExecutor) =>
  executedByBankSelected || executionByAgencyExecutor;

const haveTaxonomy = (taxonomyCustomerSupport, taxonomyOperationalSupport, taxonomyResearch) =>
  taxonomyCustomerSupport || taxonomyOperationalSupport || taxonomyResearch;

const haveCountriesCount = (upTo8Selected, moreThan8Selected) => upTo8Selected || moreThan8Selected;

//TODO CT-ETAPA-2 Mock planes de supervision - Falta integracion BE
const planesDeSupervisionParaTabla = [
  {
    id: 1403,
    code: 'BO-G1004',
    title: 'Program for Potable Water and Irrigation for the ',
    loanNumber: 'GRT/SX-15362-BO',
    field1: 'Jorge SOlo',
    field2: 'Programa algo de Agu',
    field3: '2021-05-12 12:05:00',
    field4: '2021-12-12 12:05:00',
    field5: '50',
    field6: '70',
    field7: 'USD',
    field8: 'EXEC_PH',
    field9: 'VPC', //SOURCE
  },
  {
    id: 1404,
    code: 'BO-G1005',
    title: 'Multipurpose Program for Potable Water and Irrigation for the municipalities of',
    loanNumber: 'GRT/SX-15362-BO',
    field1: 'Mauri',
    field2: 'Programa Otra Cosa de Agu',
    field3: '2021-05-12 12:05:00',
    field4: '2021-12-12 12:05:00',
    field5: '50',
    field6: '20',
    field7: 'USD',
    field8: 'EXEC_PH',
    field9: 'VPS', //SOURCE
  },
];

export interface IFiltersBidConsultanciesDetailParams {
  projectProductTypes: ProjectProductTypes[];
  projectProductAmounts: Object[];
}
export const getSimpleIndicatorProjectsBidConsultancyIndicator = (
  indicatorId,
  options: IFiltersBidConsultanciesDetailParams,
) => {
  const { projectProductTypes, projectProductAmounts } = options;
  let returnedParams = indicatorProjectsCreateCommonsParams(indicatorId);

  returnedParams = {
    ...returnedParams,
    projectProductTypes,
    projectProductAmounts,
  };

  return getFilterSessionValue('consultanciesTabs') === 0
    ? planesDeSupervisionParaTabla
    : postIndicatorsProjects(returnedParams);
};

/*
Used to get projects by followed indicators:
 1  - PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS (Desembolsado vs Pendiente)
 2  - AVERAGE_LAG
 3  - PROJECTS_WITH_TRANSACTIONAL_BUDGET_DEFICIT
 4  - PROJECTS_IN_EXECUTION_WITH_MICI_OPEN_CASES
 5  - PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL
 6  - PROJECTS_PENDING_DISBURSEMENT_ELIGIBILITY
 7  - PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS
 8  - PROJECTS_EXTENDED_NOT_FULLY_DISBURSED
 9  - DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS
10  - PROJECTS_LAST_DISBURSEMENT_DATE_CLOSE_12MONTHS_OR_EXPIRED
11  - PROJECTS_SUMMARY_PORTFOLIO (sin filtro de pmr)
*/
export const getSimpleIndicatorProjects = (indicatorId, customFilterIndicators?) => {
  const returnedParams = indicatorProjectsCreateCommonsParams(indicatorId, customFilterIndicators);
  return postIndicatorsProjects({ ...returnedParams });
};

const indicatorProjectsCreateCommonsParams = (indicatorId, customFilterIndicators?) => {
  const filterIndicators = customFilterIndicators ? customFilterIndicators : store.getState().filterIndicators;
  const haveCountriesFilter = setHaveFiltersInputsAndSelects('country', true, filterIndicators.common);
  const haveDivisionFilter = setHaveFiltersInputsAndSelects('division', true, filterIndicators.common);
  const havePendingDisbursement = setHaveFiltersInputsAndSelects(
    'disbursementProjection',
    true,
    filterIndicators.common,
  );
  const haveLastDisbursement = setHaveFiltersInputsAndSelects('timeWithoutDisbursement', true, filterIndicators.common);
  const haveAgeOfProject = setHaveFiltersInputsAndSelects('ageProject', true, filterIndicators.common);
  const havePmrClassification = filterIndicators.common.pmrId.filter(pmr => pmr.selected === true);
  const havePipelineFilter = !!sessionStorage.getItem('indicatorPipelineFilter');
  const haveTaxonomy = filterIndicators.common.taxonomyFilter && filterIndicators.common.taxonomyFilter.id;
  const haveExecution = filterIndicators.common.executionFilter && filterIndicators.common.executionFilter.id;
  const haveExcludeRegionales = filterIndicators.common.excludeRegionals;
  const { projectProductAmounts, projectProductTypes } = getProductTypesFilterAndIgConfigFromStore(
    false,
    filterIndicators,
  );
  const hasAlertTypeId =
    sessionStorage.getItem('alertTypeId') && typeof parseInt(sessionStorage.getItem('alertTypeId') || '') === 'number';
  const haveBidConsultancyIndicatorFilter = !!sessionStorage.getItem('indicatorBidConsultancyContractYear');
  const regionsFilter = setValueFilter(true, 'regionId');
  const coReponsibleDivisionsFilter = setValueFilter(true, 'coReponsibleDivisions');
  const participantDivisionsFilter = setValueFilter(true, 'participantDivisions');
  const interestIssuesFilter = setValueFilter(true, 'interestIssues');
  const teamLeadersFilter = setValueFilter(true, 'teamLeaders');

  let returnedParams = {
    sortParameter: 1,
    indicatorId,
    regions: regionsFilter.length > 0 ? regionsFilter : [],
    Filters: [] as any,
    assignedToMe: false,
    includeRegionalProjects: IncludeRegionalProjectsEnum.NO_INCLUDE_PROJECTS,
    projectProductTypes,
    projectProductAmounts,
    coReponsibleDivisions: coReponsibleDivisionsFilter,
    participantDivisions: participantDivisionsFilter,
    interestIssues: interestIssuesFilter,
    teamLeaders: teamLeadersFilter,
  };

  const returnedParamsFilters = {
    haveCountriesFilter,
    haveDivisionFilter,
    havePendingDisbursement,
    haveLastDisbursement,
    haveAgeOfProject,
    havePmrClassification,
    havePipelineFilter,
    haveBidConsultancyIndicatorFilter,
    hasAlertTypeId,
    haveTaxonomy,
    haveExecution,
    haveExcludeRegionales,
  };

  returnedParams = getReturnedParmasIndicator(returnedParamsFilters, returnedParams, filterIndicators);
  return addMenuTabFilter(returnedParams, MenuTabSectionEnum.PORTFOLIO_MENU_TAB);
};

export const postIndicatorsProjects = returnedParams => {
  return new Promise<[]>(async (resolve, reject) => {
    const url = config.INDICATOR_PROJECTS;
    try {
      const response = await axiosDefaultConfig.post(url, returnedParams);

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const postIndicators = (returnedParams, options) => {
  if ('optionSelectedApplied' in options) {
    returnedParams = { ...returnedParams, includeRegionalProjects: options.optionSelectedApplied };
  }

  if (options.alertAndProblemPmr) {
    const pmrClass = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'PmrClassification',
      value: options.alertAndProblemPmr,
    };
    returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, pmrClass] };
  }

  if (options.fiscalYear) {
    const fiscalYear = { filterType: FilterTypesEnum.Equal, field: 'FiscalYear', value: options.fiscalYear };
    returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, fiscalYear] };
  }
  if (options.regionId) {
    const updateRegionsObject = [...returnedParams.regions, parseInt(options.regionId)];
    returnedParams = { ...returnedParams, regions: updateRegionsObject };
  }

  if (options.sectorId) {
    const sectorFilter = { filterType: FilterTypesEnum.Equal, field: 'SectorId', value: options.sectorId };
    returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, sectorFilter] };
  }

  if (options.executorCode) {
    const executorFilter = { filterType: FilterTypesEnum.Equal, field: 'ExecutorCode', value: options.executorCode };
    returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, executorFilter] };
  }

  if (options.includeAll) {
    returnedParams = { ...returnedParams, includeAll: options.includeAll };
  }

  if (options.indicatorId) {
    returnedParams = { ...returnedParams, indicatorId: parseInt(options.indicatorId) };
  }

  if (options.parameter) {
    returnedParams = { ...returnedParams, parameter: options.parameter === 'null' ? null : options.parameter };
  }
  returnedParams = handleFiltersFromIndicatorSummaryCTDetail(options, returnedParams);
  store.dispatch(setPayload(returnedParams))
  return new Promise<any>(async (resolve, reject) => {
    try {
      const url = config.INDICATOR;
      const response = await axiosDefaultConfig.post(url, returnedParams);
      handleResponse(resolve, reject, response);
    } catch (error) {
      reject(error);
    }
  });
};

const handleFiltersFromIndicatorSummaryCTDetail = (options, params) => {
  let returnedParams = { ...params };

  if (
    options.projectExecutorEntity === ProjectExecutionTypes.BID ||
    options.projectExecutorEntity === ProjectExecutionTypes.EXECUTING_AGENCY
  ) {
    const projectExecutorEntity = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'ProjectExecutorEntity',
      value: [options.projectExecutorEntity],
    };
    returnedParams = { ...returnedParams, Filters: [...returnedParams.Filters, projectExecutorEntity] };
  }

  if (options.tcpTaxonomyId) {
    const taxonomyFilter = {
      filterType: FilterTypesEnum.NumberIn,
      field: 'TCPTaxonomyId',
      value: [options.tcpTaxonomyId],
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, taxonomyFilter] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }
  if (
    options.projectCountriesCount === ProjectCountryCount.UP_TO_8 ||
    options.projectCountriesCount === ProjectCountryCount.MORE_THAN_8
  ) {
    const projectCountriesCount = {
      filterType: options.projectCountriesCount,
      field: 'ProjectCountriesCount',
      value: 8,
    };
    const returnedFilters = { Filters: [...returnedParams.Filters, projectCountriesCount] };
    returnedParams = { ...returnedParams, Filters: returnedFilters.Filters };
  }

  return returnedParams;
};

export const getIndicatorsFromProjectDetail = projectId => {
  let returnedParams = createCommonParams();
  returnedParams = { ...returnedParams, includeAll: true };
  returnedParams['projectsIds'] = [projectId];
  return postIndicators(returnedParams, {});
};

export const getAverageLagDetailInfo = (projectId: number, type: number, status: number) => {
  const url = config.GET_AVERAGE_LAG_DETAIL_DATA.replace('{projectId}', projectId.toString())
    .replace('{type}', type.toString())
    .replace('{status}', status.toString());

  return buildGet(url);
};

/*
 *Used by indicator
  PMR_VALIDATION_STATE
 */
export const getByCategorizationIndicatorProjects = (indicatorId, categorization) => {
  const returnedParams = indicatorProjectsCreateCommonsParams(indicatorId);

  return postIndicatorsProjects({ ...returnedParams, categorization });
};

/*
 *Used by indicator
 SUPERVISION_PLAN_PLANNED_VERSUS_EXECUTED
 */
export const getSimpleIndicatorProjectsWithDiscriminatorAndAttribute = (indicatorId, options) => {
  let returnedParams = indicatorProjectsCreateCommonsParams(indicatorId);
  returnedParams = handleActualProjectedDifferenceFilters(returnedParams, options);
  return postIndicatorsProjects({ ...returnedParams });
};
