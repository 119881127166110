import React from 'react';
import { useTranslation } from 'react-i18next';
import './ChronologyAccordance.scss';
import { DateSegment } from './DateSegment';
import moment from 'moment';
import { replaceMomentFormat } from '../../../../services/util';
import { AccordanceState } from '../../../../models/AccordanceState';

type props = {
  data: any;
  dataEvent: any;
  dataValidity: any;
  dataCompleted: any;
  dataCancel: any;
  accordanceState: any;
};

const ChronologyAccordance = (props: props) => {
  const { data, dataEvent, dataValidity, dataCompleted, accordanceState, dataCancel } = props;
  const { creationDate, expirationDate, accordanceAgreementsType, newExpirationDate } = data;
  const { t } = useTranslation();

  const dateColor = () => {
    const expirationFormat = moment(expirationDate.dateFormatedDetails).format();
    const newExpirationFormat =
      newExpirationDate.dateFormated !== '-' ? moment(newExpirationDate.dateFormated).format() : null;
    let currentDate = new Date();
    let dateToExpire = newExpirationFormat !== null ? newExpirationFormat : expirationFormat;
    let diference = moment(new Date(dateToExpire)).diff(currentDate, 'days');
    if (new Date(dateToExpire) < currentDate) {
      return 'stylesLiRed';
    } else {
      return 'stylesLi';
    }
  };

  return (
    <div className="right-chronology">
      <div className="dates">
        <p className="section-title">{t('new_agreement_chronology')}</p>
        <ul>
          <DateSegment
            styleCircleData={'stylesLi'}
            nodeTitle={t('new_date_of_origin_of_the_agreement')}
            nodeSubtitle={accordanceAgreementsType.name}
            data={creationDate.dateFormatedDetails}
          />
          <DateSegment
            styleCircleData={
              dataValidity?.length > 0 ||
                accordanceState === AccordanceState.VALID ||
                accordanceState === AccordanceState.FULLFILLED ||
                accordanceState === AccordanceState.CANCELLED
                ? 'stylesLi'
                : 'stylesLiGrey'
            }
            nodeTitle={t('active_accordance_singular')}
            data={
              dataValidity?.length > 0 ? replaceMomentFormat(moment(dataValidity[0]?.date).format('DD/MMM/YYYY')) : '-'
            }
          />
          {dataEvent?.length !== 0
            ? dataEvent?.map(data => {
              return (
                <DateSegment
                  styleCircleData={'stylesLi'}
                  nodeTitle={t('new_associated_event')}
                  nodeSubtitle={data.accordanceAgreementsType.name}
                  data={data?.dateCreated ? replaceMomentFormat(moment(data.dateCreated).format('DD/MMM/YYYY')) : '-'}
                />
                // ${moment(news.expectedDate).format(dateFormat)}`}
              );
            })
            : null}
          <DateSegment
            styleCircleData={accordanceState === AccordanceState.FULLFILLED ? 'stylesLi' : dateColor()}
            nodeTitle={t('due_date')}
            data={
              newExpirationDate.dateFormated === '-'
                ? expirationDate.dateFormatedDetails
                : newExpirationDate.dateFormatedDetails
            }
          //   highlightInfo={{ highlightNode, barColor, highlightEnumReference: GcmHighlighted.eligibility }}
          />

          {accordanceState === AccordanceState.FULLFILLED ? (
            <DateSegment
              styleCircleData={'stylesLi'}
              nodeTitle={t('efas_pending_states_0')}
              data={
                dataCompleted?.length > 0
                  ? replaceMomentFormat(moment(dataCompleted[0]?.date).format('DD/MMM/YYYY'))
                  : '-'
              }
            />
          ) : null}

          {accordanceState === AccordanceState.CANCELLED ? (
            <DateSegment
              styleCircleData={'stylesLi'}
              nodeTitle={t('supervision_plan_activity_state_4')}
              data={
                dataCancel?.length > 0 ? replaceMomentFormat(moment(dataCancel[0]?.date).format('DD/MMM/YYYY')) : '-'
              }
            />
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default ChronologyAccordance;
