import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { getReviews, getReviewById } from '../../../api/reviewsApi';
import { ReviewsStatusEnum } from '../../../models/ReviewsStatusEnum';
import { isMobile } from '../../../services/booleanUtil';
import { FormDates } from './FormDates/FormDates';
import { ReadOnlyDates } from './ReadOnlyDates/ReadOnlyDates';
import './ReviewsDate.scss';
import ReviewWithProjects from '../ReviewWithProjects/ReviewWithProjects';
import { translateTitleReview } from './Util';
import { setCurrentReview as setCurrentReviewRedux } from '../../../redux/actions/currentReview';
import { resetReviewSelectedFilter } from '../../../redux/actions/reviewProjectSelectedFilters';
import { resetTextSearch } from '../../../redux/actions/textSearch';
import { useFormMemoryHelpContext } from '../../../context/MemoryHelpFormContext';
import SpinningLoader from '../../ProjectDetail/ProjectTabs/Loader/SpinningLoader';
import { setIsDelegate } from '../../../redux/actions/reviewsDelegate';

export const ReviewsDate = ({ localRouter, visibleComponent }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    country: { id, name },
  } = useSelector<any, any>(state => state.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [title, setTitle] = useState('');
  const [active, setActive] = useState(false);
  const [currentUserCanEditRevision, setCurrentUserCanEditRevision] = useState(false);
  const [currentReview, setCurrentReview] = useState<any>({
    id: null,
    status: null,
    startDate: null,
    endDate: null,
    projects: [],
    title: '',
  });
  let currentYear = moment().year();

  const { setPreviousView } = useFormMemoryHelpContext();

  useEffect(() => setPreviousView(false), []);

  const getReviewsOfCurrentYear = async year => {
    const reviews = await getReviews(year, 'all', id);

    setCurrentUserCanEditRevision(reviews.currentUserCanEditRevision);
    const reviewsOfCurrentYear = reviews.portfolioRevisions
      .filter(rev => rev.revisions.some(r => hasStartDateAndCurrentYearOrFirst(r)))
      .map(a => {
        a.revisions = a.revisions.filter(r => hasStartDateAndCurrentYearOrFirst(r));
        return a;
      });

    dispatch(setIsDelegate(reviews.currentUserCanEditRevision))

    const lastReview = reviewsOfCurrentYear[reviewsOfCurrentYear.length - 1];
    const isFirstRevision = checkFirstRevision(lastReview);
    const existActiveReview =
      lastReview &&
      lastReview.revisions.find(
        review => review.startDate !== null && review.endDate !== null && review.status !== ReviewsStatusEnum.Ended,
      );

    const reviewNumberTranslation = translateTitleReview(isFirstRevision, lastReview, existActiveReview);

    const title = `${t(reviewNumberTranslation)} ${t('review')} ${currentYear}`;
    setTitle(title);

    const checkNextYearsRevisions = reviews.revisionYears.findIndex(returnedYear => returnedYear === year + 1);
    if (existActiveReview) {
      setExistActiveReview(existActiveReview, title);
    } else if (checkNextYearRevision(existActiveReview, checkNextYearsRevisions)) {
      getReviewsOfCurrentYear(reviews.revisionYears[checkNextYearsRevisions]);
      currentYear = reviews.revisionYears[checkNextYearsRevisions];
    } else {
      dispatch(setCurrentReviewRedux({ title }));
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetReviewSelectedFilter());
    dispatch(resetTextSearch());
    getReviewsOfCurrentYear(currentYear);
    setTimeout(() => {
      animateScroll.scrollToTop({ smooth: true, duration: 500 });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setExistActiveReview = async (existActiveReview, title) => {
    const review = await getReviewById(existActiveReview.id);
    setCurrentReview({ ...review, title });
    setActive(true);
    dispatch(
      setCurrentReviewRedux({
        ...review,
        title,
      }),
    );
    setLoading(false);
  };

  return !loading ? (
    currentReview.projects && currentReview.projects.length > 0 ? (
      <ReviewWithProjects
        editable={currentUserCanEditRevision}
        countryName={name}
        localRouter={localRouter}
        visibleComponent={visibleComponent}
      />
    ) : (
      <div className="reviews-date-container">
        <h1>
          {t('upcoming_portfolio_review')}
          {isMobile() && <br />}
          <span className="portfolio-name">{name}</span>
        </h1>

        <div className="content">
          {currentUserCanEditRevision ? (
            <FormDates title={title} review={currentReview} isActive={active} />
          ) : (
            <ReadOnlyDates title={title} review={currentReview} />
          )}
        </div>
      </div>
    )
  ) : (
    <div className="content-loader">
      <SpinningLoader />
    </div>
  );
};

const checkNextYearRevision = (existActiveReview, checkNextYearsRevisions) => {
  return existActiveReview === undefined && checkNextYearsRevisions !== -1;
};

const currentYearRevision = (startDate: string) => {
  const currentYear = new Date().getFullYear();
  return new Date(startDate).getFullYear() === currentYear;
};

const hasStartDateAndCurrentYearOrFirst = (r: any) =>
  (r.startDate && currentYearRevision(r.startDate)) || r.firstRevision;

const checkFirstRevision = (lastReview: any) => lastReview && lastReview.revisions.find(review => review.firstRevision);
