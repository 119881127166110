import React, { useState } from 'react';
import styles from './Table.module.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Pagination } from '@material-ui/lab';
import '../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../hooks/usePaginationSort';
import useTable from '../../../hooks/useTable';
import { commonHeader } from '../PortfolioDetailsUtil';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { Tooltip } from '@material-ui/core';
import PaginationTable from '../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = ['id', 'year', 'country', 'last_project', 'last_operation', 'name', 'division', 'current_approved_amount', 'disbursed_amount'];

const selectedKeys = ['id', 'field1', 'field2', 'code', 'loanNumber', 'title', 'field3', 'field4', 'field5'];
const customRowStyle = project => (project.id === 0 ? { cursor: 'default' } : {});

const Table = () => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { handleClick, projects } = useTable();
  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  const {
    container,
    tableHeader,
    tableRow,
    headerTitle,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    headerTitleClickable,
    rowValueClosed,
    borderTable,
  } = styles;

  const tableHeaderColumns = [
    ...commonHeader({
      headerTitleClickable,
      t,
      handleSort,
      field3Translation: t('division'),
      field5Translation: `${t('amount_disbursed')} ${t('dolar_sign_parentesis')}`,
    }),
  ];
  const data = useDataNormalize(headers, selectedKeys, projects);

  return projects.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />

      <div className={borderTable}>
        <div className={tableHeader}>
          <h4 className={headerTitle}>{t('year')}</h4>
          {tableHeaderColumns.map(column => {
            return (
              <h4 className={column.classNameStyle} onClick={column.sortFunction}>
                {column.translation}
              </h4>
            );
          })}

          <h4 className={headerTitle}>{''}</h4>
        </div>

        <ul className={rowList}>
          {currentList &&
            currentList.map((project, index) => (
              <li
                className={tableRow}
                key={`${project.loanNumber}${project.code}${project.frontendId}`}
                style={customRowStyle(project)}
              >
                <p className={rowValue}>{index === 0 ? project.field1 : ''}</p>

                <p className={rowValue}>{project.field2}</p>
                {project.id ? (
                  <BlackTooltip title={t('go_to_project_info')} arrow>
                    <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>
                      {project.code}{' '}
                    </p>
                  </BlackTooltip>
                ) : (
                  <p className={`${rowValue}`}>{project.code}</p>
                )}

                <p className={rowValue}>{project.loanNumber}</p>
                <BlackTooltip title={project.title || ''} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                  <p className={rowValueName}>{project.title}</p>
                </BlackTooltip>

                <p className={rowValue}>{project.field3}</p>
                <p className={rowValue}>
                  {
                    <NumberFormat
                      value={parseInt(project.field4)}
                      displayType={'text'}
                      className={'value'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  }
                </p>
                <p className={rowValue}>
                  {
                    <NumberFormat
                      value={parseInt(project.field5)}
                      displayType={'text'}
                      className={'value'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  }
                </p>
                <span className={lastColumn}>
                  {project.id ? <p className={fw}></p> : <p className={rowValueClosed}>{t('cancelled')}</p>}
                </span>
              </li>
            ))}
        </ul>
      </div>
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  ) : null;
};

export default Table;
