import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './GeneralInformation.scss';
import { useTranslation } from 'react-i18next';
import AdvanceData from './AdvanceData';
import DateText from '../DateText/DateText';
import { DateTextTypes } from '../../../models/DateTextTypes';
import Pmr from '../Pmr/Pmr';
import { useHistory, useLocation } from 'react-router-dom';
import { getIdFromUrl } from '../../../services/numberUtil';
import { PmrClassification } from '../../../models/PmrClassification';
import { initialDisbursement, initialTemporaryAdvance, initialDivision } from './util';
import { RelatedProjectLinks } from '../RelatedProjectLinks/RelatedProjectLinks';
import { ListEsg } from '../ListEsg/ListEsg';
import { ProjectProductTypes } from '../../../models/ProjectProductTypes';
import { getProject } from '../../../redux/actions/currentProject';
import Project from '../../../models/Project';
import { setProjectTabValue } from '../../../redux/actions/projectTabValue';

export const GeneralInformation = () => {
  const { t } = useTranslation();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    name = '',
    division = initialDivision,
    code = '',
    loanNumber = 0,
    temporaryAdvance = initialTemporaryAdvance,
    financialInformation = initialDisbursement,
    pmrClassification = PmrClassification.Green,
    pmrValue = 0,
    esgClasification = '',
    loans = null,
    esgRisk = 0,
    esgSafeguard = 0,
    projectProductType,
  } = currentProject;

  const handleSeeMoreClick = () => {
    const targetSection = document.getElementById('project-tabs');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      dispatch(setProjectTabValue(1));
    }, 380);
  };

  React.useEffect(() => {
    dispatch(getProject(getIdFromUrl(history.location.search)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <div className="project-detail-general-info-container">
      <div className="top">
        <div className="left">
          <h1>{name}</h1>
          <h3 className="loan">
            {`${t(projectProductType === ProjectProductTypes.GUARANTEE ? 'warranty' : 'loan')}: ${loanNumber}`}
            {loans?.length ? <RelatedProjectLinks loans={loans} mainLoanNumber={loanNumber} /> : null}
            <Pmr classification={pmrClassification} value={pmrValue} projectCode={code} />
          </h3>
        </div>
        <div className="right">
          <h3 className="code">{code}</h3>
          <div className="division">{division.code}</div>
        </div>
      </div>
      <div className="bottom" style={projectProductType === ProjectProductTypes.GUARANTEE ? { width: '85%' } : {}}>
        <div className="bottom-container bottomn-data">
          {temporaryAdvance && (
            <AdvanceData financialInformation={financialInformation} temporaryAdvance={temporaryAdvance} />
          )}
          {/* <AdvanceData financialInformation={financialInformation} temporaryAdvance={temporaryAdvance} /> */}
        </div>

        <div className="bottom-container">
          <h3>{`${t('disbursement_title')}`}</h3>
          {projectProductType === ProjectProductTypes.GUARANTEE ? null : (
            <>
              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.lastDisbursementDate}
                text={'last_accomplished_disbursement_complete'}
              />

              <DateText
                type={DateTextTypes.Date}
                value={financialInformation.nextDisbursementDate}
                text={'next_projected_disbursement'}
              />
              <div className="container-project-detail-date">
                <div className="project-detail-date-text-container">
                  <span className="text">{`${t('disbursement_minimum')}`}</span>
                  <span className="date">
                    {`${financialInformation.minimumAmountToJustify.toLocaleString()} ${t('dolar_sign')}`}{' '}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="botton-container-button">
            <button className="btn-primary-text" onClick={handleSeeMoreClick}>
              {t('see_more')}
            </button>
          </div>
        </div>
        <div className="bottom-container padding-none">
          <ListEsg values={{ esgClasification, esgRisk, esgSafeguard }} />
        </div>
      </div>
    </div>
  );
};
export default GeneralInformation;
