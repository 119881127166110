import React, { useState } from 'react';
import styles from './PcrNextPresentationTable.module.scss';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import useTable from '../../../../hooks/useTable';
import {
  betweenMonthToDueDate,
  dueDate,
  formatMethodology,
  percentageFormat,
  renderLikeyToBeSubmittedPcrStatus,
  renderUpcomingSubmissionPcrStatus,
} from '../Util';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Tooltip } from '@material-ui/core';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const {
  container,
  rowValueCentered,
  headerTitleClickableCentered,
  headerTitle,
  rowValue,
  rowList,
  rowValueName,
  fw,
  lastColumn,
  headerTitleClickable,
  tableHeaderUpcomingSubmission,
  tableHeaderLikelyToBeSubmitted,
  tableRowUpcomingSubmission,
  tableRowLikelyToBeSubmitted,
  rowValueCenteredAndUppercase,
  rowValueMoreThanOneMonthAgo,
  rowValueBetweenLastMonth,
  closeRowValue,
  borderTable,
} = styles;

const headersUpcoming = ['id', 'last_project', 'last_operation', 'name', 'methodology', 'oc_date_pcr_start', 'status', 'due_date_ove'];

const headersShorTerm = ['id', 'last_project', 'last_operation', 'name', 'date_of_last_disbursement', 'percentage_disbursed_pcr_next_presentation', 'probability'];

const selectedKeysUpcoming = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2', 'field3', 'field4'];
const selectedKeysShorTerm = ['id', 'code', 'loanNumber', 'title', 'field6', 'field5', 'field3'];
type Props = {
  isUpcommingPresentationTable: boolean;
};

const PcrNextPresentationTable = ({ isUpcommingPresentationTable }: Props) => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  const handleClickIfHasProjectId = project => {
    if (project.id !== 0) {
      handleClick(project);
    }
  };
  const headers = isUpcommingPresentationTable === true ? headersUpcoming : headersShorTerm;
  const selectedKeys = isUpcommingPresentationTable === true ? selectedKeysUpcoming : selectedKeysShorTerm;
  const data = useDataNormalize(headers, selectedKeys, projects);

  return projects.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />

      <div className={borderTable}>
        <div className={handleTableHeaderClassname(isUpcommingPresentationTable)}>
          <h4 className={headerTitleClickable} onClick={() => handleSort('code', CriteriaType.string)}>
            {t('code')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('loanNumber', CriteriaType.string)}>
            {t('operation')}
          </h4>
          <h4 className={headerTitleClickable} onClick={() => handleSort('title', CriteriaType.string)}>
            {t('name')}
          </h4>
          {handleTableHeaderByFilter(isUpcommingPresentationTable, { handleSort, t })}
        </div>

        <ul className={rowList}>
          {currentList &&
            currentList.map((project, index) => {
              const {
                id,
                frontendId,
                code,
                loanNumber,
                title,
                field1: methodology,
                field2: operationCloseDate,
                field3: pcrStatus,
                field4: dueDate,
                field5: percentDisbursement,
                field6: lastDisbursementDate,
              } = project;
              return (
                <li
                  className={handleTableRowClassname(isUpcommingPresentationTable)}
                  key={frontendId}
                  onClick={() => {
                    handleClickIfHasProjectId(project);
                  }}
                  style={id === 0 ? { cursor: 'default' } : {}}
                >
                  <BlackTooltip arrow title={t('go_to_project_info')}>
                    <p className={`${rowValue} link-project`}>{code}</p>
                  </BlackTooltip>
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip arrow title={title} placement="bottom" enterDelay={500} leaveDelay={100}>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>

                  {handleTableBodyByFilter(isUpcommingPresentationTable, {
                    t,
                    methodology,
                    operationCloseDate,
                    pcrStatus,
                    dueDate,
                    percentDisbursement,
                    lastDisbursementDate,
                  })}
                </li>
              );
            })}
        </ul>
      </div>

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  ) : null;
};

const handleTableHeaderClassname = isUpcommingPresentationTable =>
  isUpcommingPresentationTable ? tableHeaderUpcomingSubmission : tableHeaderLikelyToBeSubmitted;

const handleTableRowClassname = isUpcommingPresentationTable =>
  isUpcommingPresentationTable ? tableRowUpcomingSubmission : tableRowLikelyToBeSubmitted;

const handleTableHeaderByFilter = (isUpcommingPresentationTable, params) => {
  const { handleSort, t } = params;
  if (isUpcommingPresentationTable) {
    return (
      <>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field1', CriteriaType.string)}>
          {t('methodology')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field2', CriteriaType.date)}>
          {t('oc_date_pcr_start')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field3', CriteriaType.string)}>{`${t(
          'status',
        )}`}</h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field4', CriteriaType.date)}>{`${t(
          'due_date_ove',
        )}`}</h4>
      </>
    );
  } else {
    return (
      <>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field6', CriteriaType.date)}>
          {t('date_of_last_disbursement')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field5', CriteriaType.number)}>
          {t('percentage_disbursed_pcr_next_presentation')}
        </h4>
        <h4 className={headerTitleClickableCentered} onClick={() => handleSort('field3', CriteriaType.string)}>{`${t(
          'probability',
        )}`}</h4>
      </>
    );
  }
};
const handleTableBodyByFilter = (isUpcommingPresentationTable, params) => {
  if (isUpcommingPresentationTable) {
    const { t, methodology, operationCloseDate, pcrStatus, dueDate } = params;
    return (
      <>
        <p className={rowValueCentered}>{formatMethodology(methodology)}</p>
        <p className={rowValueCenteredAndUppercase}>{renderDateOrSlash(operationCloseDate)}</p>
        <p className={rowValueCentered}>{t(renderUpcomingSubmissionPcrStatus(pcrStatus))}</p>
        <p className={handleDueDateOveStyle(dueDate)}>{renderDateOrSlash(dueDate)}</p>
      </>
    );
  } else {
    const { t, pcrStatus, percentDisbursement, lastDisbursementDate } = params;
    return (
      <>
        <p className={rowValueCenteredAndUppercase}>{renderDateOrSlash(lastDisbursementDate)}</p>
        <p className={rowValueCentered}>{percentageFormat(percentDisbursement)}</p>
        <p className={rowValueCentered}>{t(renderLikeyToBeSubmittedPcrStatus(pcrStatus))}</p>
      </>
    );
  }
};

const handleDueDateOveStyle = (stringDate: string) => {
  if (stringDate === null) {
    return rowValueCenteredAndUppercase;
  } else if (dueDate(new Date(stringDate))) {
    return rowValueMoreThanOneMonthAgo;
  } else if (betweenMonthToDueDate(new Date(stringDate))) {
    return rowValueBetweenLastMonth;
  }
  return rowValueCenteredAndUppercase;
};

export default PcrNextPresentationTable;
