import React from 'react';
import styles from './Table.module.scss';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Pagination } from '@material-ui/lab';
import '../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../hooks/usePaginationSort';
import useTable from '../../../hooks/useTable';
import { commonHeader } from '../PortfolioDetailsUtil';
import { TablePagination, Tooltip } from '@material-ui/core';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import PaginationTable from '../PaginationTable/PaginationTable';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

const headers = ['id', 'year', 'country', 'last_project', 'last_operation', 'name', 'Division', 'approved_current_amount', 'disbursed',];

const selectedKeys = ['id', 'field1', 'field2', 'code', 'loanNumber', 'title', 'field3', 'field4', 'field5'];

const Table = () => {
  const { t } = useTranslation();
  const {
    container,
    tableHeader,
    tableRow,
    header,
    rowValue,
    rowList,
    rowValueName,
    fw,
    lastColumn,
    headerTitleClickable,
    rowValueClosed,
    borderTable,
  } = styles;
  const { handleClick, projects } = useTable();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerColumns = [
    ...commonHeader({
      headerTitleClickable,
      t,
      handleSort,
      field3Translation: t('division_capital'),
      field5Translation: t('disbursed_disbursement_projection_last_5_years'),
    }),
  ];
  const data = useDataNormalize(headers, selectedKeys, projects);

  return projects.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
      <div className={borderTable}>
        <div className={tableHeader}>
          <h4 className={header}>{t('year_word_disbursement_projection_last_5_years')}</h4>
          {headerColumns.map(column => {
            return (
              <h4 className={column.classNameStyle} onClick={column.sortFunction}>
                {column.translation}
              </h4>
            );
          })}
          <h4 className={header}>{''}</h4>
        </div>
        <ul className={rowList}>
          {currentList &&
            currentList.map((project, index) => {
              const {
                frontendId,
                field1: yearColumn,
                field2: countryColumn,
                title: nameColumn,
                field3: divisioCapitalColumn,
                field4: originalProjectionColumn,
                field5: disbursedColumn,
                code,
                loanNumber,
                title,
                id,
              } = project;
              return (
                <li className={tableRow} key={frontendId} onClick={id ? () => handleClick(project) : () => { }}>
                  <p className={rowValue}>{index === 0 ? yearColumn : ''}</p>
                  <p className={rowValue}>{countryColumn}</p>
                  {project.id ? (
                    <BlackTooltip title={t('go_to_project_info')} arrow>
                      <p className={`${rowValue} link-project`} onClick={() => handleClick(project)}>{code}</p>
                    </BlackTooltip>
                  ) : (
                    <p className={`${rowValue}`}>{project.code}</p>
                  )}
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip arrow title={nameColumn || ''} placement="bottom" enterDelay={500} leaveDelay={100}>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>

                  <p className={rowValue}>{divisioCapitalColumn}</p>
                  <p className={rowValue}>
                    {
                      <NumberFormat
                        value={parseInt(originalProjectionColumn)}
                        displayType={'text'}
                        className={'value'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                      />
                    }
                  </p>
                  <p className={rowValue}>
                    {
                      <NumberFormat
                        value={parseInt(disbursedColumn)}
                        displayType={'text'}
                        className={'value'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={'%'}
                      />
                    }
                  </p>
                  <span className={lastColumn}>{handleForwardOrText(id, fw, rowValueClosed, t)}</span>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  ) : null;
};

export default Table;

const handleForwardOrText = (id, fw, rowValueClosed, t) => {
  return id ? <p className={fw}></p> : <p className={rowValueClosed}>{t('cancelled')}</p>;
};
