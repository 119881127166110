import React, { useCallback, useEffect, useState } from 'react';
import './Acquisitions.scss';
import ClassificationPlan from './AcquisitionsComponent/ClassificationPlan';
import CompositionPlan from './AcquisitionsComponent/CompositionPlan';
import AvanceProcessMatriz from './AcquisitionsComponent/AvanceProcessMatriz/AvanceProcessMatriz';
import AcquisitionTable from './AcquisitionsComponent/AvanceProcessMatriz/Table';
import { SelectFilters } from '../../../Util/SelectFilters/SelectFilters';
import { useTranslation } from 'react-i18next';
import { getAdquisitionsData } from '../../../../api/projectApi';
import { useDispatch, useSelector } from 'react-redux';
import { getAdquisitionData } from '../../../../redux/actions/currentProject';
import Project from '../../../../models/Project';
import SpinningLoader from '../Loader/SpinningLoader';

const Acquisitions = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const currentProject = useSelector<any, Project>(state => state.currentProject);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('top100');

  const [dataFilter, setDataFilter] = useState<any>(data?.acquisitionProcesses);
  const optionsFilter = [
    { value: 'top100', optionLabel: 'new_top' },
    { value: 'dateStimated', optionLabel: 'new_with_estimated_publication_in_the_current_year' },
    { value: 'dateHiring', optionLabel: 'new_with_estimated_contraction_in_the_current_year' },
  ];

  useEffect(() => {
    if (currentProject?.id) {
      const projectId = parseInt(currentProject.id.toString());
      dispatch(getAdquisitionData(projectId));
      getData();
    }
  }, []);

  const getData = useCallback(async () => {
    try {
      const dataAdquisitions = await getAdquisitionsData(currentProject.id);
      setDataFilter(dataAdquisitions.acquisitionProcesses);
      setData(dataAdquisitions);
    } catch (error) {
      setData('noData');
      console.error('Error fetching acquisitions data: ', error);
    }
  }, [currentProject.id]);

  if (data === 'noData') return (
    <>
      <div className="avance-process-acqui">
        <div className='container-box'>
          <div className="clean-data-container">
            <h3>{t('last_no_information')}</h3>
            <p>{t('last_the_project_does_not_have_procurement_plan')}</p>
          </div></div>
      </div>
    </>
  )


  if (!data) return <section className='acquisitions'> <SpinningLoader />  </section>;

  return (
    <section className="acquisitions">
      <div>
        <ClassificationPlan classificationData={data} />
      </div>
      <div className="content-two">
        <CompositionPlan compositionData={data.compositionProcurementPlan} />
        <AvanceProcessMatriz avanceData={data} />
      </div>
      <div className="table-acquisitions">
        <div className="filter-select">
          <SelectFilters
            labelValue={t('new_youre_watching')}
            handleChange={setDataFilter}
            options={optionsFilter}
            selectValue={selectedOption} // Pasamos el valor seleccionado como prop
            data={data.acquisitionProcesses}
            detailProject={true}
            fnSetValue={setSelectedOption} // Función para actualizar el valor seleccionado
          />
        </div>
        {dataFilter && dataFilter.length > 0 && (
          <AcquisitionTable classificationData={dataFilter} />
        )}
      </div>
    </section>
  );
};

export default Acquisitions;
