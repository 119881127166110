import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  likelyToBeSubmittedShortTermDefault,
  PcrNextPresentationChartEnum,
  upcomingSubmissionBulletsDefault,
} from '../../../models/PcrNextPresentation';
import { getProjectsByCategorization } from '../../../redux/actions/indicatorProjects';
import {
  getFilterBySessionKeyOrGetDefaultSessionObject,
  PCR_NEXT_PRESENTATION,
} from '../../../services/sessionFiltersIndicatorDetailHelper';
import styles from './PcrNextPresentation.module.scss';
import PcrNextPresentationChart from './PcrNextPresentationChart/PcrNextPresentationChart';
import PcrNextPresentationTable from './PcrNextPresentationTable/PcrNextPresentationTable';
import { TableHeader } from './TableHeader/TableHeader';
const { container, pcrCharContainer, bodyContainer, tableContainer, extraHeaderTitle, headerTitleStyle } = styles;
const categorizationValues = {
  0: '7',
  1: '8',
};

type Props = {
  headerTitle: any;
};
export const PcrNextPresentation = ({ headerTitle }: Props) => {
  const { upcomingSubmissionTotal, likelyToBeSubmittedShortTermTotal, id, indicatorKey } = useSelector<any, any>(
    state => state.currentIndicator,
  );
  const defaultSessionFilterObject = {
    selectedChart:
      upcomingSubmissionTotal !== 0
        ? PcrNextPresentationChartEnum.UPCOMING_SUBMISSION
        : PcrNextPresentationChartEnum.LIKELY_TO_BE_SUBMITTED_SHORT_TERM,
  };
  const { firstDataGroup, secondDataGroup } = useSelector<any, any>(state => state.currentIndicator.data);
  const [chartSelected, setChartSelected] = useState<any>(
    getFilterBySessionKeyOrGetDefaultSessionObject(PCR_NEXT_PRESENTATION, defaultSessionFilterObject).selectedChart,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionStorage.getItem(PCR_NEXT_PRESENTATION)) {
      sessionStorage.setItem(PCR_NEXT_PRESENTATION, JSON.stringify(defaultSessionFilterObject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, id, categorizationValues[chartSelected], indicatorKey);
    const storage = sessionStorage.getItem(PCR_NEXT_PRESENTATION);
    storage && JSON.parse(storage).selectedChart !== chartSelected && sessionStorage.removeItem('PaginationIndicator');
    storage &&
      sessionStorage.setItem(
        PCR_NEXT_PRESENTATION,
        JSON.stringify({ ...JSON.parse(storage), selectedChart: chartSelected }),
      );
    headerTitle(
      true,
      <p className={headerTitleStyle}>
        {t('upcoming_submission_of_pcr')}
        {chartSelected === PcrNextPresentationChartEnum.LIKELY_TO_BE_SUBMITTED_SHORT_TERM && (
          <p className={extraHeaderTitle}> / {t('likely_to_be_submitted_in_the_short_term')}</p>
        )}
      </p>,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSelected]);

  const handleClick = clickedValue => {
    if (clickedValue === PcrNextPresentationChartEnum.UPCOMING_SUBMISSION && upcomingSubmissionTotal > 0) {
      setChartSelected(clickedValue);
    }
    if (
      clickedValue === PcrNextPresentationChartEnum.LIKELY_TO_BE_SUBMITTED_SHORT_TERM &&
      likelyToBeSubmittedShortTermTotal > 0
    ) {
      setChartSelected(clickedValue);
    }
  };
  return (
    <div className={container}>
      <div className={pcrCharContainer}>
        <PcrNextPresentationChart
          title={t('upcoming_submission')}
          data={firstDataGroup}
          selected={chartSelected === PcrNextPresentationChartEnum.UPCOMING_SUBMISSION}
          handleClick={() => handleClick(PcrNextPresentationChartEnum.UPCOMING_SUBMISSION)}
          bulletsData={upcomingSubmissionBulletsDefault}
          unselectable={upcomingSubmissionTotal === 0}
        />
        <PcrNextPresentationChart
          title={t('likely_to_be_submitted_in_the_short_term')}
          data={secondDataGroup}
          selected={chartSelected === PcrNextPresentationChartEnum.LIKELY_TO_BE_SUBMITTED_SHORT_TERM}
          handleClick={() => handleClick(PcrNextPresentationChartEnum.LIKELY_TO_BE_SUBMITTED_SHORT_TERM)}
          bulletsData={likelyToBeSubmittedShortTermDefault}
          unselectable={likelyToBeSubmittedShortTermTotal === 0}
        />
      </div>
      <div className={bodyContainer}>
        <TableHeader
          titleKey={
            chartSelected === PcrNextPresentationChartEnum.UPCOMING_SUBMISSION
              ? 'upcoming_submission'
              : 'likely_to_be_submitted_in_the_short_term'
          }
        />
        <div className={tableContainer}>
          <PcrNextPresentationTable
            isUpcommingPresentationTable={chartSelected === PcrNextPresentationChartEnum.UPCOMING_SUBMISSION}
          />
        </div>
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, categorization, indicatorKey) => {
  return d(getProjectsByCategorization(currentIndicatorId, categorization, { indicatorKey }));
};
