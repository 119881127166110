import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import {
  findMetricValue,
  findMetricValueByGrouping,
  getIndicatorData,
  setPercentageWeight,
} from './indicatorHelperUtil';
import { getTabValuesFromMetrics } from './util';

const getDoughnutIndicator = (indicator, options) => {
  const {
    projectQuantity,
    dataLabelsTranslations,
    dataLabelsClasses,
    backgroundColor,
    hoverBackgroundColor,
    conceptTranslation,
    title,
    detailTitle,
    alert_number,
    alert_text,
    type,
    borderWidth,
    detailExtraProps,
    columns,
  } = options;
  return {
    id: indicator.id,
    indicatorKey: indicator.key,
    type,
    title,
    alert_number,
    alert_text,
    detailTitle,
    order: indicator.order,
    detailExtraProps: detailExtraProps ? detailExtraProps : null,
    data: {
      datasets: [
        {
          data: setPercentageWeight(projectQuantity),
          projectQuantity,
          dataLabelsTranslations,
          dataLabelsClasses,
          conceptTranslation,
          backgroundColor,
          hoverBackgroundColor,
          borderWidth: borderWidth ? borderWidth : 2,
        },
      ],
      labels: dataLabelsTranslations,
      columns,
    },
    tabIndicatorValues: getTabValuesFromMetrics(indicator),
  };
};

const getTextIndicator = (indicators, field, lightText, boldText, detailTitle = '', analyticIndicator = false) => {
  const indicator = getIndicatorData(indicators, field);

  if (!indicator) {
    return null;
  }

  let data: any = null;

  if (field === IndicatorKeyEnum.PROJECTS_PENDING_SIGNATURE_OR_LEGISLATIVE_APPROVAL) {
    data = {
      metricValue:
        parseInt(findMetricValue(indicator.metrics, 'AMOUNT_PENDING_SIGNATURE')) +
        parseInt(findMetricValue(indicator.metrics, 'AMOUNT_PENDING_RATIFICATION')),
      pendingSignature: findMetricValue(indicator.metrics, 'AMOUNT_PENDING_SIGNATURE'),
      pendingRatification: findMetricValue(indicator.metrics, 'AMOUNT_PENDING_RATIFICATION'),
    };
  } else if (
    field === IndicatorKeyEnum.PROJECTS_PARTIAL_CANCELLATIONS ||
    field === IndicatorKeyEnum.PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT
  ) {
    data = indicator.metrics.find(m => m.groupingName === 'PROJECT_PRODUCT_TYPE' && m.groupingValue === 'NONE');
  } else {
    data = indicator.metrics[0];
  }

  return {
    id: analyticIndicator ? 'na' : indicator.id,
    indicatorKey: indicator.key,
    type: 'text',
    title: lightText,
    subtitle: boldText,
    detailTitle,
    data:
      data && data.metricValue
        ? {
          value: data.metricValue,
          lightText,
          boldText,
          pendingSignature: data.pendingSignature,
          pendingRatification: data.pendingRatification,
        }
        : null,
    order: analyticIndicator ? 'na' : indicator.order,
    tabIndicatorValues: {
      Loan: handleRightMetricsValuesToProjectTypesTabs(indicator, 'loan'),
      CT: handleRightMetricsValuesToProjectTypesTabs(indicator, 'ct'),
      IGBoth: handleRightMetricsValuesToProjectTypesTabs(indicator, 'igBoth'),
      IGGreater: handleRightMetricsValuesToProjectTypesTabs(indicator, 'igGreater'),
      IGLower: handleRightMetricsValuesToProjectTypesTabs(indicator, 'igLower'),
    },
  };
};

export { getDoughnutIndicator, getTextIndicator };

const handleRightMetricsValuesToProjectTypesTabs = (indicator, productType: string) => {
  const byGroupingValue = {
    loan: 'LON',
    ct: 'TCP',
    igBoth: 'IGR',
  };
  const byMetricValue = {
    loan: 'LON_PROJECT_COUNT',
    ct: 'TCP_PROJECT_COUNT',
    igBoth: 'IGR_PROJECT_COUNT',
    igGreater: 'IGR_PROJECT_COUNT',
    igLower: 'IGR_PROJECT_COUNT',
  };
  switch (indicator.key) {
    case IndicatorKeyEnum.PROJECTS_PARTIAL_CANCELLATIONS:
    case IndicatorKeyEnum.PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT:
      return findMetricValueByGrouping(
        indicator.metrics,
        'PROJECT_PRODUCT_TYPE',
        byGroupingValue[productType],
        'PROJECT_COUNT',
      );

    default:
      return parseInt(findMetricValue(indicator.metrics, byMetricValue[productType]));
  }
};
