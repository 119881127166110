import React from 'react';
import { useTranslation } from 'react-i18next';
import './SaveButton.scss';

type Props = {
  handleClick: any;
  customStyle?: {};
  disabled?: boolean;
  customText?: any;
  isAccordance?: boolean;
};

const SaveButton = (props: Props) => {
  const { handleClick, disabled, customText, isAccordance } = props;
  const { t } = useTranslation();
  return (
    <button
      style={props.customStyle || {}}
      className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {isAccordance === true ? <span className="text">{customText || t('CONFIRM')}</span> :
        <span className="text">{customText || t('save')}</span>}
    </button>
  );
};

export default SaveButton;
