import React from 'react';
import './AccordanceCard.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Accordance from '../../../../../models/Accordance';
import CommentsIcon from '../CommentsIcon/CommentsIcon';
import { AccordanceType } from '../../../../../models/AccordanceType';
import { replaceMomentFormat } from '../../../../../services/util';
import { parseToSemester } from '../../../../../services/stringUtil';
import { AccordanceState } from '../../../../../models/AccordanceState';
import User from '../../../../../models/User';
import useAccordanceDetailModalsState from '../../../../../hooks/useAccordanceDetailModalsState';
import AccordanceDetailModals from '../AccordanceDetailModals';

type Props = {
  accordance?: Accordance | any;
  onClick?: any;
  events?: any;
};

const AccordanceCard = (props: Props) => {
  const { accordance, onClick, events } = props;
  const { hasUnreadComments, accordanceComments, personToApprove, accordanceState, accordanceType, rejectionDetail } =
    accordance;
  const { t } = useTranslation();
  const stateProps = useAccordanceDetailModalsState();
  const { showModal } = stateProps;

  const accordanceInfo = setDefaultValues(accordance, t);

  const arraySort = accordanceInfo.linkedProductsAccordance.sort((a: any, b: any) => {
    if (a.nameProduct < b.nameProduct) {
      return -1;
    }
    if (a.nameProduct > b.nameProduct) {
      return 1;
    }
    return 0;
  });

  const dateColor = () => {
    let currentDate = new Date();
    let dateToExpire =
      accordance?.newExpirationDate !== null ? accordance?.newExpirationDate : accordance.expirationDate;
    let diference = moment(new Date(dateToExpire)).diff(currentDate, 'days');
    if (new Date(dateToExpire) < currentDate) {
      return '#FF0000';
    } else if (diference <= 7) {
      return '#000000';
    } else {
      return '#727e8c';
    }
  };

  // Se agrega funcion para agregar 2 funciones en mismo onClick

  const handleClickPlus = e => {
    showModal();
    onClick(e);
  };

  return (
    <>
      <div
        className={`accordance-card-container ${setAccordanceTypeClass(accordanceType)} ${setAccordanceStateClass(
          accordanceState,
          personToApprove,
        )}`}
        onClick={handleClickPlus}
      >
        <div className="division">
          <span>{accordanceInfo.accordanceAgreementsType.name}</span>
        </div>
        {/* <div className="label-data">
          <span className="label-data-label">{t('critical_issue')}:</span>
          <span className="label-data-data" title={accordanceInfo.criticalIssue.name}>
            {accordanceInfo.criticalIssue.name}
          </span>
        </div> */}
        <div className="label-data">
          <span className="label-data-label">{t('accordance_description')}</span>
          <span className="label-data-data" title={accordanceInfo.description}>
            {accordanceInfo.description}
          </span>
        </div>
        {/* <div className="label-data">
          <span className="label-data-label">{t('accordance_about')}:</span>
          <span className="label-data-data" title={accordanceInfo.possibleAction.name}>
            {accordanceInfo.possibleAction.name}
          </span>
        </div> */}

        <div className="horizontal">
          <div className="vertical responsible">
            <span className="label">{t('responsible')}</span>
            <span className="data-vertical">
              {accordanceInfo.personInCharge.name} {accordanceInfo.personInCharge.lastName}
            </span>
          </div>
          <div className="vertical">
            <span className="label">{t('due_date')}</span>
            <span
              className="data"
              style={{
                // backgroundColor: isHoveringDate ? '#E8E8E8' : '',
                color:
                  accordance.newExpirationDate !== null || accordanceState === AccordanceState.FULLFILLED
                    ? '#727e8c'
                    : dateColor(),
              }}
            >
              {accordance.newExpirationDate !== null
                ? accordanceInfo.newExpirationDate.dateFormated
                : accordanceInfo.expirationDate.dateFormated}
            </span>
          </div>

          {accordanceInfo.linkedProductsAccordance.length !== 0 && (
            <div className="vertical">
              <span className="label">{t('new_linked_product')}</span>
              <div className="title-label-product">
                {arraySort?.map(element => {
                  return <span className="data-product">{element.nameProduct}</span>;
                })}
              </div>
            </div>
          )}

          <div className="vertical">
            <span className="label">{t('comments')}</span>
            <CommentsIcon
              hasUnreadComments={hasUnreadComments}
              comments={accordanceComments ? accordanceComments.filter(item => !item.isDeleted).length : 0}
            />
          </div>
        </div>
        {/* <span className="label grouped">
          <div>
            {t('accordance_of')}
            {setAccordanceLabelAndColor(accordanceType, t)}
          </div>

          {accordanceType === AccordanceType.Revision && (
            <span className="data with-margin">{accordanceInfo.revisionDate.dateFormated}</span>
          )}
          {accordanceType === AccordanceType.InterGroup && (
            <span className="data with-margin captialize">{accordanceInfo.revisionDate.dateSemester}</span>
          )}
        </span> */}

        {accordanceState === AccordanceState.PENDING &&
          personToApprove &&
          accordanceType !== AccordanceType.InterGroup && (
            <div className="bottom-gray">
              <span className="label">{t('waiting_approval')}</span>
              <span className="data">
                {personToApprove.name} {personToApprove.lastName}
              </span>
            </div>
          )}
        {accordanceType === AccordanceType.InterGroup && accordanceState === AccordanceState.PENDING && (
          <div className="bottom-gray">
            <span className="label">{t('waiting_approval')}</span>
            <span className="data">{t('operations_management')}</span>
          </div>
        )}
        {accordanceState === AccordanceState.REJECT && rejectionDetail && rejectionDetail.rejectedBy && (
          <div className="bottom-gray">
            <span className="label">{t('rejected_by')}</span>
            <span className="data">
              {rejectionDetail.rejectedBy.name} {rejectionDetail.rejectedBy.lastName}
            </span>
          </div>
        )}
      </div>
      <AccordanceDetailModals accordance={accordance} stateProps={stateProps} />
    </>
  );
};

export default AccordanceCard;

const setAccordanceTypeClass = (accordanceType: AccordanceType) =>
  accordanceType === AccordanceType.Revision
    ? 'revision'
    : accordanceType === AccordanceType.Monitoring
    ? 'monitor'
    : 'inter-group';

const setAccordanceLabelAndColor = (accordanceType: AccordanceType, t: Function) =>
  accordanceType === AccordanceType.Revision ? (
    <span style={{ color: '#6F082C' }}>{t('portfolio_review')}</span>
  ) : accordanceType === AccordanceType.Monitoring ? (
    <span style={{ color: '#f9c268' }}>{t('motitoring')}</span>
  ) : (
    <span style={{ color: '#7a629b' }}>{t('inter_group_revision_capitalized')}</span>
  );

const setAccordanceStateClass = (state: AccordanceState, personToApprove: User | undefined) =>
  (state === AccordanceState.PENDING && personToApprove) || state === AccordanceState.REJECT
    ? 'extra-height'
    : state === AccordanceState.CANCELLED
    ? 'horizontal-display'
    : state === AccordanceState.DRAFT
    ? 'low-opacity'
    : '';

export const setDefaultValues = (accordance, t) => {
  return (
    accordance && {
      accordanceAgreementsType: accordance.accordanceAgreementsType || {
        id: '-',
        isActive: '-',
        name: '-',
      },
      description: accordance.description || '-',
      criticalIssueDescription: accordance.criticalIssueDescription || '-',
      linkedProductsAccordance: accordance.linkedProductsAccordance || [],
      rootCauseCategory: accordance.rootCauseCategory || {
        id: '-',
        isActive: '-',
        name: '-',
      },
      criticalIssue: accordance.criticalIssue || {
        id: '-',
        categoryName: '-',
        name: '-',
      },
      personInCharge: accordance.personInCharge || {
        name: '-',
        lastName: '',
      },
      revisionDate: accordance.revisionDate
        ? {
            dateFormated: replaceMomentFormat(moment(accordance.revisionDate).format('DD/MMM/YYYY')),
            dateSemester: parseToSemester(accordance.revisionDate, t),
          }
        : {
            dateFormated: '-',
            dateSemester: '-',
          },
      expirationDate: accordance.expirationDate
        ? {
            dateFormated: replaceMomentFormat(moment(accordance.expirationDate).format('DD/MMM/YYYY')),
            dateFormatedDetails: replaceMomentFormat(moment(accordance.expirationDate).format('DD/MMM/YYYY')),
          }
        : {
            dateFormated: '-',
          },
      newExpirationDate: accordance.newExpirationDate
        ? {
            dateFormated: replaceMomentFormat(moment(accordance.newExpirationDate).format('DD/MMM/YYYY')),
            dateFormatedDetails: replaceMomentFormat(moment(accordance.newExpirationDate).format('DD/MMM/YYYY')),
          }
        : {
            dateFormated: '-',
          },
      accordanceDate: accordance.accordanceDate
        ? {
            dateFormated: replaceMomentFormat(moment(accordance.accordanceDate).format('DD/MMM/YYYY')),
            dateFormatedDetails: replaceMomentFormat(moment(accordance.accordanceDate).format('DD/MMM/YYYY')),
          }
        : {
            dateFormated: '-',
          },
      creationDate: accordance.dateCreated
        ? {
            dateFormated: replaceMomentFormat(moment(accordance.dateCreated).format('MMM/YYYY')),
            dateFormatedDetails: replaceMomentFormat(moment(accordance.dateCreated).format('DD/MMM/YYYY')),
          }
        : {
            dateFormated: '-',
          },
      possibleAction: accordance.possibleAction || {
        id: '-',
        name: '-',
      },
      project: `${accordance.project.code} ${accordance.project.name}`,
      accordanceFiles: accordance.accordanceFiles,
      countNewExpirationDate: accordance.countNewExpirationDate,
    }
  );
};
