import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';
import './Table.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { BidTooltip } from '../../../Util/Tooltip/Tooltip';
import useTable from '../../../../hooks/useTable';
import { PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT } from '../../../../services/sessionFiltersIndicatorDetailHelper';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import NumberFormat from 'react-number-format';
import { EarlyWarningsTimeLapse } from '../../EarlyWarningsTimeLapse/EarlyWarningsTimeLapse';
import {
  addFilterConditionOnIgSection,
  filterProjectsBetweenLimitsByDays,
  getSubstractedDateByMonths,
  getUniqueCodeProjectsList,
  substractDays,
  sumDays,
} from '../../EarlyWarningsFilters/Util';

import { useSelector } from 'react-redux';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import { handleValueOrSlash, showEarlyWarning } from '../../PortfolioDetailTablesUtil';
import { isDiferentToIGLower } from '../../EarlyWarningsTimeLapse/Util';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { ExcelDataPrint } from '../../../Util/ExcelDownloadButton/ExcelDataPrint';
import { Tooltip } from '@material-ui/core';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { withStyles } from '@material-ui/core/styles';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px'
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);


const headers = ['id', 'last_project', 'last_operation', 'name', 'approval_date_long', 'effective_date', 'eligibility_date', 'days_elapsed_since_eligibility', 'month_and_projected_first_disbursement_amount', 'early_warnings'];

const selectedKeys = ['id', 'code', 'loanNumber', 'title', 'field1', 'field2', 'field3', 'field4', 'field6', 'field3'];

const {
  container,
  tableHeaderWithoutEarlyAlerts,
  tableHeaderWithEarlyAlerts,
  headerTitleClickableWithCentered,
  tableRowWithoutEarlyAlerts,
  tableRowWithEarlyAlerts,
  headerTitle,
  rowValue,
  rowList,
  rowValueName,
  fw,
  lastColumn,
  headerTitleClickable,
  rowValueCenterd,
  rowValueSpan,
  rowValueCenterdUppercase,
  borderTable,
} = styles;

enum FilterColorSelected {
  NONE_SELECTED = -1,
  GREEN = 0,
  YELLOW = 1,
  RED = 2,
}

const handleMonthDiff = (filterColor: FilterColorSelected, projectD: string) => {
  const today = new Date();
  if (filterColor === FilterColorSelected.NONE_SELECTED) {
    return true;
  }
  const projectDate = new Date(projectD);
  if (filterColor === FilterColorSelected.GREEN) {
    return filterProjectsBetweenLimitsByDays(projectDate, sumDays(getSubstractedDateByMonths(1), 1), today);
  } else if (filterColor === FilterColorSelected.YELLOW) {
    return filterProjectsBetweenLimitsByDays(projectDate, getSubstractedDateByMonths(6), getSubstractedDateByMonths(1));
  }
  return filterProjectsBetweenLimitsByDays(projectDate, substractDays(getSubstractedDateByMonths(6), 1));
};

const getProjectsEligiblesByEarlyWarningType = (projectsExpired, projectTypeFilterOnDetail) => {
  const filterCondition = isDiferentToIGLower(projectTypeFilterOnDetail);
  const uniqueProjectsExpired = filterCondition
    ? getUniqueCodeProjectsList(projectsExpired)
    : getUniqueCodeProjectsList(projectsExpired).filter(p => isDiferentToIGLower(p.field7));

  let green = 0;
  let yellow = 0;
  let red = 0;
  const today = new Date();

  for (const p of uniqueProjectsExpired) {
    if (filterProjectsBetweenLimitsByDays(new Date(p.field3), sumDays(getSubstractedDateByMonths(1), 1), today)) {
      green++;
    } else if (
      filterProjectsBetweenLimitsByDays(
        new Date(p.field3),
        getSubstractedDateByMonths(6),
        getSubstractedDateByMonths(1),
      )
    ) {
      yellow++;
    } else if (filterProjectsBetweenLimitsByDays(new Date(p.field3), substractDays(getSubstractedDateByMonths(6), 1))) {
      red++;
    }
  }
  return [green, yellow, red];
};

type Props = {
  earlyWarningsTab: boolean[];
  projectsQuantities: Function;
  loading: boolean;
};
export const ProjectsElegibleTable = ({ projectsQuantities, earlyWarningsTab, loading }: Props) => {
  const { handleClick, projects } = useTable();
  const [filterdProjectsElegibleFirstUndisbursement, setFilteredProjectsElegibleFirstUndisbursement] =
    useState<any>(projects);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: filterdProjectsElegibleFirstUndisbursement,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });
  const { t } = useTranslation();
  useEffect(() => {
    const filterNewProjects = [...projects];
    projectsQuantities(getProjectsEligiblesByEarlyWarningType(projects, projectTypeFilterOnDetail));
    setFilteredProjectsElegibleFirstUndisbursement(filterNewProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    setFilteredProjectsElegibleFirstUndisbursement(
      projects.filter(p => {
        return (
          handleMonthDiff(earlyWarningsTab.indexOf(true), p.field3) &&
          addFilterConditionOnIgSection(projectTypeFilterOnDetail, earlyWarningsTab, p.field7)
        );
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyWarningsTab]);

  const handleClickOnProject = project => {
    const storage = sessionStorage.getItem(PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT);
    storage &&
      sessionStorage.setItem(
        PROJECTS_ELIGIBLE_FIRST_UNDISBURSEMENT,
        JSON.stringify({ ...JSON.parse(storage), changeProjectType: false }),
      );
    handleClick(project);
  };

  const projectsElegibleHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field1',
      criteriaType: CriteriaType.date,
      label: 'approval_date_long',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field2',
      criteriaType: CriteriaType.date,
      label: 'effective_date',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field3',
      criteriaType: CriteriaType.date,
      label: 'eligibility_date',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field4',
      criteriaType: CriteriaType.number,
      label: 'days_elapsed_since_eligibility',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field6',
      criteriaType: CriteriaType.number,
      label: 'month_and_projected_first_disbursement_amount',
    },
    {
      className: headerTitleClickableWithCentered,
      criteria: 'field3',
      criteriaType: CriteriaType.date,
      label: 'early_warnings',
      hide: !showEarlyWarning(projectTypeFilterOnDetail),
    },
  ];
  const dataExcel = useDataNormalize(headers, selectedKeys, projects);

  return loading ? (
    <div className="projects-eligible-first-undisbursement__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : filterdProjectsElegibleFirstUndisbursement.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
      <div className={borderTable}>
        <div
          className={
            showEarlyWarning(projectTypeFilterOnDetail) ? tableHeaderWithEarlyAlerts : tableHeaderWithoutEarlyAlerts
          }
        >
          {projectsElegibleHeaderTableSetup.map(pe => {
            const { className, criteria, criteriaType, label, hide } = pe;

            return (
              !hide && (
                <h4
                  className={className}
                  key={`${label}-${className}-${criteria}`}
                  onClick={() => handleSort(criteria, criteriaType)}
                >
                  {t(label)}
                </h4>
              )
            );
          })}
        </div>

        <ul className={rowList}>
          {currentList &&
            currentList.map(project => {
              const {
                loanNumber,
                code,
                frontendId,
                title,
                field1: approvalDate,
                field2: effectiveDate,
                field3: eligibilityDate,
                field4: daysElapsedSinceEligibility,
                field5: monthFirstDisbursementProjected,
                field6: amountFirstDisbursementProjected,
                field7: projectProductType,
                field8: yearFirstDisbursementProjected,
              } = project;
              return (
                <li
                  className={
                    showEarlyWarning(projectTypeFilterOnDetail) ? tableRowWithEarlyAlerts : tableRowWithoutEarlyAlerts
                  }
                  key={`${loanNumber}${code}${frontendId}`}
                >
                  <BlackTooltip arrow title={t('go_to_project_info')}>
                    <p className={`${rowValue} link-project`} onClick={() => handleClickOnProject(project)}>
                      {code}
                    </p>
                  </BlackTooltip>
                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip arrow title={title || ''} placement="bottom" enterDelay={500} leaveDelay={100}>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>
                  <p className={rowValueCenterdUppercase}>{renderDateOrSlash(approvalDate)}</p>
                  <p className={rowValueCenterdUppercase}>{renderDateOrSlash(effectiveDate)}</p>
                  <p className={rowValueCenterdUppercase}>{renderDateOrSlash(eligibilityDate)}</p>
                  <p className={rowValueCenterd}>{handleValueOrSlash(daysElapsedSinceEligibility)}</p>
                  <p className={rowValueCenterd}>
                    <AmountAndMonthColumn
                      amount={amountFirstDisbursementProjected}
                      month={monthFirstDisbursementProjected}
                      year={yearFirstDisbursementProjected}
                    />
                  </p>
                  {showEarlyWarning(projectTypeFilterOnDetail) && (
                    <span className={rowValueSpan}>
                      <EarlyWarningsTimeLapse
                        filterToApply={{
                          green: filterProjectsBetweenLimitsByDays(
                            new Date(eligibilityDate),
                            sumDays(getSubstractedDateByMonths(1), 1),
                            new Date(),
                          ),
                          yellow: filterProjectsBetweenLimitsByDays(
                            new Date(eligibilityDate),
                            getSubstractedDateByMonths(6),
                            getSubstractedDateByMonths(1),
                          ),
                          red: filterProjectsBetweenLimitsByDays(
                            new Date(eligibilityDate),
                            substractDays(getSubstractedDateByMonths(6), 1),
                          ),
                        }}
                        projectStringDate={handleValueOrSlash(eligibilityDate)}
                        projectProductType={projectProductType}
                      />
                    </span>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
      <div className="pagination-style">
        <PaginationTable
          fnSetRows={setRowsPerPage}
          rows={rowsPerPage}
          numProyects={filterdProjectsElegibleFirstUndisbursement.length}
        />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
      {/* <PaginationTable /> */}
    </div>
  ) : null;
};

const AmountAndMonthColumn = props => {
  const { t } = useTranslation();
  const { amount, month, year } = props;


  const MONTHS_TRSANSLATION_KEY = {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december',
  };

  const handleHaveDataToRender = () => month && amount;
  const renderMonth = () => {
    return ` ${t('in')} ${t(MONTHS_TRSANSLATION_KEY[parseInt(month)])} `;
  };
  return handleHaveDataToRender() ? (
    <>
      <NumberFormat
        value={parseFloat(amount)}
        displayType={'text'}
        className={'value'}
        thousandSeparator={'.'}
        decimalSeparator={','}
      />
      {renderMonth()}
      {year}
    </>
  ) : (
    <>-</>
  );
};
