import React, { useEffect, useState } from 'react';
import './PortfolioAccordanceTable.scss';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../PorfolioTableShared.Styles';
import '../PortfolioDetailContent.scss';
import { StyledTableRow } from './PortfolioAccordanceTable.Styles';
import { Table, TableBody, TableContainer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Accordance from '../../../models/Accordance';
import { expiredDate } from '../../../services/util';
import { renderDateOrSlash } from '../../../services/stringUtil';
import { sortAlphabetically as sortAlphabeticallyGeneral } from '../../../services/sortsUtil';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { AccordanceType } from '../../../models/AccordanceType';
import { SupervisionPlanItemType } from '../../ProjectDetail/ProjectTabs/Supervision/util';
import moment from 'moment';
import { replaceMomentFormat } from '../../../services/util';
import { ProjectsListSkeleton } from '../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import useDataNormalize from '../../../hooks/useDataNormalize';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import usePaginationSort from '../../../hooks/usePaginationSort';
import PaginationTable from '../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';


const headersPending = ['id', 'last_project', 'last_operation', 'name', 'To Be Approved By', 'type', 'due_date', 'critical_issue', 'Agrrement'];
const selectedKeyPending = ['id', 'code', 'loanNumber', 'title', 'code', 'loanNumber', 'title', 'title', 'field2'];

const headersAgreements = ['id', 'last_project', 'last_operation', 'name', 'type', 'due_date', 'critical_issue', 'description_of_the_agreement'];
const selectedKeysAgreements = ['id', 'project_code', 'project_loanNumber', 'project_name', 'accordanceType', 'newExpirationDate', 'criticalIssue_name', 'description'];

const headersWaitingA = ['id', 'last_project', 'last_operation', 'name', 'type', 'due_date', 'critical_issue', 'description_of_the_agreement'];
const selectedKeysWaitingA = ['id', 'project_code', 'project_loanNumber', 'project_name', 'accordanceType', 'newExpirationDate', 'criticalIssue_name', 'description'];

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.accordances.length === nextProps.accordances.length &&
    prevProps.waitingApproval === nextProps.waitingApproval &&
    prevProps.indicatorKey === nextProps.indicatorKey &&
    prevProps.handleProject === nextProps.handleProject
  );
};

const basicHeaders = [
  { label: 'code', sortKey: 'code' },
  { label: 'operation', sortKey: 'loanNumber' },
  { label: 'project_name', sortKey: 'name' },
];

const sortAlphabetically = (key: string, order = 'desc', porpertyKey: string) => {
  return (a, b) => {
    if (porpertyKey === 'personToApprove') {
      if (a[porpertyKey] === null) {
        a[porpertyKey] = { name: '-', lastName: '' };
      }
      if (b[porpertyKey] === null) {
        b[porpertyKey] = { name: '-', lastName: '' };
      }
    }

    if (!a[porpertyKey].hasOwnProperty(key) || !b[porpertyKey].hasOwnProperty(key)) {
      return 0;
    }

    const varA = a[porpertyKey][key];
    const varB = b[porpertyKey][key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const basicStyles = [
  { width: '10%' },
  { width: '10%' },
  { width: '18%' },
  { width: '16%' },
  { width: '10%' },
  { width: '18%' },
  { width: '18%' },
];
const pendingTaskStyles = [
  { width: '5%' },
  { width: '5%' },
  { width: '25%' },
  { width: '20%' },
  { width: '30%' },
  { width: '5%' },
  { width: '10%' },
];

const waitingStyles = [
  { minWidth: '50px', padding: '8px' },
  { padding: '8px' },
  { padding: '8px' },
  { padding: '8px' },
];

const persontoApproveText = accordance => {
  return accordance.personToApprove ? `${accordance.personToApprove.name} ${accordance.personToApprove.lastName}` : '-';
};

const cellStyle = (index, waitingApproval, pendingTask = '') => {
  if (waitingApproval) {
    return waitingStyles[index];
  } else if (pendingTask === IndicatorKeyEnum.USER_PENDING_TASKS) {
    return pendingTaskStyles[index];
  } else {
    return basicStyles[index];
  }
};

const headerCellStyle = waitingApproval => {
  return waitingApproval ? { padding: '10px' } : {};
};

const setSelectedRow = (selected, accordanceID, indicatorKey) => {
  return indicatorKey !== IndicatorKeyEnum.USER_PENDING_TASKS ? selected === accordanceID : false;
};

type Props = {
  accordances: Accordance[];
  handleProject: any;
  waitingApproval?: boolean;
  indicatorKey?: any;
  handleAccordance?: Function;
  loading?: boolean;
};
const PortfolioAccordanceTable = (props: Props) => {
  const { t } = useTranslation();
  const [isLoading, setisLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<number>(-1);
  const { accordances, handleProject, waitingApproval, indicatorKey, handleAccordance, loading } = props;
  const [sortAsc, setSortAsc] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: accordances,
    defaultSortCriteria: 'name',
    pageCount: rowsPerPage,
  });

  const handleClick = (accordSelected, isLinkEvent = false) => {
    window.scrollTo(0, 0);
    setSelected(accordSelected.id);

    handleProject(accordSelected, isLinkEvent);

    if (handleAccordance) {
      handleAccordance(accordSelected);
    }
  };

  const sortTable = (accordances, keyToSort, propertyKey) => {
    propertyKey === 'rootProperty'
      ? currentList.sort(sortAlphabeticallyGeneral(keyToSort, sortAsc ? 'asc' : 'desc'))
      : currentList.sort(sortAlphabetically(keyToSort, sortAsc ? 'asc' : 'desc', propertyKey));
    setSortAsc(!sortAsc);
  };

  const renderPendingTasksRows = accordance => {
    return (
      indicatorKey === IndicatorKeyEnum.USER_PENDING_TASKS && (
        <>
          <StyledTableCell style={cellStyle(3, waitingApproval, indicatorKey)}>
            {renderTypeOfAccordanceTitle(accordance, t, indicatorKey)}
          </StyledTableCell>
          <StyledTableCell style={cellStyle(4, waitingApproval, indicatorKey)}>
            {waitingApproval ? accordance.criticalIssue.name : accordance.description}
          </StyledTableCell>
          <StyledTableCell style={cellStyle(5, waitingApproval, indicatorKey)}>
            <span
              style={
                accordance.expirationDate && expiredDate(accordance.expirationDate)
                  ? { color: '#E092A3', fontWeight: 500 }
                  : {}
              }
            >
              {renderDateOrSlash(accordance.expirationDate)}
            </span>
          </StyledTableCell>
          <StyledTableCell
            onClick={event => handleClick(accordance, true)}
            style={cellStyle(6, waitingApproval, indicatorKey)}
          >
            <span className="bell" />
          </StyledTableCell>
        </>
      )
    );
  };
  const getHeaders = indicator => {
    switch (indicator) {
      case 'ACCORDANCES_EXPIRING':
        return headersAgreements;
      case 'PROJECTS_OVERALL_STAGE_CODE_DISBURSING_NO_DISBURSEMENTS_OVER_180_DAYS':
        return headersPending;
      case 'ACCORDANCES_WAITING_APPROVAL':
        return headersWaitingA;
      default:
        return headersPending;
    }
  };

  const getSelectedKeys = indicator => {
    switch (indicator) {
      case 'ACCORDANCES_EXPIRING':
        return selectedKeysAgreements;
      case 'DISBURSEMENT_PROJECTION_CURRENT_YEAR_INCLUDING_REGIONALS':
        return selectedKeyPending;
      case 'ACCORDANCES_WAITING_APPROVAL':
        return selectedKeysWaitingA;
      default:
        return selectedKeyPending;
    }
  };
  const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let propName = parentKey ? parentKey + '_' + key : key;

        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, result);
        } else {
          result[propName] = obj[key];
        }
      }
    }
    return result;
  };

  const headers = getHeaders(props.indicatorKey);
  const selectedKeys = getSelectedKeys(props.indicatorKey);

  const projects = accordances.map((projects: any) => flattenObject(projects));
  const data = useDataNormalize(headers, selectedKeys, projects);
  useEffect(() => {
    if (data.length > 0) return setisLoading(false);
  }, [data]);

  return isLoading ? (
    <div className="portfolio-detail-content__loading__container">
      <ProjectsListSkeleton />
    </div>
  ) : (
    <div className="portfolio-accordance-table-container">
      <div className="">
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} key={data} />

        {/* <DataTable headers={headers} selectedKeys={selectedKeys} hasFirstColLink data={data} hasRowDetail projectsModal={projects} fnPrueba={handleClick} /> */}

        <TableContainer>
          <div className="border-table">
            <Table stickyHeader className="custom-table" style={{ tableLayout: 'auto' }} size="small">
              <StyledTableHeader>
                <StyledTableRowHeader style={{ height: '80px' }}>
                  {basicHeaders.map(item => {
                    return (
                      <StyledTableCellHeader
                        align="left"
                        onClick={() => sortTable(accordances, item.sortKey, 'project')}
                        style={headerCellStyle(waitingApproval)}
                        key={item.label}
                      >
                        <div className="text-head">
                          <div>{t(item.label)}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>
                    );
                  })}

                  {waitingApproval && (
                    <StyledTableCellHeader
                      style={{ padding: '10px' }}
                      align="left"
                      onClick={() => sortTable(accordances, 'name', 'personToApprove')}
                    >
                      <div className="text-head">
                        <div>{t('to_be_approved_by')}</div>
                        <div>
                          <i className="sort-icon"></i>
                        </div>
                      </div>
                    </StyledTableCellHeader>
                  )}
                  {indicatorKey && indicatorKey === IndicatorKeyEnum.USER_PENDING_TASKS && (
                    <>
                      <StyledTableCellHeader
                        align="left"
                        onClick={() => sortTable(accordances, 'typeSortValue', 'rootProperty')}
                      >
                        <div className="text-head">
                          <div>{t('type')}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>
                      <StyledTableCellHeader
                        align="left"
                        onClick={() =>
                          sortTable(
                            accordances,
                            waitingApproval ? 'name' : 'description',
                            waitingApproval ? 'criticalIssue' : 'rootProperty',
                          )
                        }
                      >
                        <div className="text-head">
                          <div>{descriptionCriticalText(waitingApproval, t)}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>
                      <StyledTableCellHeader
                        align="left"
                        onClick={() => sortTable(accordances, 'expirationDate', 'rootProperty')}
                      >
                        <div className="text-head">
                          <div>{t('due_date')}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>
                    </>
                  )}
                  {indicatorKey && indicatorKey === IndicatorKeyEnum.ACCORDANCES_EXPIRING && (
                    <StyledTableCellHeader
                      align="left"
                      onClick={() => sortTable(accordances, 'accordanceType', 'rootProperty')}
                    >
                      <div className="text-head">
                        <div> {t('type')}</div>
                        <div>
                          <i className="sort-icon"></i>
                        </div>
                      </div>
                    </StyledTableCellHeader>
                  )}

                  {indicatorKey && indicatorKey === IndicatorKeyEnum.ACCORDANCES_EXPIRING && (
                    <>
                      <StyledTableCellHeader
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '80px' }}
                        align="left"
                        onClick={() => sortTable(accordances, 'expirationDate', 'rootProperty')}
                      >
                        <div className="text-head">
                          <div>{t('due_date')}</div>
                          <div className="display-flex-icon">
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>

                      <StyledTableCellHeader
                        align="left"
                        onClick={() => sortTable(accordances, 'name', 'criticalIssue')}
                      >
                        <div className="text-head">
                          <div> {t('critical_issue')}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>

                      <StyledTableCellHeader align="left" onClick={() => sortTable(accordances, 0, 'description')}>
                        <div className="text-head">
                          <div>{t('description_of_the_agreement')}</div>
                          <div>
                            <i className="sort-icon"></i>
                          </div>
                        </div>
                      </StyledTableCellHeader>

                    </>
                  )}

                </StyledTableRowHeader>
              </StyledTableHeader>
              <TableBody>
                {currentList &&
                  currentList.map((accordance, index) => (
                    <StyledTableRow
                      style={stripeRows(index, indicatorKey)}
                      hover
                      key={accordance.id}
                      selected={setSelectedRow(selected, accordance.id, indicatorKey)}
                      onClick={
                        indicatorKey !== IndicatorKeyEnum.USER_PENDING_TASKS
                          ? event => handleClick(accordance)
                          : () => { }
                      }
                    >
                      <StyledTableCell style={cellStyle(0, waitingApproval, indicatorKey)} className="cursor-pointer">
                        {accordance.project.code}
                      </StyledTableCell>
                      <StyledTableCell style={cellStyle(1, waitingApproval, indicatorKey)}>
                        {accordance.project.loanNumber}
                      </StyledTableCell>
                      <StyledTableCell
                        className="name-project-nowrap-tiny cursor-pointer"
                        style={cellStyle(2, waitingApproval, indicatorKey)}
                      >
                        {accordance.project.name}
                      </StyledTableCell>

                      {renderPendingTasksRows(accordance)}

                      {waitingApproval && (
                        <StyledTableCell style={{ width: '15%', padding: '10px' }}>
                          {accordance.accordanceType === AccordanceType.InterGroup
                            ? t('operations_management')
                            : persontoApproveText(accordance)}
                        </StyledTableCell>
                      )}
                      {indicatorKey && indicatorKey === IndicatorKeyEnum.ACCORDANCES_EXPIRING && (
                        <>
                          <StyledTableCell style={cellStyle(3, waitingApproval, indicatorKey)}>
                            {renderTypeOfAccordanceTitle(accordance, t, indicatorKey)}
                          </StyledTableCell>

                          <StyledTableCell style={cellStyle(4, waitingApproval, indicatorKey)}>
                            {renderExpirationDate(accordance)}
                          </StyledTableCell>

                          <StyledTableCell style={cellStyle(4, waitingApproval, indicatorKey)}>
                            {accordance.criticalIssue ? accordance.criticalIssue.name : '-'}
                          </StyledTableCell>

                          <StyledTableCell style={cellStyle(5, waitingApproval, indicatorKey)}>
                            {accordance.description ? accordance.description : '-'}
                          </StyledTableCell>

                          {/* <StyledTableCell style={cellStyle(5, waitingApproval, indicatorKey)}>
                            {accordance.possibleAction ? accordance.possibleAction.name : '-'}
                          </StyledTableCell> */}
                        </>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>

        <div className="pagination-style">
          <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PortfolioAccordanceTable, areEqual);

const stripeRows = (index, indicatorKey) => {
  return indicatorKey !== IndicatorKeyEnum.USER_PENDING_TASKS
    ? {}
    : index % 2
      ? { background: '#fafafa' }
      : { background: 'white' };
};

const renderTypeOfAccordanceTitle = (accordance: any, t: Function, indicatorKey: IndicatorKeyEnum) => {
  return <span className="capitalize-first-letter">{t(titleByType(accordance, indicatorKey))}</span>;
};

const titleByType = (item: any, indicatorKey: IndicatorKeyEnum) => {
  if (isActivity(indicatorKey, item)) {
    return activityTitle(item);
  } else {
    return accordanceTitle(item);
  }
};

const activityTitle = item => {
  switch (item.type) {
    case SupervisionPlanItemType.CriticalProduct:
      return 'critical_product';
    case SupervisionPlanItemType.AcquisitionTrustConsulting:
    case SupervisionPlanItemType.FinancialTrustConsulting:
    case SupervisionPlanItemType.TechnicalConsultancy:
      return 'consulting_type';
    case SupervisionPlanItemType.TrainingWithExecutingAgencies:
      return 'training_type';
    case SupervisionPlanItemType.AdministrativeMission:
      return 'mission_type';
    case SupervisionPlanItemType.ProcurementSupervisionVisit:
    case SupervisionPlanItemType.EnvironmentalSupervisionVisit:
    case SupervisionPlanItemType.FinancialSupervisionVisit:
    case SupervisionPlanItemType.TechnicalSupervisionVisit:
      return 'visit_type';
    case SupervisionPlanItemType.OtherActivities:
      return 'others_type';
    default:
      return '-';
  }
};

const accordanceTitle = item =>
  item.accordanceType === AccordanceType.Monitoring
    ? 'monitor_accordance'
    : item.accordanceType === AccordanceType.Revision
      ? 'revision_accordance'
      : item.accordanceType === AccordanceType.InterGroup
        ? 'inter_group_accordance'
        : item.accordanceType === AccordanceType.PreparationForStart
          ? 'new_accordance_the_preparation_for_start'
          : item.accordanceType === AccordanceType.StartPreparation
            ? 'new_accordance_the_start_preparation'
            : item.accordanceType === AccordanceType.ThermalMediumMonitoring
              ? 'new_accordance_the_thermal_medium_monitoring'
              : 'new_accordance_the_preparation_for_closing';

const isActivity = (indicatorKey, item) => {
  return item.supervisionPlanId !== undefined && indicatorKey === IndicatorKeyEnum.USER_PENDING_TASKS;
};

const descriptionCriticalText = (waitingApproval = false, t: any) =>
  waitingApproval ? t('critical_issue_portuguese_exept') : t('description');

const renderExpirationDate = (accordance: any) => {
  let currentDate = new Date();
  let dateToExpire = accordance.newExpirationDate !== null ? accordance.newExpirationDate : accordance.expirationDate;
  let diference = moment(new Date(dateToExpire)).diff(currentDate, 'days');

  if (new Date(dateToExpire) < currentDate) {
    return (
      <span style={{ color: 'red', fontWeight: 'bold' }}>
        {replaceMomentFormat(moment(dateToExpire).format('DD/MMM/YYYY'))}
      </span>
    );
  } else if (diference <= 7) {
    return (
      <span style={{ color: 'black', fontWeight: 'bold' }}>
        {replaceMomentFormat(moment(dateToExpire).format('DD/MMM/YYYY'))}
      </span>
    );
  } else {
    return <span>{replaceMomentFormat(moment(dateToExpire).format('DD/MMM/YYYY'))}</span>;
  }
};
