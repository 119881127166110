import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ActualProjectedDifferenceTable.module.scss';
import { renderDateOrSlash } from '../../../../services/stringUtil';
import { Pagination } from '@material-ui/lab';
import '../../../Util/Pagination/Pagination.scss';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { BidTooltip } from '../../../Util/Tooltip/Tooltip';
import useTable from '../../../../hooks/useTable';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import { ProjectCounter } from '../../ProjectCounter/ProjectCounter';
import { IndicatorKeyEnum } from '../../../../models/IndicatorKeyEnum';
import { basicFirstColumns, HeaderTableColumn } from '../../../../models/HeaderTableColumn';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';

const headers = ["id", "last_project", "last_operation", "name", "description", "planned_date", "finish_date", "status"]
const selectedKeys = ["id", "code", "loanNumber", "title", "field1", "field2", "field8", "field3"]

export const ActualProjectedDifferenceTable = () => {
  const { t } = useTranslation();
  const { handleClick, projects } = useTable();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects,
    defaultSortCriteria: 'field2',
    pageCount: rowsPerPage,
  });

  const {
    container,
    tableHeader,
    tableRow,
    headerTitleClickable,
    rowValueDescription,
    headerTitle,
    rowValueName,
    fw,
    lastColumn,
    rowValueDate,
    rowValue,
    rowList,
  } = styles;

  const actualProjectedDifferenceHeaderTableSetup: HeaderTableColumn[] = [
    ...basicFirstColumns(headerTitleClickable),
    {
      className: headerTitleClickable,
      criteria: 'field1',
      criteriaType: CriteriaType.string,
      label: 'description',
    },
    {
      className: headerTitleClickable,
      criteria: 'field2',
      criteriaType: CriteriaType.date,
      label: 'planned_date',
    },
    {
      className: headerTitleClickable,
      criteria: 'field6',
      criteriaType: CriteriaType.date,
      label: 'finish_date',
    },
    {
      className: headerTitleClickable,
      criteria: 'field3',
      criteriaType: CriteriaType.string,
      label: 'status',
    },
  ];
  const dataExcel = useDataNormalize(headers, selectedKeys, projects).map(element => setStateForExcel(element, t));


  const BlackTooltip = withStyles({
    tooltip: {
      //   color: '$ci-color-greyscale-90',
      backgroundColor: '#101319',
      fontSize: '16px',
      fontFamily: 'Rubik',
      fontWeight: '400',
      lineHeight: '20px',
      padding: '8px 12px',
      borderRadius: '8px'
    },
    arrow: {
      fontSize: 20,
      color: '#4A4A4A',
      '&::before': {
        backgroundColor: '#101319',
      },
    },
  })(Tooltip);

  return projects.length ? (
    <div className={container}>
      <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />

      <div className='borderTable'>
        <div className={tableHeader}>
          {actualProjectedDifferenceHeaderTableSetup.map(apd => {
            const { criteriaType, label, className, criteria } = apd;
            return (
              <h4
                onClick={() => handleSort(criteria, criteriaType)}
                className={className}
                key={`${className}-${criteria}-${label}`}
              >
                {t(label)}
              </h4>
            );
          })}

          <h4 className={headerTitle}>{''}</h4>
        </div>
        <ul className={rowList}>
          {currentList &&
            currentList.map(project => {
              const {
                loanNumber,
                code,
                frontendId,
                title,
                field1: description,
                field2: planificationDate,
                field6: finishDate,
                field3: supervisionPlanStatus,
              } = project;

              return (
                <li className={tableRow} key={`${loanNumber}${code}${frontendId}`}>
                  <BlackTooltip arrow title={t('go_to_project_info')} onClick={() => {
                    handleClick(project);
                  }}>
                    <p className={`${rowValue} link-project`}>{code}</p>
                  </BlackTooltip>

                  <p className={rowValue}>{loanNumber}</p>
                  <BlackTooltip title={title} placement="bottom" enterDelay={500} leaveDelay={100} arrow>
                    <p className={rowValueName}>{title}</p>
                  </BlackTooltip>
                  <p className={rowValueDescription}>{description}</p>
                  <p className={rowValueDate}>{renderDateOrSlash(planificationDate)}</p>
                  <p className={rowValueDate}>{renderDateOrSlash(finishDate)}</p>
                  <p className={rowValue}>{translationState(supervisionPlanStatus, t)}</p>
                  <span className={lastColumn} onClick={() => handleClick(project)}>
                    <p className={fw}></p>
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
      <ProjectCounter
        arrayToCount={projects}
        countProjects={true}
        indicatorKey={IndicatorKeyEnum.SUPERVISION_PLAN_BUDGET_STATUS}
      />

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={projects.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  ) : null;
};

const translationState = (state: string, t: Function) => {
  return t(`supervision_plan_activity_state_${state.toString()}`);
};

const setStateForExcel = (data, t: Function) => {

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key === "state" && typeof data[key] === 'number') {
        data[key] = translationState(data[key].toString(), t);

      }
    }
  }

  return data;
};

